import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Checkbox } from "antd";
import AuthContainer from "components/layout/AuthContainer";
import { Field, Button, Selectable } from "components/forms";
import * as auth_actions from "../../redux/AuthRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { register, change_email, getUserByToken } from "../../redux/AuthCRUD";
import * as functions from "app/global/functions";
import marklightIcon from "../../../../../assets/Svg/marklight.svg";
import { Select } from "antd";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [showsearch, setshowsearch] = useState(false);
  const [selectedCountry, setselectedCountry] = useState("Search to select");
  const [loading, setLoading] = useState(false);
  const [consent, setConsent] = useState(false);
  const [pumpFake, setPumpFake] = useState(1);
  const [disabled_input, set_disabled] = useState(false);
  const location = useLocation();

  let strongPassword = new RegExp(
    "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
  );
  let mediumPassword = new RegExp(
    "((?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
    // "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordTypeb, setPasswordTypeb] = useState("password");
  const [passwordInputb, setPasswordInputb] = useState("");
  const [passwordTypec, setPasswordTypec] = useState("password");
  const [passwordInputc, setPasswordInputc] = useState("");
  const [password_state, set_p_state] = useState<number>(0);

  const prop = {
    intro:
      "Empower you the creator,  to monetise your content,  while effectively inspiring consumers to  make good, without compromise.",
    title: "Let's get started",
    titleB: "Basic info",
    subTitle: "Please take a moment to fill out the following.",
    steps: true,
  };

  const {
    user,
    more_User,
    accessToken,
  } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user,
      more_User: state.auth,
      accessToken: state.auth.accessToken,
    }),
    shallowEqual
  );




  const [first_name, set_first_name] = useState(user?.first_name || more_User?.first_name || "");
  const [last_name, set_last_name] = useState(user?.last_name || more_User?.last_name || "");
  const [email, set_email] = useState(user?.email || more_User?.email || "");
  const [country, set_country] = useState(user?.country || "");
  const [password, set_password] = useState("");
  const [confirm, set_confirm] = useState("");


  const handlePasswordChange = (e: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setPasswordInputb(e.target.value);
    set_password(e.target.value);
    console.log('STROGN TEST', strongPassword.test(e.target.value));
    if (strongPassword.test(e.target.value)) {
      set_p_state(4);
    } else if (mediumPassword.test(e.target.value)) {
      set_p_state(3);
    } else if (e.target.value.length >= 8) {
      set_p_state(1);
    } else {
      set_p_state(0);
    }
  };

  const handlePasswordChangec = (e: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setPasswordInputc(e.target.value);
    set_confirm(e.target.value.trim());
  };

  useEffect(() => {
    if (location?.state?.edit) {
      set_disabled(true);
    }
  }, [location]);

  async function log() {
    var first = first_name.trim();
    var last = last_name.trim();

    // if ((!accessToken || accessToken === '') && !disabled_input) {

    if (
      first === "" ||
      last === "" ||
      email === "" ||
      password === "" ||
      confirm === "" ||
      country === ""
    ) {
      toast.error("Enter all inputs!");
      return;
    }

    if (
      !functions.isValidFirstname(first) ||
      !functions.isValidFirstname(last)
    ) {
      toast.error("Enter a valid first or last name!");
      return;
    }

    if (password !== confirm) {
      toast.error("Passwords does not match");
      return;
    }

    if (password.length < 8) {
      toast.error("Passwords should be atleast 8 characters!");
      return;
    }

    if (password_state < 4) {
      toast.error("Enter a stronger password!");
      return;
    }

    if (!functions.validateEmail(email)) {
      toast.error("Enter a valid email address!");
      return;
    }

    if (first.length < 3 || last.length < 3) {
      toast.error("Enter a valid first or last name");
      return;
    }

    if (confirm !== password) {
      toast.error("Passwords must be same!");
      return;
    }
    var body: any = {};
    body["first_name"] = first_name;
    body["last_name"] = last_name;
    body["email"] = email;
    body["password"] = password;
    body["country"] = country.toLowerCase();


    setLoading(true);
    // check_email(`?email=${email}`)
    //   .then((response: any) => {
    //     setLoading(false);
    //     var data = response.data;
    //     if (data.error) {
    //       navigate("/channels");
    //     } else {
    //       toast.error("This email address currently exists");
    //     }
    //   })
    //   .catch((error: any) => {
    //     var message = error.response.data
    //       ? error.response.data.message
    //       : "Network error occured!";

    //     setLoading(false);
    //     toast.error(message ? message : "Network error occured!");
    //   });

    register(body)
      .then(async (response: any) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
          toast.error(data.error);
        } else {
          dispatch(auth_actions.actions.fulfillToken(data.user_token));
          const { data: user } = await getUserByToken()
          dispatch(auth_actions.actions.fulfillUser(user));

          // dispatch(auth_actions.actions.fulfillToken(data.user_token));
          navigate("/channels");
        }
      })
      .catch((error: any) => {
        var message = error.response.data
          ? error.response.data.message
          : "Network error occured!";

        setLoading(false);
        toast.error(message ? message : "Network error occured!");
      });

    // } else {
    //   var body: any = {};
    //   body["email"] = email;

    //   setLoading(true);
    //   change_email(body)
    //     .then((response: any) => {
    //       setLoading(false);
    //       var data = response.data;
    //       if (data.error) {
    //         toast.error(data.error);
    //       } else {
    //         dispatch(auth_actions.actions.fulfillToken(""));
    //         navigate("/thank-you");
    //       }
    //     })
    //     .catch((error: any) => {
    //       var message = error.response.data
    //         ? error.response.data.message
    //         : "Network error occured!";

    //       setLoading(false);
    //       toast.error(message ? message : "Network error occured!");
    //     });
    //   return;
    // }
  }
  const onSearch = (text: string) => {
    setSearchText(text.length > 0 ? text.toLowerCase() : "");
  };

  function filterByText(list: any, searchText: string) {
    return list.filter(
      (item: any) =>
        item.value.toLowerCase().search(searchText.toLowerCase()) === 0
    );
  }

  const onToggleConsent = () => setConsent(!consent);
  const productOptions = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Antigua & Deps", label: "Antigua & Deps" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia Herzegovina", label: "Bosnia Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Brazil", label: "Brazil" },
    { value: "Brunei", label: "Brunei" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina", label: "Burkina" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Central African Rep", label: "Central African Rep" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    { value: "Congo {Democratic Rep}", label: "Congo {Democratic Rep}" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "East Timor", label: "East Timor" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Greece", label: "Greece" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran", label: "Iran" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland (Republic)", label: "Ireland (Republic)" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Ivory Coast", label: "Ivory Coast" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Korea North", label: "Korea North" },
    { value: "Korea South", label: "Korea South" },
    { value: "Kosovo", label: "Kosovo" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Laos", label: "Laos" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libya", label: "Libya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macedonia", label: "Macedonia" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia", label: "Micronesia" },
    { value: "Moldova", label: "Moldova" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar, {Burma}", label: "Myanmar, {Burma}" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Qatar", label: "Qatar" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "St Kitts & Nevis", label: "St Kitts & Nevis" },
    { value: "St Lucia", label: "St Lucia" },
    { value: "Saint Vincent & the Grenadines", label: "Saint Vincent & the Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome & Principe", label: "Sao Tome & Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Sudan", label: "South Sudan" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syria", label: "Syria" },
    { value: "Taiwan", label: "Taiwan" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania", label: "Tanzania" },
    { value: "Thailand", label: "Thailand" },
    { value: "Togo", label: "Togo" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad & Tobago", label: "Trinidad & Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Vatican City", label: "Vatican City" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ];

  const filteredList = searchText
    ? filterByText(productOptions, searchText)
    : productOptions;

  useEffect(() => {
    setPumpFake(pumpFake + 1);

  }, []);

  return (
    <AuthContainer {...prop}>
      <div className="auth-form">
        <Field
          type="text"
          value={first_name}
          required
          showCount
          maxLength={20}
          className="plusCount"
          disabled={accessToken && disabled_input}
          onChange={(e: any) =>
            set_first_name(e.target.value)
          }
          label="First name"
          placeholder="e.g. John"
        />
        <Field
          type="text"
          value={last_name}
          required
          showCount
          maxLength={20}
          className="plusCount"
          disabled={accessToken && disabled_input}
          onChange={(e: any) =>
            set_last_name(e.target.value)
          }
          label="Last name"
          placeholder="e.g. Doe"
        />

        {/* <Selectable
          defaultValue={country}
          onChange={(e: any) => dispatch(auth_actions.actions.set_country(e))}
          options={filteredList}
          label="Country of residence"
        /> */}

        <label className="dcntrylabel">Country of residency</label>

        {!showsearch && (
          <div className="dcountry" onClick={() => setshowsearch(true)}>
            <label className="dcountry-label">{country}</label>
          </div>
        )}

        {showsearch && (
          <div className="dcountrysearch">
            <input
              autoFocus
              type="text"
              value={searchText}
              defaultValue={searchText}
              onChange={(e) => onSearch(e.target.value)}
              placeholder="Search to select "
            />


            <div
              className="dcntryoverlay"
              onClick={() => setshowsearch(!showsearch)}
            />
            <div className="countrylist">
              {" "}
              {filteredList.map((item: any, index: number) => (
                <p key={index}
                  onClick={() => {
                    // dispatch(auth_actions.actions.set_country(item.value));
                    set_country(item.value)
                    setshowsearch(!showsearch);
                  }}
                >
                  {item.value}
                </p>
              ))}
            </div>

            {/* </>
            )} */}
          </div>
        )}

        <Field
          value={email}
          required
          onChange={(e: any) =>
            // dispatch(auth_actions.actions.set_email(e.target.value.trim()))
            set_email(e.target.value.trim())
          }
          type="text"
          label="Email address"
          placeholder="e.g. johndoe@email.com"
        />
        <Field
          value={password}
          required
          onChange={handlePasswordChange}
          // onChange={(e: any) => dispatch(auth_actions.actions.set_password(e.target.value.trim()))}
          disabled={accessToken && disabled_input}
          type="password"
          label="Password"
          placeholder=""
        />

        <div className="pmp_bodybottomitem">
          <div className="pmpbbipstrength" style={{ width: 500 }}>
            <div
              className="pstrength a"
              style={{
                backgroundColor: password_state > 0 ? "green" : undefined,
              }}
            ></div>
            <div
              className="pstrength b"
              style={{
                backgroundColor: password_state >= 3 ? "green" : undefined,
              }}
            ></div>
            <div
              className="pstrength c"
              style={{
                backgroundColor: password_state === 4 ? "green" : undefined,
              }}
            ></div>
          </div>
        </div>

        <div
          className="pmp_bodybottomitem"
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div className="pmpbbipasschar">
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: passwordInputb.length >= 8 ? "green" : undefined,
                }}
              >
                Eight (8) or more characters
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isUpperCase(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One (1) or more uppercase character(s)
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isNumeric(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One(1) or more numerical character(s)
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isSpecial(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One(1) or more special character(s)
              </label>
            </div>
          </div>
        </div>
        <Field
          value={confirm}
          onChange={handlePasswordChangec}
          // onChange={(e: any) => dispatch(auth_actions.actions.set_confirm(e.target.value.trim()))}
          disabled={accessToken && disabled_input}
          type="password"
          label="Confirm password"
          placeholder=""
        />
        {/* <div className="consent">
          <Checkbox onChange={onToggleConsent}>
            I'm over the age of 18 years old and I agree to Clean Creator Community Terms of Use and Privacy Policy
          </Checkbox>
        </div> */}
        <div className="actions mb-4">
          <div className="flex gap w-100">
            {!disabled_input && (
              <Button
                variant="secondary"
                label="Previous"
                onClick={() => navigate("/login")}
              />
            )}
            <Button
              variant="primary"
              label={accessToken && disabled_input ? "Update" : "Next"}
              onClick={() => log()}
            />
          </div>
        </div>
        <Button
          variant="plain"
          label="Already have an account? Login"
          onClick={() => navigate("/login")}
        />
      </div>
    </AuthContainer>
  );
}
