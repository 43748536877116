import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'contents') {
    return axios.get(`${URL}api/content/all?${queryParams}`);
  }
}

export function fetchContentAll(type, q) {
  if (type === 'content') {
    return axios.get(`${URL}api/content/all`);
  } else {
    return axios.get(`${URL}api/content/search${q}`);
  }
}

export function fetchContentDetails(id) {
  return axios.get(`${URL}api/content/?content_id=${id}`);
}