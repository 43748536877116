import { Button as AntButton } from 'antd';

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'plain';
  label: any;
  Icon?: any;
  onClick?: () => void;
  loading?: boolean;
}
export default function Button({ loading, variant, label, Icon, onClick }: ButtonProps) {
  return (
      <AntButton
        icon={Icon && <Icon />}
        loading = {loading}
        className={`cta ${variant} letsbtn`}
        onClick={onClick}
      >
        {label}
      </AntButton>
  )
}
