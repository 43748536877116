import { Layout } from "antd";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
import Crumbs from "./Crumbs";
import Footer from "antd/es/layout/layout";
import { PartnerAnalytics } from "components/Icons";

const { Content } = Layout;
const crumbs = [{ label: "/ ANALYTICS", to: "/analytics" }];
const AnalyticsLayout = (props: any) => {
  const { headerIcon, headerTitle, children } = props;
  return (
    <Layout>
      <Sidebar />
      <Layout className="layout-container">
        <TopHeader Icon={headerIcon} title={headerTitle} />
        <Content className="content-container">
          <Crumbs
            crumbs={crumbs}
            icon={<PartnerAnalytics width={18} height={18} />}
          />
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default AnalyticsLayout;
