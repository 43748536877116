import { useEffect, useState } from "react";
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import AuthContainer from "components/layout/AuthContainer";
import { Button, Field } from "components/forms";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editUserData, getUserByToken } from "../../redux/AuthCRUD";
import * as auth_actions from "../../redux/AuthRedux";
import "./styles.scss";
import { getProducts } from "../../redux/AuthCRUD";

const prop = {
  title: "Let's get started",
  titleB: "Welcome pack",
  subTitle:
    "Once approved you'll receive your welcome pack. Select up to 2 products to be included.",
  steps: true,
};

export default function WelcomePack() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user,
    }),
    shallowEqual
  );

  const [address, set_address] = useState(user?.address);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [city, set_city] = useState(user?.city);
  const [state, set_state_] = useState(user?.state);
  const [street, set_street] = useState(user?.street);
  const [building, set_building] = useState(user?.building);
  const [country, set_country] = useState(user?.country);
  const [number, set_number] = useState(user?.number);
  const [floor, set_floor] = useState(user?.floor);
  const [postal_code, set_postal_code] = useState(user?.postal_code);
  const [language, set_lang] = useState(user?.language);
  const [productss, set_prodss] = useState([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  useEffect(() => {
    if (!user) {
      navigate("/register");
    }

    getProducts()
      .then((response: any) => {
        var data = response.data;
        set_prodss(data.success);
      })
      .catch((error: any) => {
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }, []);

  async function submit() {
    if (!city || !address || !postal_code || !building) {
      toast.error("Kindly provide all required input");
      return;
    } else if (selectedItems.length > 2 || selectedItems.length === 0) {
      toast.error("Select max 2 products");
      return;
    }

    var body: any = {};
    // body["first_name"] = first_name;
    body["city"] = city;
    body["address"] = address;
    body["state"] = state;
    body["street"] = street;
    body["building"] = building;
    body["country"] = country;
    body["number"] = number;
    body["floor"] = floor;
    body["postal_code"] = postal_code;
    body["starter_pack"] = selectedItems;
    // products

    // console.log(body); return;
    setLoading(true);
    setStatus("");
    editUserData(body)
      .then(async (response: any) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
          toast.error(data.error);
        } else {
          const { data: user } = await getUserByToken()
          dispatch(auth_actions.actions.fulfillUser(user));
          dispatch(auth_actions.actions.fulfillToken(data.user_token));
          navigate("/thank-you");
        }
      })
      .catch((error: any) => {
        var message = error.response.data
          ? error.response.data.message
          : "Network error occured!";

        setLoading(false);
        toast.error(message ? message : "Network error occured!");
      });
  }

  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('');
    }
    return newStr;
  }

  const updateList = (id: string) => {
    const selectsClone = [...selectedItems];
    if (selectsClone.includes(id)) {
      selectsClone.map((ele: any, index: number) => {
        if (ele == id) {
          selectsClone.splice(index, 1);
          setSelectedItems(selectsClone);
        }
      })
    } else {
      if (selectsClone.length >= 2) {
        toast.warn('Cannot select more than two items');
      } else {
        selectsClone.push(id);
        setSelectedItems(selectsClone);
      }
    }
  }

  return (
    <AuthContainer {...prop}>
      <div className="welcome-pack auth-form">
        <div className="mt-6">
          <label className="labelled">Select 2 products</label>
          <div className="products">
            {productss && productss
              .map((ele: any, index: number) => (
                <div className="check-product" key={index} onClick={() => updateList(ele._id)}>
                  <div className="selection">
                    {selectedItems && selectedItems.includes(ele._id)
                      ? <img src={require('assets/images/checked.png')} alt="" />
                      : <img src={require('assets/images/checked-off.png')} alt="" />
                    }
                  </div>
                  <img src={ele.thumbnail} alt="" className="thumbnail" />
                  <div className="tt">{`${limitStr(ele.name, 22)}...`}
                    <Popover
                      content={<div className="pop-sub" dangerouslySetInnerHTML={{ __html: ele.description }} />}
                      title={ele?.name} trigger="click">
                      {' '}<InfoCircleOutlined />
                    </Popover>
                  </div>
                  <div className="subtt" dangerouslySetInnerHTML={{ __html: limitStr(ele.description, 57)+'...' }} />
                </div>
              ))}
          </div>
        </div>

        <Field
          onChange={(e: any) => set_address(e.target.value)}
          label="Address *"
          placeholder=""
          value={address}
          className="letfield"
        />
        <Field
          onChange={(e: any) => set_building(e.target.value)}
          label="Apartment, suite, etc *"
          placeholder=""
          value={building}
          className="letfield"
        />
        <div className="thewebpack">

          {/* <Field
            type="text"
            value={street}
            onChange={(e: any) => set_street(e.target.value)}
            label="Street Name"
            placeholder=""
          />
          <Field
            type="number"
            onChange={(e: any) => set_floor(e.target.value)}
            value={floor}
            label="Street name 2"
            placeholder=""
          /> */}
          <Field
            type="text"
            value={city}
            onChange={(e: any) => set_city(e.target.value)}
            label="City *"
            placeholder=""
          />
          <Field
            // type="number"
            onChange={(e: any) => set_postal_code(e.target.value)}
            label="Post Code *"
            placeholder=""
            value={postal_code}
            className="letfield"
          />
          {/* <Field
            type="text"
            value={state}
            onChange={(e: any) => set_state_(e.target.value)}
            label="State / Province / Region"
            placeholder=""
          />
          <Field
            type="text"
            value={building}
            onChange={(e: any) => set_building(e.target.value)}
            label="Building Name"
            placeholder=""
          />
          <Field
            type="number"
            onChange={(e: any) => set_number(e.target.value)}
            value={number}
            label="Building Number"
            placeholder=""
          /> */}
        </div>

        <div className="actions mb-4">
          <div className="flex gap w-100">
            <Button
              variant="secondary"
              label="Previous"
              onClick={() => navigate("/about-you")}
            />
            <Button
              variant="primary"
              loading={loading}
              label="Submit"
              onClick={() => submit()}
            />
          </div>
        </div>
        <Button
          variant="plain"
          label="Already have an account? Login"
          onClick={() => navigate("/login")}
        />
      </div>
    </AuthContainer>
  );
}
