import { Layout, Button } from "antd";
import { UpOutlined } from '@ant-design/icons';

const { Footer } = Layout;

export default function Foot() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Footer className="footer-container">
      <p> ©{new Date().getFullYear()} The Clean Creator Community by Amyris. All Rights Reserved</p>
      <Button type="text" onClick={goToTop}>
        <UpOutlined />
        BACK TO TOP
      </Button>

    </Footer>
  )
}
