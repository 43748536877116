import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Checkbox, Alert, Space } from 'antd';
import AuthContainer from 'components/layout/AuthContainer'
import { Field, Button } from 'components/forms';
import { Google, Apple, Facebook } from 'components/Icons';
import * as auth from '../../redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { change_password } from '../../redux/AuthCRUD'
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import config from 'app/global/config';
import * as functions from 'app/global/functions';
import marklightIcon from "../../../../../assets/Svg/marklight.svg";
import { toast } from 'react-toastify';

export default function Reset() {



  const prop = {
    intro: 'Empower you the creator,  to monetise your content,  while effectively inspiring consumers to  make good, without compromise.',
    title: 'Reset your password',
    subTitle: 'Type in your new strong password, to complete this process'
  }
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();



  let strongPassword = new RegExp(
    "(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  let mediumPassword = new RegExp(
    "((?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
    // "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordTypeb, setPasswordTypeb] = useState("password");
  const [passwordInputb, setPasswordInputb] = useState("");
  const [passwordTypec, setPasswordTypec] = useState("password");
  const [passwordInputc, setPasswordInputc] = useState("");
  const [password_state, set_p_state] = useState<number>(0);


  useEffect(() => {
    dispatch(auth.actions.logout());
  }, [])

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [email, set_email] = useState('')
  const navigate = useNavigate();
  const [password, set_password] = useState("");
  const [confirm, set_confirm] = useState("");


  const handlePasswordChange = (e: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setPasswordInputb(e.target.value);
    set_password(e.target.value);
    if (strongPassword.test(e.target.value)) {
      set_p_state(4);
    } else if (mediumPassword.test(e.target.value)) {
      set_p_state(3);
    } else if (e.target.value.length >= 8) {
      set_p_state(1);
    } else {
      set_p_state(0);
    }
  };

  const handlePasswordChangec = (e: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setPasswordInputc(e.target.value);
    set_confirm(e.target.value.trim());
  };


  async function subm() {


    const code = searchParams.get("ses");
    const user = searchParams.get("uu");
    if (!code || !user) {
      toast.error('Link corrupted, click link again');
      return;
    }

    if (password === "" ||
      confirm === "") {
      toast.error('Enter all inputs!');
      return;
    }

    // console.log(password, email); return;

    if (confirm !== password) {
      toast.error("Passwords must be same!");
      return;
    }

    var b: any = {};
    b['user'] = user;
    b['password'] = password;
    b['token'] = code;

    // console.log(b); return;

    setLoading(true);
    setStatus('');
    change_password(b)
      .then(async (response) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
          setStatus(data.error);
        } else {
          toast.info("Successfully changed your password!");
          navigate("/login");
        }
      })
      .catch((error) => {
        var message = error.response.data ? error.response.data.message : 'Network error occured!';

        setLoading(false);
        setStatus(message ? message : 'Network error occured!');
      });
  }



  return (
    <AuthContainer {...prop}>

      <form onSubmit={(e: any) => {
        e.PreventDefault();
        subm();
      }} className="auth-form">
        {status &&
          <Alert
            description={`Error! ${status}`}
            type="error"
            closable
            showIcon
            onClose={() => setStatus('')}
          />}<br></br>


        <Field
          value={password}
          required
          onChange={handlePasswordChange}
          type="password"
          label="Password"
          placeholder=""
        />

        <div className="pmp_bodybottomitem">
          <div className="pmpbbipstrength">
            <div
              className="pstrength a"
              style={{
                backgroundColor: password_state > 0 ? "green" : undefined,
              }}
            ></div>
            <div
              className="pstrength b"
              style={{
                backgroundColor: password_state >= 3 ? "green" : undefined,
              }}
            ></div>
            <div
              className="pstrength c"
              style={{
                backgroundColor: password_state === 4 ? "green" : undefined,
              }}
            ></div>
          </div>
        </div>

        <div
          className="pmp_bodybottomitem"
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div className="pmpbbipasschar">
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: passwordInputb.length >= 8 ? "green" : undefined,
                }}
              >
                Eight (8) or more characters
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isUpperCase(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One (1) or more uppercase character(s)
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isNumeric(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One(1) or more numerical character(s)
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isSpecial(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One(1) or more special character(s)
              </label>
            </div>
          </div>
        </div>
        <Field
          value={confirm}
          onChange={handlePasswordChangec}
          // onChange={(e: any) => dispatch(auth_actions.actions.set_confirm(e.target.value.trim()))}
          type="password"
          label="Confirm password"
          placeholder=""
        />


        <div className="actions">
          <Button
            loading={loading}
            onClick={() => subm()} variant="primary" label="Submit" />
          <span className="or">OR</span>
          <Space direction="vertical" size="middle" className="w-100">
            <Button variant="plain" label="Back to login" onClick={() => navigate("/login")} />
          </Space>
        </div>
      </form>
    </AuthContainer>
  )
}
