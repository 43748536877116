import { useState, useRef } from "react";
import "./styles.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { editUserData, edit_pic } from "../../auth/redux/AuthCRUD";
import { toast } from "react-toastify";
import * as auth from "../../auth/redux/AuthRedux";
import profileIcon from "../../../../assets/Svg/profileicon.svg";
import editIcon from "../../../../assets/Svg/editicon.svg";
import AddIcon from "../../../../assets/Svg/Union.svg";

function PersonalInformation() {
  const dispatch = useDispatch();
  const inputFile = useRef<any>(null);

  const { user } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user,
    }),
    shallowEqual
  );

  const [editProfile, setEditProfile] = useState(false);
  const [first_name, set_first_name] = useState(user?.first_name);
  const [last_name, set_last_name] = useState(user?.last_name);
  const [phone, set_phone] = useState(user?.phone);
  const [city, set_city] = useState(user?.city);
  const [state, set_state_] = useState(user?.state);
  const [street, set_street] = useState(user?.street);
  const [building, set_building] = useState(user?.building);
  const [country, set_country] = useState(user?.country);
  const [number, set_number] = useState(user?.number);
  const [floor, set_floor] = useState(user?.floor);
  const [postal_code, set_postal_code] = useState(user?.postal_code);
  const [address, set_address] = useState(user?.address);
  const [language, set_lang] = useState(user?.language);
  const [im, set_image] = useState(user?.picture);
  const [im_link, set_image_link] = useState(user?.picture);

  function chan() {
    var body: any = {};
    body["first_name"] = first_name;
    body["last_name"] = last_name;
    body["phone"] = phone;
    body["city"] = city;
    body["state"] = state;
    body["street"] = street;
    body["country"] = country;
    body["address"] = address;
    body["number"] = number;
    body["building"] = building;
    body["floor"] = floor;
    body["postal_code"] = postal_code;

    editUserData(body)
      .then((response: any) => {
        var data = response.data;
        setEditProfile(false);
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Successfully updated profile!");
          dispatch(auth.actions.fulfillToken(data.user_token));
          dispatch(auth.actions.fulfillUser(data));
        }
      })
      .catch((error: any) => {
        setEditProfile(false);
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  const onButtonClick = () => {
    if (!editProfile) {
      return;
    }
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  function readFile(e: any) {
    var image_as_files = e.target.files[0];

    set_image(image_as_files);
    set_image_link(URL.createObjectURL(e.target.files[0]));

    let formdata = new FormData();
    formdata.append("picture", image_as_files);

    edit_pic(formdata)
      .then((response: any) => {
        var data = response.data;
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Successfully updated profile pic!");
          dispatch(auth.actions.fulfillToken(data.user_token));
          dispatch(auth.actions.fulfillUser(data));
        }
      })
      .catch((error: any) => {
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  return (
    <div className="pip_body">
      <input
        type="file"
        id="file"
        ref={inputFile}
        onChange={(event) => {
          readFile(event);
          // event.target.value = null;
        }}
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
      />

      <div className="pip_body-top">
        <a
          href={undefined}
          style={{ overflow: "hidden" }}
          onClick={() => onButtonClick()}
          className="pip_body-topprofile"
        >
          <img
            src={im_link ? im_link : profileIcon}
            alt=""
            style={{
              objectFit: im_link ? "cover" : undefined,
              clear: im_link ? "both" : undefined,
              width: im_link ? "100%" : undefined,
              height: im_link ? "100%" : undefined,
            }}
          />
        </a>
        {!editProfile === true ? (
          <div
            className="pip_body-topedit"
            onClick={() => {
              setEditProfile(true);
            }}
          >
            <div className="pybitrightedit">
              <img src={editIcon} alt="" />
            </div>
            <label>EDIT PROFILE</label>
          </div>
        ) : (
          <div className="pip_body-topedit">
            <div className="pybitrightedit"  onClick={() => onButtonClick()}>
              <img
                src={AddIcon}
                alt=""
                // style = {{marginTop: 4}}
              />
              <label>UPLOAD PHOTO </label>
            </div>
          </div>
        )}
      </div>
      <div className="pip_body-bottom">
        <div className="pip_body-bottomitem">
          <label>
            First name{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.first_name}</label>
            </div>
          ) : (
            <input
              type="text"
              placeholder="First Name"
              value={first_name}
              onChange={(e: any) => set_first_name(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div>
        <div className="pip_body-bottomitem">
          <label>
            Last name{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user.last_name}</label>
            </div>
          ) : (
            <input
              type="text"
              placeholder="Last Name"
              value={last_name}
              onChange={(e: any) => set_last_name(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div>
        <div className="pip_body-bottomitem">
          <label>
            Phone number{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.phone || "N/A"}</label>
            </div>
          ) : (
            <input
              type="tel"
              placeholder="Phone number"
              value={phone}
              onChange={(e: any) => set_phone(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div>
        <div className="pip_body-bottomitem">
          <label>
            Email address{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.email || "N/A"}</label>
            </div>
          ) : (
            <input
              value={user?.email}
              readOnly={true}
              disabled={true}
              style = {{color: 'grey'}}
              type="email"
              placeholder="johndoe@gmail.com"
              className="pip_body-bottomitemedit"
            />
          )}
        </div>
        {/* <div className="pip_body-bottomitem">
          <label>
            Language{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.language || "N/A"}</label>
            </div>
          ) : (
            <input
              placeholder="Language"
              value={language}
              onChange={(e: any) => set_lang(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div> */}
        
        <div className="pip_body-bottomitem">
          <label>
              Address{" "}
              {editProfile === true ? (
                <span className="asteriskspan">*</span>
              ) : (
                ""
              )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.address || "N/A"}</label>
           </div>
          ) : (
            <input
              type="text"
              placeholder="Apartment, suite, etc"
              value={address}
              onChange={(e: any) => set_address(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div>

        <div className="pip_body-bottomitem">
          <label>
            Apartment, suite, etc{" (optional)"}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
               <label>{user?.building || "N/A"}</label>
           </div>
          ) : (
            <input
              type="text"
              placeholder="Apartment, suite, etc"
              value={building}
              onChange={(e: any) => set_building(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div>

        {/* <div className="pip_body-bottomitem">
          <label>
            Country of residence{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.country || "N/A"}</label>
            </div>
          ) : (
            <select
              onChange={(e: any) => set_country(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            >
              <option value="">Select your country</option>
              <option value="UK">UK</option>
            </select>
          )}
        </div> */}
        <div className="pip_body-bottomitem">
          <label>
            City{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.city || "N/A"}</label>
            </div>
          ) : (
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e: any) => set_city(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div>
      
        <div className="pip_body-bottomitem">
          <label>
            Postal code{" "}
            {editProfile === true ? (
              <span className="asteriskspan">*</span>
            ) : (
              ""
            )}
          </label>
          {!editProfile === true ? (
            <div className="pip_body-bottomitemndit">
              <label>{user?.postal_code || "N/A"}</label>
            </div>
          ) : (
            <input
              type="text"
              placeholder="Postal code"
              value={postal_code}
              onChange={(e: any) => set_postal_code(e.target.value.trim())}
              className="pip_body-bottomitemedit"
            />
          )}
        </div>
      </div>
      {editProfile && (
        <button
          className="editprofilebtn"
          // loading = {true}
          onClick={() => chan()}
        >
          SAVE CHANGES
        </button>
      )}
    </div>
  );
}

export default PersonalInformation;
