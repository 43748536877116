import { useState, useEffect, useRef } from "react";
import {
  Skeleton,
  Table,
  message,
  Dropdown,
  Select,
  Button,
  DatePicker,
} from "antd";
import { DownOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { Check, Cross } from "components/Icons";
import { SidebarLayout } from "components/layout";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import * as actions from "../../analytics/_redux/analytics/Actions";
import { CreatorVerification } from "components/Icons";
import { Field } from "components/forms";
// import "./styles.scss";
// // import "./style.css";

const { RangePicker } = DatePicker;

interface DataType {
  key: React.Key;
  name: string;
  email: string;
  status: "paid" | "pending" | "notPaid";
}

const crumbs = [{ label: "", to: "/" }];

const menuItems: any = [
  {
    label: "All",
    key: "1",
    title: "All",
  },
  {
    label: "Approved",
    key: "2",
    title: "Approved",
  },
  {
    label: "Pending",
    key: "3",
    title: "Pending",
  },
  {
    label: "Declined",
    key: "4",
    title: "Declined",
  },
];

function PayoutHistory() {
  const dispatch = useDispatch<any>();
  const [kCreator, setKCreator] = useState([]);
  const [selectedCategory, setCategory] = useState("All");
  const [start, set_start_date] = useState<any>(null);
  const [date, set_date] = useState<any>(null);
  const [end, set_end_date] = useState<any>(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [messageApi, contextHolder] = message.useMessage();
  const [skip, setSkip] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [q, setQ] = useState("");
  const [headers, set_headers] = useState<any>([]);
  const [data, set_data] = useState<any>([]);




  const { listLoading, transactions } = useSelector(
    (state: any) => ({
      transactions: state.analytics.transactions,
      actionsLoading: state.analytics.actionsLoading,
      listLoading: state.analytics.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAllData("transactions", ``));
  }, []);

  useEffect(() => {
    if (date) {
      dispatch(
        actions.fetchAllData("transactions", `start=${date.startDate}&end=${date.endDate}`)
      );
    }
  }, [date]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      var heade = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Currency", key: "currency" },
        { label: "Amount", key: "amount" },
        { label: "Request date", key: "createdAt" },
        { label: "Status", key: "status" },
      ];

      set_headers(heade);
      set_data(transactions);
      // console.log(headers, data)
    }
  }, [transactions]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Full name",
      dataIndex: "name",
      sorter: true,
      render: (text: string, full: any) => {
        return (
          <span className="table-namey">
            {full.first_name.trim() || "n/A"} {full.last_name.trim() || "n/A"}
          </span>
        );
      },
    },
    {
      title: "Request date",
      dataIndex: "requestDate",
      sorter: true,
      render: (text: Date, full: any) => {
        return `${new Date(full.createdAt).toLocaleDateString()}`;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: true,
      render: (text: string, full: any) => {
        return <span>{`GBP ${full.amount}`}</span>;
      },
    },
    {
      title: "Payout date",
      dataIndex: "payoutDate",
      sorter: true,
      render: (text: Date, full: any) => {
        return `${
          full.date_approved
            ? new Date(full.date_approved).toLocaleDateString()
            : "N/A"
        }`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (text: string, full: any) => {
        return (
          <div className={`vrf-status ${full.status}`}>
            <span>
              {" "}
              <span className="status-dot">&#8226;</span>
              {full.status == "notPaid" ? "Not Paid" : full.status || ""}
            </span>
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleMenuClick = async (el: any) => {
    console.log(el?.item?.props?.title);
    setCategory(el?.item?.props?.title);
    await dispatch(
      actions.fetchAllData(
        "transactions",
        `status=${
          el?.item?.props?.title === "All"
            ? ""
            : el?.item?.props?.title?.toLowerCase()
        }`
      )
    );
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const initSearch = () => {
    if (q !== "") {
      dispatch(actions.fetchAllData("transactions", `q=${q}`));
    } else {
      dispatch(actions.fetchAllData("transactions", ``));
    }
  };

  const pageChangeManual = async (lim: any) => {
    setPageLimit(lim);
    setSkip(0);
    setPageIndex(0);
    await dispatch(
      actions.fetchAllData("transactions", `limit=${lim}&skip=${skip}`)
    );
  };

  const paginateManual = async (type: string) => {
    if (type == "prev" && pageIndex > 0) {
      setSkip(skip - 1);
      await dispatch(
        actions.fetchAllData("transactions", `limit=${pageLimit}&skip=${skip}`)
      );
      setPageIndex(pageIndex - 1);
    }

    // if (type == 'next' && (pageIndex + 1) * pageLimit <= tan) {
    //   setSkip(skip + 1);
    //   await dispatch(actions.fetchAllData('transactions', `limit=${pageLimit}&skip=${skip + 1}`));
    //   setPageIndex(pageIndex + 1)
    // }
  };

  return (
      <div className="verification-container">
        <div className="search-content">
          <Field
            placeholder="Search "
            type="text"
            label="Search"
            onChange={(e) => setQ(e.target.value)}
            onPressEnter={initSearch}
          />
          <div className="dorpdown flexed">
            <div>
              <span>Filter: Status</span>
              <Dropdown menu={menuProps} trigger={["click"]}>
                <div className="user">
                  <span className="labele">{selectedCategory}</span>
                  <DownOutlined color="#370E78" />
                </div>
              </Dropdown>
            </div>
            <div className="">
                <p className="hclabel">Date range</p>
                <div className="analyselection__cnt">
                  <div className="analysection_cnt">
                    <DateRangePicker
                      onApply={(a, b) => {
                        set_date({
                          startDate: b.startDate.format("YYYY-MM-DD"),
                          endDate: b.endDate.format("YYYY-MM-DD"),
                        });
                        // handleSubmit();
                      }}
                      initialSettings={{
                        startDate: "1/1/2023",
                        endDate: new Date(),
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          backgroundColor: "transparent",
                          width: "100%",
                        }}
                      />
                    </DateRangePicker>
                    {/* <img
                      className="vblsc_right"
                      style={{ paddingLeft: 20 }}
                      src={dropdown}
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>

          </div>
        </div>
        {transactions && transactions.length === 0 &&
          <Skeleton loading={listLoading} active paragraph={{ rows: 10 }} />
        }
        {windowSize?.current[0] >= 768 && (
          <>
            {transactions && transactions.length > 0 && (
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={transactions}
                pagination={false}
              />
            )}
          </>
        )}
        {windowSize?.current[0] < 768 && (
          <div className="mobile-list-view">
            {transactions &&
              transactions.length > 0 &&
              transactions.map((creator: any, index: number) => (
                <Link
                  to={`/creator/${creator._id}`}
                  className="mobile-l-item"
                  key={index}
                >
                  <div className="label-value">
                    <span className="label">FULL name</span>
                    <span className="value name">{`${creator.first_name} ${creator.influencerlast_name}`}</span>
                  </div>
                  <div className="label-value">
                    <span className="label">email address</span>
                    <span className="value">{creator.email}</span>
                  </div>
                  <div className="label-value">
                    <span className="label">status</span>
                    <span className="value">
                      <div className={`vrf-status ${creator.status}`}>
                        <span>&#8226;{creator.status}</span>
                      </div>
                    </span>
                  </div>
                </Link>
              ))}
          </div>
        )}
        <div className="paginate">
          <Button
            type="primary"
            onClick={() => paginateManual("prev")}
            disabled={pageIndex <= 0}
          >
            <LeftOutlined />
            Prev
          </Button>
          <div className="page">
            <Select
              defaultValue=""
              style={{ width: 120 }}
              onChange={pageChangeManual}
              options={[
                { value: "", label: "Page size" },
                { value: "20", label: "20/page" },
                { value: "25", label: "25/page" },
                { value: "30", label: "30/page" },
                { value: "40", label: "40/page" },
                { value: "60", label: "60/page" },
              ]}
            />
          </div>
          {/* <Button type='primary' onClick={() => paginateManual('next')} disabled={!((pageIndex + 1) * pageLimit <= creatorsCount)}> */}
          {/* Next
            <RightOutlined />
          </Button> */}
        </div>
      </div>
  );
}

export default PayoutHistory;
