import React from 'react'
import { Select } from 'antd';

export default function Selectable({ options, value, onChange, label, required, searchable }: any) {
  return (
    <div className="selectable form-item">
      {label && <label htmlFor="">
        {label || ''}
        {required && <span className='required'>*</span>}
      </label>}
      {options && options.length > 0 &&
        <Select
          showSearch={searchable || true}
          style={{ width: 200 }}
          placeholder="Search to Select"
          onChange={onChange}
          value={value}
          optionFilterProp="children"
          filterOption={(input: any, option: any) => option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => (optionA.label.toLowerCase() > optionB.label.toLowerCase()) ? 1 : -1}
          // filterSort={(optionA, optionB) =>
          //   (optionA?.label ?? '').toLowerCase().includes((optionB?.label ?? '').toLowerCase())
          // }
          options={options}
        />
      }
    </div>
  )
}
