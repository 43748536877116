import * as requestFromServer from "./Crud";
import { ContentSlice, callTypes } from "./Slice";
import { toast } from 'react-toastify';

const { actions } = ContentSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'contents') {
        dispatch(actions.allContents(res.success));
        return;
      } 
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getContentsAll = (type = 'content', q = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchContentAll(type, q)
    .then(response => {
      let res = response.data;
      // console.log('REESE', res);
      dispatch(actions.contentList(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch content list";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleContent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchContentDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.contentDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch post details";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const create = (payload, type = 'book') => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createData(payload, type)
//     .then(response => {
//       let res = response.data;
//       return res;
//     })
//     .catch(error => {
//       error.clientMessage = "Cannot create a product at the moment";
//       toast.error(error?.response?.data?.error?.response?.errormessage || error?.response?.data?.error?.response?.returnvalue?.message || 'Error creating booking!');
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const reset = () => dispatch => {
  dispatch(actions.reset());
};

