import { CreatorVerification } from "components/Icons";
import { DatePickerProps, Dropdown, Empty, Skeleton } from "antd";
import { DatePicker, TimePicker } from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import CommunityLayout from "components/layout/CommunityLayout";
import React, { useState, useRef, useEffect } from "react";
import * as actions from "../_redux/community/Actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import vsimage from "../../../../assets/images/vsimage.png";
import dropdown from "../../../../assets/Svg/dropdownicon.svg";
import topicon from "../../../../assets/Svg/topicicon.svg";
import brand from "../../../../assets/Svg/brandicon.svg";
import etime from "../../../../assets/Svg/etimeicon.svg";
import ecapacity from "../../../../assets/Svg/ecapacity.svg";
import ecalendar from "../../../../assets/Svg/ecalendaricon.svg";
import search from "../../../../assets/Svg/searchicon.svg";
import modal1 from "../../../../assets/images/modal1.png";
import modal2 from "../../../../assets/images/modal2.png";
import modalbackground from "../../../../assets/images/modalbackground.png";
import crossicon from "../../../../assets/Svg/Cross.svg";
import "./styles.css";
import { ProgressiveImage } from "components/shared";
import { Field } from "components/forms";
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

const topiclist = [
  {
    id: "topiclist1",
    title: "Please Select",
  },
  {
    id: "topiclist2",
    title: "Branding topic",
  },
  {
    id: "topiclist3",
    title: "Virtual Session topic",
  },
];

const brandlist = [
  {
    id: "brandlist1",
    title: "Please Select",
  },
  {
    id: "brandlist2",
    title: "JVN",
  },
  {
    id: "brandlist3",
    title: "Rose Inc",
  },
];

const prioritylist = [
  {
    id: "priority1",
    title: "HIGH PRIORITY: RESPONSE WITHIN 24 HOURS",
  },
  {
    id: "priority2",
    title: "MID PRIORITY: RESPONSE WITHIN 1-2 WORKING DAYS",
  },
  {
    id: "priority3",
    title: "LOW PRIORITY: RESPONSE WITHIN 3-4 WORKING DAYS",
  },
];

const menuItems: any = [
  {
    label: 'All',
    key: '1',
    title: 'All'
  },
  {
    label: 'THIS WEEK',
    key: '2',
    title: 'THIS WEEK'
  },
  {
    label: 'NEXT WEEK',
    key: '3',
    title: 'NEXT WEEK'
  },
];

const dateList = [
  {
    label: "All",
    key: "1",
    title: "All",
  },
  {
    id: "THIS WEEK",
    title: "THIS WEEK",
    key: "2",
  },
  {
    id: "NEXT WEEK",
    key: "3",
    title: "NEXT WEEK",
  },
];

function Drop() {
  return <img className="vblsc_right" src={dropdown} alt="" />;
}

export default function CommunityHub() {
  const inputFile = useRef<any>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { user, listLoading, actionsLoading, workspaces } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      listLoading: state.products.listLoading,
      workspaces: state.community.workspaces,
      user: state.auth?.user?.user,
    }),
    shallowEqual
  );

  const [pageView, setPageView] = useState("VIRTUAL SESSIONS");
  const [topicdrop, setTopicdrop] = useState<any>(false);
  const [topicselect, setTopicselect] = useState<string>("Please Select");
  const [service_id, set_service_id] = useState(null);
  const [resource_id, set_resource_id] = useState<any>(null);
  const [prioritydrop, setPrioritydrop] = useState(false);
  const [priorityselect, setPriorityselect] = useState("Please Select");
  const [filter_date, set_filter_date] = useState("Please Select");
  const [branddrop, setBranddrop] = useState(false);
  const [brandselect, setBrandselect] = useState("Please Select");
  const [staffs, set_staffs] = useState([]);
  const [resources, set_resources] = useState([]);
  const [resourcesFmt, setResourcesFmt] = useState([]);
  const [topics, set_topics] = useState([]);
  const [topicsFmt, settopicsFmt] = useState([]);
  // const [eventsAll, setEventsAll] = useState([]);
  const [date, set_date] = useState<any>(null);
  const [date_r, set_date_r] = useState<any>(null);
  const [times, set_times] = useState<any>([]);
  const [time, set_time] = useState<any>(null);
  const [staff_id, set_staff_id] = useState<any>(null);
  const [full_name, set_full_name] = useState<any>(
    `${user.first_name} ${user.last_name}`
  );
  const [email_a, set_email_a] = useState<any>(`${user.email}`);
  const [phon, set_phon] = useState<any>(
    `${user?.phone === undefined ? "" : user?.phone}`
  );
  const [topicdd, set_topicdd] = useState<any>(null);
  const [message, set_message] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);
  const [modal, setModal] = useState(false);
  const [slEvents, setSlEvents] = useState<any>([]);
  const [image_upload, set_image] = useState<any>();
  const [image_upload_link, set_image_link] = useState<any>();
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [q, setQ] = useState("");
  const [tabdrop, settabdrop] = useState(false);
  const [selectedCategory, setCategory] = useState('All');
  const [selectedTopic, setSelectedTopic] = useState<any>('Please Select');
  const [selectedResource, setSelectedResource] = useState<any>('Please Select');

  const { eventsAll, event_loader } = useSelector(
    (state: any) => ({
      event_loader: state.community.actionsLoading,
      eventsAll: state.community.eventsAll,
      currentUser: state.auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAllData(`workspaces`));
    dispatch(actions.getEventsAll());

    //get staffs
    dispatch(actions.fetchAllData(`staffs`)).then((res: any) => {
      set_staffs(res.success);
    });

    //get staffs
    dispatch(actions.fetchAllData(`resources`)).then((res: any) => {
      set_resources(res.success);
    });

    dispatch(actions.fetchAllData(`services`)).then((res: any) => {
      set_topics(res.success);
    });
  }, []);


  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    let path_curr = currentPath.split("/").pop();
    if (path_curr === 'help') {
      setPageView("HELP CENTRE")
    } else if (path_curr === 'events') {
       setPageView("EVENTS & EXPERIENCES")
    } else {
      setPageView("VIRTUAL SESSIONS")
    }
    console.log(path_curr)

  }, [location.pathname]);

  useEffect(() => {
    const tops: any = [];
    if (topics && topics.length > 0) {
      topics.map((topic: any) => {
        tops.push({
          label: topic.name || 'General Meeting',
          key: topic?.id,
          title: topic?.name || 'General Meeting',
        })
      })
      settopicsFmt(tops);
    }

    if (resources && resources.length > 0) {
      const res: any = [];
      resources.map((el: any) => {
        res.push({
          label: el.name || 'n/A',
          key: el?.id,
          title: el?.name || 'n/A',
        })
      })
      setResourcesFmt(res);
    }
  }, [topics, resources])

  useEffect(() => {
    if (eventsAll && eventsAll.length > 0) {
      const otherEvents: any = [];
      let spotEvent: any = {};
      let eEvents = [...eventsAll];
      eEvents.map((event: any, index: number) => {
        if (event?.spotlight) {
          spotEvent = event;
        } else {
          otherEvents.push(event);
        }
      });
      otherEvents.sort((a: any, b: any) => {
        if (new Date(a.date) > new Date(b.date)) {
          return 1;
        }
        if (new Date(a.date) < new Date(b.date)) {
          return -1
        };
        return 0;
      });
      if (spotEvent && spotEvent?._id) otherEvents.unshift(spotEvent);
      setTimeout(() => {
        setSlEvents([...otherEvents])
      }, 200)
    }
  }, [eventsAll]);

  useEffect(() => {
    if (date && service_id) {

      const allowed_idsss = ['164748000000064006', '164748000000064022', '164748000000064052'];
      const allowed_staffs: any = {
        '164748000000026078': '164748000000085014', // Resource ID: Staff ID
        '164748000000026080': '164748000000071046', // Resource ID: Staff ID
      };

      // const staffss = []

      /*

      staff 1; Heather McDermott (164748000000071046)
      staff 2; Amanda Ortize (164748000000085014)


      resource 1: JVN (164748000000026078)
      resource 2: Rose Inc (164748000000026080)

      */
      set_times([]);
      (async () => {
        var promiseArray: any = [];

        staffs.forEach((staff: any, index: number) => {
          if (index > 0) {
          
            promiseArray.push(
              new Promise((resolve, reject) => {
                dispatch(
                  actions.fetchAllData(
                    `availability`,
                    `?service_id=${service_id}&staff_id=${staff.id}&selected_date=${date}`
                  )
                )
                  .then((res: any) => {
                    var resp = res.success;
                    resp["staff"] = staff.id;
                    resp["staff_name"] = staff.name;
                    if (!allowed_idsss.includes(service_id)) {
                      resolve(resp);
                    } else {
                      if (allowed_staffs[resource_id] === staff.id) {
                          resolve(resp);
                      } else {
                        resolve({}); 
                      }
                    }

                  })
                  .catch(function (e: any) {
                    reject(e);
                  });
              })
            );
          }
          
        });

        var r_data = await Promise.all(promiseArray);
        var r_data = r_data.filter(obj => Object.keys(obj).length !== 0);
        console.log("hadcjsdc", r_data)
        set_times(r_data);
        // console.log(r_data);
      })();
    } else {
      set_times([]);
    }
  }, [date, resource_id, service_id]);

  function convertTo24Hour(timeStr: any) {
    // const time = new Date(`1970-01-01 ${timeStr}`);
    // let hours: any = time.getHours();
    // let minutes: any = time.getMinutes();
    // let seconds: any = time.getSeconds();

    // console.log(time)

    // hours = hours < 10 ? `0${hours}` : hours;
    // minutes = minutes < 10 ? `0${minutes}` : minutes;
    // seconds = seconds < 10 ? `0${seconds}` : seconds;

    // return `${hours}:${minutes}:${seconds}`;
    const time24hr = moment(timeStr, 'hh:mm A').format('HH:mm');

    return time24hr;
}

  function submit() {
    if (loading) {
      return;
    }
    if (!resource_id || !service_id || !staff_id || !time) {
      toast.error("Select topic, brand, date, and time!");
      return;
    }

    var b: any = {};
    var customer: any = {};
    var add: any = {};

    customer["name"] = `${user.first_name} ${user.last_name}`;
    customer["first_name"] = `${user.first_name}`;
    customer["last_name"] = `${user.last_name}`;
    customer["email"] = `${user.email}`;
    customer["phone_number"] = `${user?.phone || '9876543201'}`;

    // add['name'] = `${user.email}`;

    // b["resource_id"] = resource_id;
    b["service_id"] = service_id;
    b["staff_id"] = staff_id;
    // b["from_time"] = date_r + " " + time;
    b["from_time"] = `${date_r} ${time}`; 
    b["customer_details"] = customer;
    b["additional_fields"] = add;
    b["user"] = user._id;


    setLoading(true);
    dispatch(actions.create(b))
      .then((data: any) => {
        setLoading(false);
        console.log(data);
        if (data) {
          toast.success("Successfully created booking!");
          setSelectedTopic('Please select');
          set_service_id(null);
          setSelectedResource('Please select');
          set_resource_id(null);
          set_date(null);
        } else {
        }

      })
      .catch((error: any) => {
        setLoading(false);
        console.log("error: ", error);
        toast.error("Error occured while creating booking");
      });
  }


  function getCurrentDate(date: string) {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    set_date(new Date(dateString).toISOString().slice(0, 10));
    set_date_r(getCurrentDate(dateString));
  };

  function readFile(e: any) {
    var image_as_files = e.target.files[0];
    // console.log(image_as_files);
    set_image(image_as_files);
    set_image_link(URL.createObjectURL(e.target.files[0]));
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    initSearch();
  };

  const initSearch = () => {
    if (q !== '') {
      dispatch(actions.getEventsAll('search', `?q=${q}`));
    } else {
      dispatch(actions.getEventsAll());
    }
  };

  function sendMessage() {
    if (loading) {
      return;
    }
    if (!full_name || !email_a || !message || !topicdd) {
      toast.error("All inputs required");
      return;
    }
    const payload = new FormData();
    payload.append('fullname', full_name)
    payload.append('email', email_a)
    payload.append('phone', phon || null)
    payload.append('priority', priorityselect)
    payload.append('topic', topicdd)
    payload.append('message', message)
    if (image_upload && image_upload?.name) {
      payload.append('image', image_upload, image_upload?.name)
    }

    setLoading(true);
    dispatch(actions.create(payload, "email"))
      .then((data: any) => {
        setLoading(false);
        console.log(data);
        if (data) {
          set_phon('')
          set_topicdd('')
          set_message('')
          set_image('')
          toast.success("Successfully sent info!");
        } else {
        }
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("error: ", error);
        toast.error("Error occured while creating booking");
      });
  }

  const limitStr = (str: string, length: number) => {
    const spl = str.split("");
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join("") + "...";
    }
    return newStr;
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };


  const bookYourEvent = () => {
    dispatch(actions.bookEvent(selectedEvent._id)).then((res: any) => {
      setModal(false);
      dispatch(actions.getEventsAll());
    });
  };

  function filterDataByWeek(data: any, key = '2') {
    if (key === '1') {
      dispatch(actions.getEventsAll());
      return;
    }

    const today = new Date();
    const thisWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const nextWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    var date_to_measure = new Date();
    if (key == '2') {
      date_to_measure = thisWeekStartDate;
    } else {
      date_to_measure = nextWeekStartDate;
    }
    const newData = data.filter((item: any) => {
      const itemDate = new Date(item.date);
      return itemDate >= date_to_measure && itemDate < new Date(date_to_measure.getFullYear(), date_to_measure.getMonth(), date_to_measure.getDate() + 7);
    });

    setSlEvents(newData);

  }

  const buttonList = [
    { id: "button-1", title: "VIRTUAL SESSIONS" },
    { id: "button-2", title: "EVENTS & EXPERIENCES" },
    { id: "button-3", title: "HELP CENTRE" },
  ];

  const handleMenuClick = (el: any) => {
    setCategory(el?.item?.props?.title)
    filterDataByWeek(eventsAll, el?.key);
  }

  const handleTopicsMenuClick = (el: any) => {
    const items: any = [...topicsFmt];
    Object.keys(items).map((id: any) => {
      if (items[id]?.key == el.key) {
        setTopicselect(items[id]?.label);
        setSelectedTopic(items[id]?.label);
        set_service_id(items[id]?.key);
      }
    });
  };

  const handleResourcesMenuClick = (el: any) => {
    const items: any = [...resourcesFmt];
    Object.keys(items).map((id: any) => {
      if (items[id]?.key == el.key) {
        setSelectedResource(items[id]?.label);
        setBrandselect(items[id]?.label);
        set_resource_id(items[id]?.key);
      }
    });
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const topicsMenuProps = {
    items: topicsFmt,
    onClick: handleTopicsMenuClick,
  };

  const resourceMenuProps = {
    items: resourcesFmt,
    onClick: handleResourcesMenuClick,
  };

  const Virtualsessions = (
    <div className="virtualsessions_body">
      <div className="virtualsessions_body-left">
        <p className="vblp1">Your Welcome 1-on-1 Session</p>
        <div className="vblcontainer">
          <img src={vsimage} alt="" />
        </div>
        <p className="vblp2">
          Exclusive and personalized virtual sessions to get you started
        </p>
        <p className="vblp2">We'll dive into:</p>
        <ol className="list-disc space-y-3 ml-[30px] mt-[5px] vblp3">
          <li>Your favourite products and how to use them</li>
          <li>Resolve any product questions and</li>
          <li>
            How to Navigate the platform and understand the commmission system.
          </li>
        </ol>
      </div>
      <div className="virtualsessions_body-right">
        <div className="vbrtop">
          <label className="vblp1">
            Book a new session with our beauty advisors
          </label>
        </div>

        <div className="vblselection">
          {topics && topics.length > 0 &&
            <div className="dorpdown flexed">
              <div>
                <span>Topic</span>
                <Dropdown menu={topicsMenuProps} trigger={["click"]}>
                  <div className="user">
                    <span className="labele">{selectedTopic}</span>
                    <DownOutlined color="#370E78" />
                  </div>
                </Dropdown>
              </div>
            </div>
          }

          {resources && resources.length > 0 &&
            <div className="dorpdown flexed">
              <div>
                <span>Select brand</span>
                <Dropdown menu={resourceMenuProps} trigger={["click"]}>
                  <div className="user">
                    <span className="labele">{selectedResource}</span>
                    <DownOutlined color="#370E78" />
                  </div>
                </Dropdown>
              </div>
            </div>
          }
          <div className="">
            <p className="vbllabel">Select date</p>
            <div className="pick-me-picker">
              <DatePicker
                onChange={onDateChange}
                style={{ color: 'red' }}
                suffixIcon={<Drop />}
                disabledDate = {(current) => current && current < moment().startOf('day')}
              />

            </div>
          </div>

          {date && (
            <div className="">
              {times &&
                times.map((ele: any, i: number) => {
                  return (
                    <div key={i} className="vstop">
                      <label className="vstoplabel">{ele.staff_name}</label>
                      <div className="vstopbottom">
                        {ele.data.map((hahaa: any, innn: number) => {
                          return (
                            <a
                              href="javascript:;"
                              onClick={() => {
                                // var ddd = new Date(date);
                                set_time(hahaa);
                                set_staff_id(ele.staff);
                              }}
                              key={innn}
                              style={{
                                borderColor:
                                  time && (time === hahaa) && staff_id && (staff_id == ele.staff)
                                    ? "#370e78"
                                    : undefined,
                              }}
                              className="vstopbottomitem"
                            >
                              <label className="vstime">
                                {hahaa} ({ele.time_zone})
                              </label>
                            </a>
                          );
                        })}

                        {/* <div className="vstopbottomitem">
                      <label className="vstime">3:00PM (WAT)</label>
                    </div>
                    <div className="vstopbottomitem">
                      <label className="vstime">5:00PM (WAT)</label>
                    </div>
                    <div className="vstopbottomitem">
                      <label className="vstime">8:00PM (WAT)</label>
                    </div> */}
                      </div>
                    </div>
                  );
                })}
              {/* <div className="vstop">
              <label className="vstoplabel">Johnny Doe</label>
              <div className="vstopbottom">
                <div className="vstopbottomitem">
                  <label className="vstime">2:00PM (WAT)</label>
                </div>
                <div className="vstopbottomitem">
                  <label className="vstime">3:00PM (WAT)</label>
                </div>
                <div className="vstopbottomitem">
                  <label className="vstime">5:00PM (WAT)</label>
                </div>
                <div className="vstopbottomitem">
                  <label className="vstime">8:00PM (WAT)</label>
                </div>
              </div>
            </div> */}
            </div>
          )}
        </div>

        <button onClick={() => submit()} className="vblbutton">
          {loading ? "LOADING..." : "CONFIRM BOOKING"}
        </button>
      </div>
    </div>
  );

  const Eventsandexperiences = (
    <div className="eventsandexperiences">
      <div className="">
        <p className="eaeupcoming">Upcoming events</p>
      </div>

      <div className="eaeupcoming-nav">
        <div className="search-content">
          <Field
            showCount
            placeholder="Search"
            type="text"
            label="Search"
            onChange={(e: any) => setQ(e.target.value)}
            onPressEnter={handleSubmit}
          />
          <div className="dorpdown flexed">
            <div>
              <span>Filter: Status</span>
              <Dropdown menu={menuProps} trigger={["click"]}>
                <div className="user">
                  <span className="labele">{selectedCategory}</span>
                  <DownOutlined color="#370E78" />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        {loading && <Skeleton paragraph={{ rows: 10 }} />}
        {!loading && slEvents && slEvents.length > 0 ? (
          <div className="eventbody">
            {slEvents.map((item: any, index: number) => (
              <div className="evlist-body" key={index} style={{ marginBottom: 30 }}>
                <div className="eventbody-top">
                  <div className={`evlist-domimg ${item?.spotlight && "spot"}`}>
                    <ProgressiveImage
                      imgSrc={item?.banner || null}
                      previewSrc={require("assets/images/holder.png")}
                      width="100%"
                      height="75%"
                      spotlight={item?.spotlight || false}
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        maxHeight: "254px",
                      }}
                    />
                  </div>
                  {item?.spotlight === true && (
                    <div className="evlist-spotlight">
                      <label>EVENT SPOTLIGHT</label>
                    </div>
                  )}
                </div>

                {item?.logo && (
                  <img
                    src={item.eventlogo}
                    alt="Companyname"
                    className="evlist-logo"
                  />
                )}

                <h3 className="eventtitle">{limitStr(item?.title, 44)}
                
                <Popover 
                  content={
                    <div>
                      {item?.description.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^a-zA-Z0-9 ]/g, "")}
                    </div>
                  }
                  title={item?.title || `Event description`} trigger="click">
                    {' '}<InfoCircleOutlined />
              </Popover>
                </h3>
                <div
                  className="eventparagraph"
                  dangerouslySetInnerHTML={{
                    __html: limitStr(item?.description, 200),
                  }}
                />
                <div className="evlistcnt">
                  <img src={ecalendar} alt="" />
                  <label className="evlist">{item?.location}</label>
                </div>
                <div className="evlistcnt">
                  <img src={ecalendar} alt="" />
                  <label className="evlist">
                    {item?.date
                      ? moment(item?.date).format('Do MMM, YYYY')
                      : "not available"}
                  </label>
                </div>
                <div className="evlistcnt">
                  <img src={etime} alt="" />
                  <label className="evlist">
                    {item?.date
                      ? new Date(item?.date)?.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                      : "not available"}
                  </label>
                </div>
                {/* <div className="evlistcnt">
                  <img src={ecapacity} alt="" /> 
                  <label className="evlist">{item?.capacity || 'not available'}</label>
                </div> */}
                <button
                  onClick={() =>

                    event_loader ? console.log('sd') :

                      item && item?.attendees.includes(user._id) ?
                        dispatch(actions.create(item._id, 'cancel_event'))
                        :
                        dispatch(actions.create(item._id, 'attend_event'))

                  }
                  className="evlist-btn">
                  {
                    item?.attendees.includes(user._id) ?
                      'Cancel Booking' :
                      item.spotlight ? 'RSVP'
                        :
                        'Book your spot'
                  }</button>
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );

  const Helpcentre = (
    <div className="helpcenter">
      <div className="helpcenter_body-left">
        <p className="hcpone">Need help?</p>
        <div className="hcselection">
          <div className="">
            <p className="hclabel">Fullname</p>
            <div className="hcinput_cnt">
              <input
                value={full_name}
                onChange={(e: any) => set_full_name(e.target.value)}
                type="text"
                placeholder="e.g John Doe"
              />
              <label>0/20</label>
            </div>
          </div>
          <div className="">
            <p className="hclabel">Email address</p>
            <div className="hcinput_cnt">
              <input
                type="email"
                value={email_a}
                onChange={(e: any) => set_email_a(e.target.value)}
                placeholder="e.g johndeo@gmail.com"
              />
              <label>0/20</label>
            </div>
          </div>
          <div className="">
            <p className="hclabel">Phone number</p>
            <div className="hcinput_cnt">
              <input
                type="Tel"
                value={phon}
                onChange={(e: any) => set_phon(e.target.value)}
                placeholder="e.g +44 1234 5678"
              />
              <label>0/20</label>
            </div>
          </div>
          <div className="">
            <p className="hclabel">Topic</p>
            <div className="hcinput_cnt">
              <input
                onChange={(e: any) => set_topicdd(e.target.value)}
                type="text"
                value={topicdd}
                placeholder="e.g Beauty"
              />
              <label>0/20</label>
            </div>
          </div>
          <div className="vblselection">
            <div className="">
              <p className="hclabel">Priority level</p>
              <div className="vblselection__cnt">
                <div
                  className="hcinput_cnt"
                  onClick={() => setPrioritydrop(true)}
                >
                  <label>{priorityselect}</label>
                  {/*<label>0/20</label> 
              {/* <select>
                <option>Please select</option>
              </select> */}
                  <img src={dropdown} alt="" />
                </div>
                {prioritydrop && (
                  <div className="">
                    <div
                      className="vblscdropoverlay"
                      onClick={() => setPrioritydrop(false)}
                    />
                    <div className="vblsdrop">
                      {prioritylist.map((plist, key) => (
                        <label
                          key={key}
                          onClick={() => {
                            setPriorityselect(plist.title);
                            setPrioritydrop(false);
                          }}
                          className={
                            key === prioritylist.length - 1
                              ? ""
                              : "vblsdroplabel"
                          }
                        >
                          {plist.title}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="">
            <p className="hclabel">Message</p>
            <div className="hcarea_cnt">
              <textarea
                value={message}
                onChange={(e: any) => set_message(e.target.value)}
                placeholder="Write your message here"
              />
              <label>0/20</label>
            </div>
          </div>

          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={(event) => {
              readFile(event);
            }}
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
          />

          <div className="hcupload" onClick={() => onButtonClick()}>
            <img src={require("assets/images/upload-plus.png")} alt="" />
            <label>{image_upload ? image_upload?.name : "UPLOAD FILE"}</label>
          </div>
        </div>

        <button onClick={() => sendMessage()} className="hcbutton">
          SEND MESSAGE
        </button>
      </div>
    </div>
  );

  return (
    <>
      {modal && (
        <div className="modal">
          <div className="modalOVerlay" />
          <div className="modalWrap">
            <div
              className="modalbody"
              style={{ backgroundImage: `url(${modalbackground})` }}
            >
              <div className="modalcontent">
                <img
                  src={crossicon}
                  alt=""
                  className="modalclose"
                  onClick={() => setModal(false)}
                />
                <p className="modaltitle">Book your welcome 1on1 session</p>
                <p className="modalsubtitle">with your beauty advisor.</p>
                <div className="modalbottom">
                  <div className="modalleft">
                    <p className="modallefttitle">
                      An exclusive personalised virtual <br /> session to get
                      you started
                    </p>
                    <p className="modaldiv">We’ll dive into</p>
                    <ol className="list-disc space-y-3 ml-[30px] mt-[5px] modallst">
                      <li className="modallistitem">
                        Your favourite products and how to use them
                      </li>
                      <li className="modallistitem">
                        Resolve any product questions
                      </li>
                      <li className="modallistitem">
                        How to navigate the platform
                      </li>
                      <li className="modallistitem">
                        Understanding the commission system
                      </li>
                    </ol>
                    <button className="modalschebtn" onClick={bookYourEvent}>
                      Schedule Now
                    </button>
                  </div>
                  <div className="modalright">
                    <div className="modalrightitem">
                      <img src={modal1} alt="" />
                      <p className="modalrightitemname">Jason Hoffman</p>
                      <p className="modalrightitemabt">
                        VP of beauty & product experience
                      </p>
                    </div>
                    <div className="modalrightitem">
                      <img src={modal2} alt="" />
                      <p className="modalrightitemname">Regina Sanches</p>
                      <p className="modalrightitemabt">
                        Head of beauty & product education
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <CommunityLayout
        headerTitle="Community Hub"
        headerIcon={CreatorVerification}
      >
        <div className="comhubcnt">
          <div className="communityhubButtons">
            <button
              autoFocus={true}
              className={
                pageView === "VIRTUAL SESSIONS"
                  ? "active_button"
                  : "notactive_button"
              }
              onClick={() => {
                // setPageView("VIRTUAL SESSIONS")
                navigate(`/community/virtual-sessions`);
              }}
            >
              VIRTUAL SESSIONS
            </button>
            <button
              className={
                pageView === "EVENTS & EXPERIENCES"
                  ? "active_button"
                  : "notactive_button"
              }
              onClick={() => {
                navigate(`/community/events`);
                // setPageView("EVENTS & EXPERIENCES")
              }}
              // onClick={() => setPageView("EVENTS & EXPERIENCES")}
            >
              EVENTS & EXPERIENCES
            </button>
            <button
              className={
                pageView === "HELP CENTRE"
                  ? "active_button"
                  : "notactive_button"
              }
              onClick={() => {
                navigate(`/community/help`);
                // setPageView("HELP CENTRE")
              }}
            >
              HELP CENTRE
            </button>
          </div>
          <div className="communityhubbuttonsmobile">
            <div className="cbbmtop" onClick={() => settabdrop(true)}>
              <label>{pageView}</label>
              <img src={dropdown} alt="" />
            </div>
            {tabdrop && (
              <div className="">
                <div
                  className="vblscdropoverlay"
                  onClick={() => settabdrop(false)}
                />
                <div className="vblsdrop">
                  {buttonList.map((btnlist: any, key: number) => (
                    <div
                      className={`dboxlabel ${key === buttonList.length - 1 ? "" : "vblsdroplabel"
                        }`}
                    >
                      <label
                        key={key}
                        onClick={() => {
                          setPageView(`${btnlist.title}`);
                          settabdrop(false);
                        }}
                      >
                        {btnlist.title}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="communityhub_body">
            {pageView === "VIRTUAL SESSIONS" && Virtualsessions}
            {pageView === "EVENTS & EXPERIENCES" && Eventsandexperiences}
            {pageView === "HELP CENTRE" && Helpcentre}
          </div>
        </div>
      </CommunityLayout>
    </>
  );
}

