import { useEffect, useState, useCallback } from 'react';
import AuthContainer from 'components/layout/AuthContainer'
import { Field, Button, CheckButton, SocialInput } from 'components/forms';
import { useNavigate } from 'react-router-dom';
import * as auth_actions from "../../redux/AuthRedux";
import * as functions from "../../../../global/functions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSocials, addSocials } from "../../redux/AuthCRUD";
import facebookIcon from "../../../../../assets/images/facebook.png";
import googleIcon from "../../../../../assets/images/google.png";
import instagramIcon from "../../../../../assets/images/instagram.png";
import pinterestIcon from "../../../../../assets/images/pinterest.png";
import tiktokIcon from "../../../../../assets/images/tiktok.png";
import snapchatIcon from "../../../../../assets/images/snapchat.png";
import twitterIcon from "../../../../../assets/images/twitter.png";
import youtubeIcon from "../../../../../assets/images/youtube.png";
import cancelIcon from "../../../../../assets/Svg/canceicon.svg";
import markIcon from "../../../../../assets/Svg/markicon.svg";
import arrowIcon from "../../../../../assets/Svg/arrowicon.svg";
import closeIcon from "../../../../../assets/Svg/closeicon.svg";
import arrowdownIcon from "../../../../../assets/Svg/arrowdown.svg";
import addIcon from "../../../../../assets/Svg/addicon.svg";
import warningIcon from "../../../../../assets/Svg/warningicon.svg";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  IResolveParams,
} from "reactjs-social-login";

export default function Channels() {
  const [facebookSelected, setFacebookSelected] = useState(false);

  var [socials, set_socials] = useState<any>([]);

  const onLoginStart = useCallback(() => {
    // alert('login start');
    // console.log(process.env.REACT_APP_TWITTER_V2_APP_KEY);
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    // alert('logout success');
  }, []);

  const [selected, setSelected] = useState<any>([]);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();


  const navigate = useNavigate();
  const prop = {
    title: 'Let\'s get started',
    titleB: 'Connect channels',
    subTitle: 'Connect at least one social account and make sure to include the correct URL/link of your account',
    steps: true,
  }

  const { user } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!user) {
      navigate("/register")
    }
  }, []);


  function sub() {

    if (socials.length === 0) {
      toast.error('Input at least 1 social profile urls!'); return;
    }

    socials.map((ele: any, i: number) => {
      var b: any = {};
      if (!functions.isValidUrl(ele.username)) {
        toast.error(`Input a valid URL to your ${ele.type} profile`); 
      } else {
        b["type"] = ele.type;
        b["username"] = ele.username;
        addSocials(b)

        if (socials.length === (i + 1)) {
          navigate("/about-you");
        }
      }
      
    })

  }

  useEffect(() => {
    getSocia();
  }, []);


  const handleSelectedSocialMedia = (id: string) => {
    if (selected.includes(id)) {
      setSelected((prevSelected: any) =>
        prevSelected.filter((s: any) => s !== id)
      );
    } else {
      setSelected((prevSelected: any) => [...prevSelected, id]);
    }
  };

  function getSocia() {
    getSocials()
      .then((response: any) => {
        var data = response.data;
        // setSelected(data.success);
        // console.log(data);

        var soc: any = [];
        data.success.forEach((element: any) => {
          var d: any = {};
          d['username'] = element.username;
          d['type'] = element.type;
          if (functions.isValidUrl(element.username)) {
            soc.push(d);
          }
        });
        set_socials(soc);

      })
      .catch((error: any) => {
        console.log(error)
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  const Checkss = (itesm: any) => {
    const item: any = itesm.item;
    let obj = selected.find((o: any) => o.type === itesm.item.title);
    // console.log("objobjobj", obj, selected, itesm)
    return (
      <div className="as_body-leftitem" style={{ marginBottom: 20 }}>
        <div className="as_body-leftitemcheck">
          <div
            className={obj ? "ascheckbox" : "ascheckboxnot"}
          // onClick={() => handleSelectedSocialMedia(item.title)}
          >
            {obj && <img src={markIcon} alt="" />}
          </div>
          <img src={item.icon} alt="" />
          <label>{item.title}</label>
        </div>
        <div
          className="ascheckboxmarkam"
          onClick={() => handleSelectedSocialMedia(item.title)}
        >
          {obj ? (
            <img src={cancelIcon} alt="" />
          ) : (
            <img src={arrowIcon} alt="" />
          )}
        </div>
      </div>
    );
  };

  const updateSelected = (e: any, label: string) => {
    var soc = [...socials];
    const i = soc.findIndex(e => e.type.toLowerCase() === label.toLowerCase());

    if (i > -1) {
      if (e.target.value.trim() === '') {
        soc.splice(i, 1)
      } else {
        soc[i].username = e.target.value;
      }
    } else {
      var d: any = {};
      d['type'] = label.toLowerCase();
      d['username'] = e.target.value;

      soc.push(d)

    }

    set_socials(soc);

  }

  const options = [
    { icon: <img src={require('assets/images/facebook.png')} alt="" />, label: 'Facebook' },
    // { icon: <img src={require('assets/images/google.png')} alt="" />, label: 'Google Analytics' },
    { icon: <img src={require('assets/images/instagram.png')} alt="" />, label: 'Instagram' },
    { icon: <img src={require('assets/images/pinterest.png')} alt="" />, label: 'Pinterest' },
    { icon: <img src={require('assets/images/tiktok.png')} alt="" />, label: 'TikTok' },
    { icon: <img src={require('assets/images/snapchat.png')} alt="" />, label: 'Snapchat' },
    { icon: <img src={require('assets/images/twitter.png')} alt="" />, label: 'Twitter' },
    { icon: <img src={require('assets/images/youtube.png')} alt="" />, label: 'YouTube' },
  ]


  const responseFacebook = (response: any) => {
    setFacebookSelected(true);
    console.log('responseFacebookresponseFacebook', response);
  }

  const facebookIcons = () => (
    <div className="flex facebook-icons">
      <img src={require(`assets/images/${facebookSelected ? 'checked.png' : 'check-empty.png'}`)} alt="" className="check" />
      <img src={require('assets/images/facebook.png')} alt="" />
    </div>
  )

  return (
    <AuthContainer {...prop}>
      <div className="auth-form">
        {options.map((option, index) => {
          const foundItem = socials.find((value: any) => value.type === option.label.toLowerCase());
          const valueToPass = foundItem ? foundItem.username : '';
          return (
            <SocialInput
              key={index}
              label={option.label}
              value={valueToPass}
              icon={option.icon}
              onChange={(e: any) => updateSelected(e, option.label)}
            />
          )
        })}

        <div className="actions mb-4">
          <div className="flex gap w-100">
            <Button variant="secondary" label="Previous" onClick={() => navigate("/register")} />
            <Button variant="primary" label="Next" onClick={() => sub()} />
          </div>
        </div>
        <Button variant="plain" label="Already have an account? Login" onClick={() => navigate("/login")} />
      </div>
    </AuthContainer>
  )
}
