import React from 'react'
import { Select } from 'antd';

export default function MultiSelectField({ options, value, onChange, label, required }: any) {
  return (
    <div className="multi-selectable form-item">
      {label && <label htmlFor="">
        {label || ''}
        {required && <span className='required'>*</span>}
      </label>}
      {options && options.length > 0 &&
        <Select
          mode="multiple"
          showSearch
          style={{ width: 200 }}
          placeholder="Search to Select"
          onChange={onChange}
          value={value}
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={options}
        />
      }
    </div>
  )
}
