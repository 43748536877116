import { Checkbox } from 'antd';
import AuthContainer from 'components/layout/AuthContainer'
import { Button, Field } from 'components/forms';
import { useNavigate, Link } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import config from 'app/global/config';
interface dataProps {
  edit?: string;
}

export default function ThankYou() {
  const navigate = useNavigate();

  const { user } = useSelector(
    (state: any) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const prop = {
    title: 'Thank You For Your Application',
    subTitle: `Our team will forward a mail to ${user?.user?.email} within 24-48 hours after reviewing your submission.`,
  }

  return (
    <AuthContainer {...prop}>
      <div className="welcome-pack auth-form">
        <div className="actions mb-4">
          <a href={config.landing}>
            <Button
              variant="secondary" label="BACK TO HOMEPAGE" />
          </a>
        </div>
        <Button onClick={() => navigate("/register", {
          state: {
            "edit": "true"
          }
        })} variant="plain" label="Not your email?" />
      </div>
    </AuthContainer>
  )
}
