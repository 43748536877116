import React, { useState } from "react";
import "./styles.scss";

import eyeIcon from "../../../../assets/Svg/eyeicon.svg";
import marklightIcon from "../../../../assets/Svg/marklight.svg";
import PasswordStrengthBar from "react-password-strength-bar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../auth/redux/AuthCRUD";
import { toast } from "react-toastify";
import * as auth from "../../auth/redux/AuthRedux";
import * as functions from "../../../global/functions";

function PasswordManagement() {
  const dispatch = useDispatch();

  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  let mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordTypeb, setPasswordTypeb] = useState("password");
  const [passwordInputb, setPasswordInputb] = useState("");
  const [passwordTypec, setPasswordTypec] = useState("password");
  const [passwordInputc, setPasswordInputc] = useState("");
  const [password_state, set_p_state] = useState<number>(0);

  function chan() {
    if (passwordInputb !== passwordInputc) {
      toast.error("Ensure that both passwords are the same");
      return;
    }

    if (password_state !== 4) {
      toast.error("Enter a stronger password!");
      return;
    }

    var body: any = {};
    body["password"] = passwordInputb;
    body["old"] = passwordInput;

    changePassword(body)
      .then((response: any) => {
        var data = response.data;
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Successfully changed your password!");
        }
      })
      .catch((error: any) => {
        var message = error.response.data
          ? error.response.data.message
          : "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  const handlePasswordChange = (e: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setPasswordInput(e.target.value);
  };

  const handlePasswordChangeb = (e: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setPasswordInputb(e.target.value.trim());

    if (strongPassword.test(e.target.value.trim())) {
      set_p_state(4);
    } else if (mediumPassword.test(e.target.value.trim())) {
      set_p_state(3);
    } else if (e.target.value.trim().length >= 8) {
      set_p_state(1);
    } else {
      set_p_state(0);
    }
  };

  const handlePasswordChangec = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPasswordInputc(e.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePasswordb = () => {
    if (passwordTypeb === "password") {
      setPasswordTypeb("text");
      return;
    }
    setPasswordTypeb("password");
  };
  const togglePasswordc = () => {
    if (passwordTypec === "password") {
      setPasswordTypec("text");
      return;
    }
    setPasswordTypec("password");
  };

  return (
    <div className="pmp_body">
      <p className="pmp_body-title">Change password</p>
      <div className="pmp_bodybottom">
        <div className="pmp_bodybottomitem">
          <label>
            Old password<span className="asteriskspan">*</span>
          </label>
          <div className="pmpbbiinput">
            <input
              type={passwordType}
              onChange={handlePasswordChange}
              name="password"
              value={passwordInput}
              placeholder="password"
            />
            <img onClick={togglePassword} src={eyeIcon} alt="" />
          </div>
        </div>
        <div className="pmp_bodybottomitem">
          <label>New password<span className="asteriskspan">*</span></label>
          <div className="pmpbbiinput">
            <input
              type={passwordTypeb}
              onChange={handlePasswordChangeb}
              value={passwordInputb}
              name="password"
              placeholder="password"
            />
            <img onClick={togglePasswordb} src={eyeIcon} alt="" />
          </div>
        </div>

        <div className="pmp_bodybottomitem">
          <div className="pmpbbipstrength">
            <div
              className="pstrength a"
              style={{
                backgroundColor: password_state > 0 ? "green" : undefined,
              }}
            ></div>
            <div
              className="pstrength b"
              style={{
                backgroundColor: password_state >= 3 ? "green" : undefined,
              }}
            ></div>
            <div
              className="pstrength c"
              style={{
                backgroundColor: password_state === 4 ? "green" : undefined,
              }}
            ></div>
          </div>
        </div>

        <div className="pmp_bodybottomitem">
          <div className="pmpbbipasschar">
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: passwordInputb.length >= 8 ? "green" : undefined,
                }}
              >
                Eight (8) or more alphabetical characters
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isUpperCase(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One (1) or more uppercase character(s)
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isNumeric(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One(1) or more numerical character(s)
              </label>
            </div>
            <div className="passchar">
              <img src={marklightIcon} alt="" />
              <label
                style={{
                  color: functions.isSpecial(passwordInputb)
                    ? "green"
                    : undefined,
                }}
              >
                One(1) or more special character(s)
              </label>
            </div>
          </div>
        </div>
        <div className="pmp_bodybottomitem">
          <label>Confirm password<span className="asteriskspan">*</span></label>
          <div className="pmpbbiinput">
            <input
              type={passwordTypec}
              onChange={handlePasswordChangec}
              value={passwordInputc}
              name="password"
              placeholder="password"
            />
            <img onClick={togglePasswordc} src={eyeIcon} alt="" />
          </div>
        </div>

        <div className="pmp_bodybottomitem" style={{ width: "55%" }}>
          <button
            className="editprofilebtn"
            // loading = {true}
            onClick={() => chan()}
          >
            CHANGE PASSWORD
          </button>
        </div>
      </div>
    </div>
  );
}

export default PasswordManagement;
