import { FC } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../modules/auth";
import { RootState } from "../../setup";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Register from "../modules/auth/components/auth/Register";
import Channels from "../modules/auth/components/auth/Channels";
import About from "../modules/auth/components/auth/About";
import WelcomePack from "../modules/auth/components/auth/WelcomePack";
import ThankYou from "../modules/auth/components/auth/ThankYou";
import Pending from "../modules/auth/components/auth/Pending";
import Forgot from "../modules/auth/components/auth/forgot";
import Reset from "../modules/auth/components/auth/reset-password";
import Email from "../modules/auth/components/auth/check-email";
import {
  ProductDetails,
  ProductsCatalogue,
  BrandDetails,
  Track
} from "app/modules/products";
import CommnunityHub from "app/modules/communityhub/pages/main";
import Profile from "app/modules/profile/pages/main";
import ContentHub from "app/modules/contenthub/pages/main";
import ContentDetails from "app/modules/contenthub/pages/ContentDetails";
import AnalyticPage from "app/modules/analytics";
import { Payouts } from 'app/modules/payouts/pages'

const App_Routes: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  var isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user?.user?.verification_status === "approved",
    shallowEqual
  );

  return (
    <Routes>
      {!isAuthorized ? (
        <>
          <Route path="/login" element={<AuthPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/channels" element={<Channels />} />
          <Route path="/about-you" element={<About />} />
          <Route path="/welcome-pack" element={<WelcomePack />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/check-email" element={<Email />} />
          <Route path="/pending" element={<Pending />} />
          <Route path="/track/:id" element={<Track />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      ) : (
        // console.log('dcdw')
        <Route path="/login" element={<Navigate to="/" />} />
      )}
      <Route path="/logout" element={<Logout />} />
      <Route path="/error" element={<Logout />} />

      {!isAuthorized ? (
        <Route path="/" element={<Navigate to="/login" />} />
      ) : (
        <>
          <Route path="/" element={<Navigate to="/products" />} />
          {/* <Route path='/' element={<PrivateRoutes />} /> */}
          <Route path="/products" element={<ProductsCatalogue />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/track/:id" element={<Track />} />
          <Route path="/brand/:id" element={<BrandDetails />} />
          <Route path="/community" element={<CommnunityHub />} />
          <Route path="/community/virtual-sessions" element={<CommnunityHub />} />
          <Route path="/community/events" element={<CommnunityHub />} />
          <Route path="/community/help" element={<CommnunityHub />} />
          <Route path="/content" element={<ContentHub />} />
          <Route path="/content/post/:id" element={<ContentDetails />} />
          <Route path="/profile" element={<Profile />} />{" "}
          <Route path="/analytics" element={<AnalyticPage />} />
          <Route path='/payouts' element={<Payouts />} />
          <Route path="*" element={<Navigate to="/products" />} />
        </>
      )}
    </Routes>
  );
};

export { App_Routes };
