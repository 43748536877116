import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'sum_analytics' || type === 'sum_analytics_compare') {
    return axios.get(`${URL}api/analytics/sum?${queryParams}`);
  } else if (type === 'content_performance') {
    return axios.get(`${URL}api/analytics/content_performance?${queryParams}`);
  } else if (type === 'channel_performance') {
    return axios.get(`${URL}api/analytics/channel_performance?${queryParams}`);
  } else if (type === 'request_payment') {
    return axios.get(`${URL}api/transaction/request_payment`);
  } else if (type === 'transactions') {
    return axios.get(`${URL}api/transaction/all?${queryParams}`);
  } 
}
