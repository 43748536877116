import { useNavigate, useParams } from "react-router-dom";
import SidebarLayout from "components/layout/SidebarLayout";
import { ContentHub as CHub } from "components/Icons";
import { Button, Field } from "components/forms";
import { getSingleContent, fetchAllData } from "../_redux/content/Actions";
import "./styles.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Skeleton } from "antd";
// import Modal from 'react-modal';
import { Modal } from 'antd';
import Vimeo from '@u-wave/react-vimeo';
import btnplay from "../../../../assets/Svg/btnplay.svg";
import { ProgressiveImage } from "components/shared";
import ReactPlayer from 'react-player/vimeo'
import ReactMarkdown from 'react-markdown';

//sorting, simpliy promo code, add video options, creator info, date addon

export default function ContentDetails() {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();
  const [play, set_play] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [video, set_video] = useState<any>(null);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const limitStr = (str: string, length: number) => {
    const spl = str.split("");
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join("") + "...";
    }
    return newStr;
  };

  
  const params = useParams();
  const { id } = params;
  const [crumbs, setCrumbs] = useState([
    { label: 'getting you started', to: '/content' },
    { label: '', to: `/content` },
  ])

  const { contentDetails, contentList } = useSelector(
    (state: any) => ({
      contentDetails: state.content.contentDetails,
      contentList: state.content.contents,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(getSingleContent(id));
    dispatch(fetchAllData('contents', ''))
 
  }, [])

  useEffect(() => {
    if (contentDetails && contentDetails._id) {
      setCrumbs([
        { label: 'getting you started', to: '/content' },
        { label: contentDetails.title || 'n/A', to: `/content/post/${contentDetails._id}` },
      ])

    }
  }, [contentDetails])


  function removeMarkdownAndHTML(text: string) {
    // Remove HTML tags
    const withoutHTML = text.replace(/<[^>]+>/g, '');
  
  
    return withoutHTML;
  }



  return (
    <SidebarLayout
      headerTitle="Content hub"
      headerIcon={CHub}
      crumbs={crumbs}
    >
      <div className="content-hub-container">
        {contentDetails && contentDetails._id ? <>

          <div className="header-section">
            <h2 className="section-title">{contentDetails.title}</h2>

          </div>
          <div className="banner">
            {
              !play ?
              <img src={contentDetails.thumbnail} alt="" className="large" />
              :
              // <div 
              // className="large" 
              //   style = {{
              //   }}>
              //     <Vimeo
              //       video={contentDetails.video_url}
              //       width={940} 
              //       height={580}
              //       autoplay = {true}
              //     />
              // </div>
              <div 
              
              // className="fullScreenPlayer"
              style={{ width: 'auto', height: 'auto', minHeight: '340px', position: 'relative' }}
              >
                <ReactPlayer
                  url={video}
                  playing={true}
                  // width="100%"
                  // height="100%"
                  controls={true}
                  autoplay={true}
                />
              </div>
            }

            {
              contentDetails.video_url && !play &&
              <img src={require('assets/images/play-btn.png')} onClick = {() => {
                set_play(true);
                set_video(contentDetails.video_url)
              }} alt="" className="play" />
            }
          </div>
          {/* <div className="description" dangerouslySetInnerHTML={{ __html: contentDetails.body }} /> */}
          
          <div className="prose lg:prose-xl">
            <ReactMarkdown className="prose">{removeMarkdownAndHTML(contentDetails.body)}</ReactMarkdown>
          </div>
          
          
          <div className="header-section" style = {{marginTop: 50}}>
            <h2 className="section-title">Want to read more?</h2>
          </div>
          <div className="contenthublist">
            {contentList && contentList.length > 0
              ? contentList.filter((opt: any) => opt._id !== id).map((el: any, index: number) => (
                <div className="contenthublistitem" key={index}>
                  <div className="cthlitop">
                    <ProgressiveImage
                      imgSrc={el.thumbnail}
                      previewSrc={""}
                      width="100%"
                      height="75%"
                      style={{ objectFit: 'cover', objectPosition: 'center', height: '230px' }}
                    />
                    {/* <div className="overlay-feature"></div>
                    {el.video_url &&
                    <img src={btnplay} onClick = {() => {
                      set_video(el.video_url)
                      setIsOpen(true)
                    }} alt="" className="cthlitopimg2" />} */}
                  </div>
                  <h3 className="cthlititle">{el.title}</h3>
                  <ReactMarkdown className="cthlisubtitle">{limitStr(el.body, 80)}</ReactMarkdown>
                  <a className="cthlibtn" href={`/content/post/${el._id}`}>
                    WATCH OR READ
                  </a>  
                </div>
              ))
              : ''
            }
          </div>
        
        </>
          : <Skeleton paragraph={{ rows: 10 }} />
        }
      </div>



      {/* <Modal
        centered
        open={modalIsOpen}
        onOk={() => {
          setIsOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={null}
        title={null}
      >
        {video &&
        <div className="video" 
        style = {{
          height: 400,
          width: 400,
          // marginRight: 20
        }}>
          <Vimeo
            video={video}
            width={520} 
            height={400}
            autoplay = {true}
          />
        </div>}
      </Modal> */}
    </SidebarLayout>
  );
}
