import { useState, useCallback, useEffect } from "react";
import cancelIcon from "../../../../assets/Svg/canceicon.svg";
import markIcon from "../../../../assets/Svg/markicon.svg";
import arrowIcon from "../../../../assets/Svg/arrowicon.svg";
import addIcon from "../../../../assets/Svg/addicon.svg";
import * as functions from "../../../global/functions";
import { useSearchParams } from "react-router-dom";
import { getSocials, addSocials } from "../../auth/redux/AuthCRUD";
import { toast } from "react-toastify";
import MoreIcon from "assets/Svg/more-info.svg";
import { Popover, Form, Select } from "antd";
import {
  Field,
  Button,
  Selectable,
  MultiSelectField,
  SocialInput
} from "components/forms";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { editUserData, getUserByToken } from "../../auth/redux/AuthCRUD";
import * as auth_actions from "../../auth/redux/AuthRedux";
import { countryOptions } from './data'
const { Option } = Select;


const contentOptions = [
  {
    value: "Beauty",
    label: "Beauty",
  },
  {
    value: "Lifestyle",
    label: "Lifestyle",
  },
  {
    value: "Travel",
    label: "Travel",
  },
  {
    value: "Fitness",
    label: "Fitness",
  },
  {
    value: "Comedy",
    label: "Comedy",
  },
  {
    value: "Food",
    label: "Food",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const audienceOptions = [
  {
    value: "UK",
    label: "UK",
  },
  {
    value: "USA",
    label: "USA",
  }
];

const productOptions = [
  {
    value: "Beauty",
    label: "Beauty",
  },
  {
    value: "Fashion",
    label: "Fashion",
  },
  {
    value: "Lifestyle",
    label: "Lifestyle",
  },
  {
    value: "Skincare",
    label: "Skincare",
  },

  {
    value: "Fitness",
    label: "Fitness",
  },
  {
    value: "Technology",
    label: "Technology",
  },
  {
    value: "Entertainment",
    label: "Entertainment",
  },
  {
    value: "Vegan Products",
    label: "Vegan Products",
  },
  {
    value: "Makeup",
    label: "Makeup",
  },
  {
    value: "Health",
    label: "Health",
  },
  {
    value: "Wellness",
    label: "Wellness",
  },
  {
    value: "Sustainable",
    label: "Sustainable",
  },
];

function AccountSetting() {
  // const REDIRECT_URI =
  // 'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
  const REDIRECT_URI = "https://dev-creator.amyris.tech/profile";
  const dispatch = useDispatch();

  const { user } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user,
    }),
    shallowEqual
  );

  function isJson(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}


  const [selected, setSelected] = useState<any>([]);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  var [socials, set_socials] = useState<any>([]);


  const [value, setValue] = useState(user?.affiliate || "yes");
  const [programs, set_programs] = useState(user?.programs || []);
  const [products, set_products] = useState(user?.products_to_promote ? JSON.parse(user?.products_to_promote || '[]') || [] : []);
  const [commission, set_com] = useState(user?.average_commission || "");
  const [audience, set_audience] = useState(isJson(user?.audience) ? JSON.parse(user?.audience) : '');
  // const [audience, set_audience] = useState(user?.audience || "");
  const [content_type, set_content_type] = useState(user?.content_type ? JSON.parse(user?.content_type || '[]') || [] : []);
  const [loading, setLoading] = useState(false)

  const [consent, setConsent] = useState(false);
  const [currency, set_currency] = useState(user?.currency || 'usd');

  const [pumpFake, setPumpfake] = useState(1);
  const [fielders, setFielders] = useState(user?.programs.length > 0 ? user?.programs : [' ']);
  const [fieldersPromote, setFieldersPromote] = useState(
    user?.products_to_promote || [1]
  );

  const handleSelectedSocialMedia = (e: any, id: string) => {
    if (selected.includes(id)) {
      setSelected((prevSelected: any) =>
        prevSelected.filter((s: any) => s !== id)
      );
    } else {
      setSelected((prevSelected: any) => [...prevSelected, id]);
    }
    e.stopPropagation();
  };

  const MoreInfo = () => {
    return (
      <Popover content={commissionInfo} trigger="click">
        <div className="more">
          <img src={MoreIcon} alt="*" />
        </div>
      </Popover>
    );
  };

  const commissionInfo = (
    <div>
      <p>
        To calculate the payable commission, multiply your sales by the sales
        commission rate. A 10 percent commission rate on a $10,000 product deal
        would pay $1,000 in commission.
      </p>
    </div>
  );

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        onChange={(e: any) => set_currency(e)}
        defaultValue={currency === "usd" ? "$" : "£"}
        style={{ width: 70 }}
      >
        <Option value="$">$</Option>
        <Option value="£">£</Option>
        {/* <Option value="naira">₦</Option> */}
      </Select>
    </Form.Item>
  );

  useEffect(() => {
    getSocia();
  }, []);

  function getSocia() {
    getSocials()
      .then((response: any) => {
        var data = response.data;
        // setSelected(data.success);

        var soc: any = [];
        data.success.forEach((element: any) => {
          var d: any = {};
          d['username'] = element.username;
          d['type'] = element.type;
          if (functions.isValidUrl(element.username)) {
            soc.push(d);
          }
        });
        set_socials(soc);
        // console.log(data);
      })
      .catch((error: any) => {
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  const Checkss = (itesm: any) => {
    const item: any = itesm.item;
    let obj = selected.find((o: any) => o.type === itesm.item.title);
    // console.log("objobjobj", obj, selected, itesm)
    return (
      <div className="as_body-leftitem" style={{ marginBottom: 20 }}>
        <div className="as_body-leftitemcheck">
          <div
            className={obj ? "ascheckbox" : "ascheckboxnot"}
          // onClick={() => handleSelectedSocialMedia(item.title)}
          >
            {obj && <img src={markIcon} alt="" />}
          </div>
          <img src={item.icon} alt="" />
          <label>{item.title}</label>
        </div>
        <div
          className="ascheckboxmarkam"
          onClick={(e) => handleSelectedSocialMedia(e, item.title)}
        >
          {obj ? (
            <img src={cancelIcon} alt="" />
          ) : (
            <img src={arrowIcon} alt="" />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    renderFields();
  }, [fielders, programs]);


  const addNewField = () => {
    const cFields = fielders;
    cFields.push(fielders.length + 1);
    setPumpfake(pumpFake + 1);
    setFielders(cFields);
  };

  const removeField = (index: any) => {
    if (fielders && fielders.length > 1) {
      const cFields = [...fielders];
      const cPrograms = [...programs];
      cFields.splice(index, 1);
      cPrograms.splice(index, 1);
      setPumpfake(pumpFake + 1);
      setFielders(cFields)
    }
  };

  const renderFields = () => {
    return fielders.map((_: any, index: number) => (
      <div key={index} className="removable-fd">
        <input
          type="text"
          required
          className="plusCount"
          placeholder={"e.g. JVL"}
          defaultValue={programs[index]}
          style={{ paddingLeft: 15 }}
          onChange={(e: any) => set_pr(e.target.value.trim(), index)}

        />
        <button
          className="close-btn"
          onClick={() => removeField(index)}
        >
          <img src={require("assets/images/close.png")} alt="" />
        </button>
        {/* } */}
      </div>
    ));
  };

  function set_pr(value: string, i: number) {
    var p: any = programs;
    p[i] = value;
    set_programs(p);
  }

  function rm_pr(i: number) {
    var array: any = programs;
    array.splice(i, 1);
    // const index = array.indexOf(i);
    // if (index > -1) {
    // }
    set_programs(array);
  }
  function set_prod(value: string, i: number) {
    if (!value || value === "") {
      return;
    }
    // console.log(value);
    var p: any = products;
    p[i] = value;
    // set_products(p);
  }

  function rm_prod(i: number) {
    var array: any = products;
    const index = array.indexOf(i);
    if (index > -1) {
      array.splice(index, 1);
    }
    // set_products(array);
  }

  const updateSelected = (e: any, label: string) => {
    var soc = [...socials];
    const i = soc.findIndex(e => e.type.toLowerCase() === label.toLowerCase());

    if (i > -1) {
      if (e.target.value.trim() === '') {
        soc.splice(i, 1)
      } else {
        soc[i].username = e.target.value;
      }
    } else {
      var d: any = {};
      d['type'] = label.toLowerCase();
      d['username'] = e.target.value;

      soc.push(d)

    }

    set_socials(soc);

  }

  const options = [
    { icon: <img src={require('assets/images/facebook.png')} alt="" />, label: 'Facebook' },
    // { icon: <img src={require('assets/images/google.png')} alt="" />, label: 'Google Analytics' },
    { icon: <img src={require('assets/images/instagram.png')} alt="" />, label: 'Instagram' },
    { icon: <img src={require('assets/images/pinterest.png')} alt="" />, label: 'Pinterest' },
    { icon: <img src={require('assets/images/tiktok.png')} alt="" />, label: 'TikTok' },
    { icon: <img src={require('assets/images/snapchat.png')} alt="" />, label: 'Snapchat' },
    { icon: <img src={require('assets/images/twitter.png')} alt="" />, label: 'Twitter' },
    { icon: <img src={require('assets/images/youtube.png')} alt="" />, label: 'YouTube' },
  ]

  async function sub() {
    var filtered = programs.filter(function (el: any) {
      return el != null && el !== "";
    });
    if (value === "yes") {
      if (filtered.length === 0) {
        toast.error("Kindly include the affiliate platforms you're registered to");
        return;
      }

      if (commission === "") {
        toast.error("Kindly input the average commission rate per month");
        return;
      }
    }

    if (content_type === "") {
      toast.error("Kindly input the main content");
      return;
    }

    if (products === "") {
      // console.log(products)
      toast.error("Kindly input preferred products categories");
      return;
    }


    socials.map((ele: any) => {
      var b: any = {};
      if (!functions.isValidUrl(ele.username)) {
        toast.error(`Input a valid URL to your ${ele.type} profile`); 
      } else {
        b["type"] = ele.type;
        b["username"] = ele.username;
        if (ele.username && ele.username !== '') {
          addSocials(b)
        }
      }
    })


    var body: any = {};
    body["affiliate"] = value;
    body["programs"] = programs;
    body["average_commission"] = commission;
    body["content_type"] = JSON.stringify(content_type);
    body["products_to_promote"] = JSON.stringify(products);
    body["currency"] = currency === "$" ? 'usd' : "gbp" //currency;
    body["audience"] = JSON.stringify(audience);

    // console.log(body);

    // dispatch(auth_actions.actions.set_more(body));

    editUserData(body)
      .then(async (response: any) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
          toast.error(data.error);
        } else {
          const { data: user } = await getUserByToken()
          dispatch(auth_actions.actions.fulfillUser(user));
          dispatch(auth_actions.actions.fulfillToken(data.user_token));
          toast.success('Success, Info updated!')
        }
      })
      .catch((error: any) => {
        var message = error.response.data
          ? error.response.data.message
          : "Network error occured!";

        setLoading(false);
        toast.error(message ? message : "Network error occured!");
      });
  }

  return (
    <div>
      <div className="as_body">
        <div className="as_body-left auth-form" style={{ padding: 0 }}>
          <p>Connect your social media channels</p>
          <div className="as_body-leftbody">
            {options.map((option, index) => {
              const foundItem = socials.find((value: any) => value.type === option.label.toLowerCase());
              const valueToPass = foundItem ? foundItem.username : '';
              return (
                <SocialInput
                  key={index}
                  label={option.label}
                  value={valueToPass}
                  icon={option.icon}
                  onChange={(e: any) => updateSelected(e, option.label)}
                />
              )
            })}
          </div>
        </div>
        <div className="as_body-right">
          <p>Account preference</p>
          <div className="as_body-rightbottom  auth-form">
            <MultiSelectField
              value={audience}
              required
              onChange={(e: any) => {
                set_audience(e);
              }}
              options={countryOptions}
              label="Audience main locations"
            />

            <div className="as_body-rightitem form-item">
              <div className="mb-6">

                <div className="form-item">
                  <label htmlFor="">
                    Other Affiliate Platforms You Are Registered To
                    <span className="required">*</span>
                  </label>
                  {renderFields()}
                </div>

                <button onClick={addNewField} className="abtbtn">
                  <img src={addIcon} alt="" />
                </button>
              </div>

            </div>


            <Field
              type="text"
              WithTopRight={MoreInfo}
              required
              prefixSelector={prefixSelector}
              onChange={(e: any) => set_com(e.target.value.trim())}
              value={value === "no" ? "0" : commission}
              label="Average Commission Rate Per Month"
              placeholder="e.g. $100"
            />



            <MultiSelectField
              required
              value={content_type}
              onChange={(e: any) => set_content_type(e)}
              options={contentOptions}
              label="Main content types?"
            />

            <MultiSelectField
              value={products}
              required
              onChange={(e: any) => {
                set_products(e);
              }}
              options={productOptions}
              label="Preferred product categories?"
            />



          </div>

        </div>



      </div>
      <div style={{ marginRight: 40, marginLeft: 40, marginBottom: 20, paddingBottom: 30 }}>
        <Button
          loading={loading}
          variant="primary"
          label="Update"
          onClick={() => sub()}
        />
      </div>
    </div>

  );
}

export default AccountSetting;
