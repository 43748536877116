import * as requestFromServer from "./Crud";
import { AnalyticsSlice, callTypes } from "./Slice";
import { toast } from 'react-toastify';

const { actions } = AnalyticsSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  if (type !== 'request_payment') {
    dispatch(actions.startCall({ callType: callTypes.list }));
  }
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'sum_analytics') {
        dispatch(actions.sumAnalytics(res.success));
        return;
      } else if (type === 'content_performance') {
        dispatch(actions.contentPerformance(res.success));
        return;
      } else if (type === 'channel_performance') {
        dispatch(actions.channelPerformance(res.success));
        return;
      }  else if (type === 'transactions') {
        dispatch(actions.transactionsList(res.success));
        return;
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};



export const reset = () => dispatch => {
  dispatch(actions.reset());
};

