import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Checkbox, Alert, Space } from 'antd';
import AuthContainer from 'components/layout/AuthContainer'
import { Field, Button } from 'components/forms';
import { Google, Apple, Facebook } from 'components/Icons';
import * as auth from '../../redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { reset_password} from '../../redux/AuthCRUD'
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import config from 'app/global/config';
import * as functions from 'app/global/functions';
import { toast } from 'react-toastify';

export default function Forgot() {



  const prop = {
    intro: 'Empower you the creator,  to monetise your content,  while effectively inspiring consumers to  make good, without compromise.',
    title: 'Forgot your password',
    subTitle: 'To reset your password, kindly enter your email-address below and follow the instructions sent to it'
  }
  const dispatch = useDispatch()

  let { code } = useParams();

  useEffect(() => {
    dispatch(auth.actions.logout());
  }, [])

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [email, set_email] = useState('')
  const navigate = useNavigate();



  async function subm() {

    if (!email) {
      toast.error('Enter all inputs!');
      return;
    }

    // console.log(password, email); return;

    if (!functions.validateEmail(email)) {
      toast.error('Enter a valid email address!');
      return;
    }

    setLoading(true);
    setStatus('');
    reset_password({ email: email, type: 'creator' })
      .then(async(response) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
            setStatus(data.error);
        } else {
            navigate("/check-email");
        }
      })
      .catch((error) => {
        var message = error.response.data ? error.response.data.message : 'Network error occured!';

        setLoading(false);
        setStatus(message ? message : 'Network error occured!');
      });
  }



  return (
    <AuthContainer {...prop}>

      <form onSubmit={(e: any) => {
        e.PreventDefault();
        subm();
      }} className="auth-form">
        {status &&
          <Alert
            description={`Error! ${status}`}
            type="error"
            closable
            showIcon
            onClose={() => setStatus('')}
          />}<br></br>
        <Field type="email" required={true}
          onChange={(e: any) => set_email(e.target.value.trim())}
          label="Email address" placeholder="e.g. johndoe@email.com" />
        <div className="actions">
          <Button
            loading={loading}
            onClick={() => subm()} variant="primary" label="Submit" />
          <span className="or">OR</span>
          <Space direction="vertical" size="middle" className="w-100">
            <Button variant="plain" label="Back to login" onClick={() => navigate("/login")} />
          </Space>
        </div>
      </form>
    </AuthContainer>
  )
}
