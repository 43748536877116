import {  useEffect } from 'react'
import { useParams } from 'react-router-dom';
import {  useDispatch } from "react-redux";
import { fetchAllData, addClick } from '../_redux/products/Actions';
import { toast } from 'react-toastify';


export default function Track() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const code_id: any = params?.id;

  function setTrackingCookie(affiliateId: any) {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // set cookie expiration to 30 days
    const expires = "expires=" + date.toUTCString();
    document.cookie = "affiliate=" + affiliateId + ";" + expires + ";path=/";
  }

  

  useEffect(() => {
    // console.log(code_id);

    if (code_id) {
      // setTrackingCookie(code_id);
      // return;
 
      dispatch(fetchAllData('ip')).then((resp: any) => {
        // console.log(resp)
        var b:any = {};
        b['code'] = code_id;
        b['client_data'] = resp;
        b['source'] = document.referrer.split('/')[2];
        b['source_url'] = document.referrer;
        // var referer = 
        // dispatch(addClick(b)); return;
        dispatch(addClick(b)).then((respon: any) => {
          // console.log(respon);
          if (respon.success) {
            window.location.href = respon.success;

            
            // setCookie('referralddd', getParameterByName('aff', code_id), 1);
            // window.location = respon.success;

          // var expires = (function(days){
          //             date = new Date();
          //             date.setTime(date.getTime() + (days * 24 * 60 * 60 * 
          //           1000));
          //         return date.toUTCString();
          //   })(5);
          // var d = new Date();
          // d.setTime(d.getTime() + (1*24*60*60*1000));
          // var expires = "expires="+ d.toUTCString();
          // document.cookie ="mytestcookie=testcookie; expires="+expires+"; path=/";
          // window.open(respon.success)
         
          } else {
            toast.error('Error occured! Link corrupted');
          }
        })
      });
    } 
  }, []);

  function getParameterByName(name: any, url: any) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function setCookie(cname: any, cvalue: any, exdays: any) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

  
  return (
    <h1>Redirecting... Please wait.</h1>
  )
}
