import { useState, useEffect, useRef } from "react";
import { message } from "antd";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SidebarLayout } from "components/layout";
import { ProductCatalogue } from "components/Icons";
import Slider from "react-slick";
import * as actions from "../_redux/products/Actions";
import { toast } from "react-toastify";
import { Button } from "components/forms";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import modal1 from "../../../../assets/images/advicor1.png";
import modal2 from "../../../../assets/images/advicor2.png";
import crossicon from "../../../../assets/Svg/Cross.svg";
import modalbackground from "../../../../assets/images/modalbackground.png";
import docImage from "../../../../assets/images/doc_image.png";
import * as community_actions from "../../communityhub/_redux/community/Actions";
import { getUserByToken } from "../../auth/redux/AuthCRUD";
import * as auth_actions from "../../auth/redux/AuthRedux";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";


import "./styles.scss";

const NextArrow = (props: any) => {
  const { className, style, onClick } = props; 
  return (
    <div className={className} onClick={onClick}>
      <img src={require("assets/images/next-icon.png")} alt="" />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={require("assets/images/prev-icon.png")} alt="" />
    </div>
  );
};
const settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function ProductsCatalogue() {
  const dispatch = useDispatch<any>();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const params = useParams();
 
  const { listLoading, brands, user, bookings, cats, actionsLoading } = useSelector(
    (state: any) => ({
      actionsLoading: state.dashboard.actionsLoading,
      user: state.auth.user.user,
      listLoading: state.dashboard.listLoading,
      brands: state.products.brands,
      bookings: state.community.bookings,
      cats: state.products.categories,
    }),
    shallowEqual
  );
  
  // const [searchParams] = useSearchParams();
  const myParam = useLocation()?.search;
  const event= new URLSearchParams(myParam)?.get("event");
  

  const [modal, setModal] = useState(false);
  const [load, setLoading] = useState(false);
  const [modal_changed, set_m_changed] = useState(false);
  const [signingData, setSigningData] = useState(null);
 

  useEffect(() => {
    if (event) {
        setModal(false);
        var b:any = {};
        b['event'] = event;
        dispatch(actions.addData(b, 'event')).then(async (respon: any) => {
          if (respon) {
            const { data: user } = await getUserByToken()
            dispatch(auth_actions.actions.fulfillUser(user));
            toast.success('Success, Info updated!')
          } else {
            toast.error('Error occured!');
          }
        })
    } 
  }, [event]);


  useEffect(() => {
    dispatch(actions.fetchAllData("brands"));
    dispatch(actions.fetchAllData("categories"));
    dispatch(community_actions.fetchAllData("bookings"));
  }, []);

  useEffect(() => {
    if (!modal_changed) {
      // if (user && !user?.doc_signed) {
      //   setModal(true);
      //   return;
      // } else {
      //   setModal(false);
      // }

      // if (bookings &&  bookings.length === 0) {
      //   setModal(true);
      //   return;
      // } else {
      //   setModal(false);
      // }


      set_m_changed(true);
    }

  }, [bookings, modal_changed, user]);


  function getLink() {
    setLoading(true);
    dispatch(actions.fetchAllData("signing_link")).then((da: any) => {
      setLoading(false);
      if (da.success) {
        setSigningData(da.success)
        window.parent.location.href = da.success;
      }
    });
    
  }

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const crumbs = [{ label: "/ Brands", to: "/products" }];



  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {contextHolder}
      <div className="products-container">
        <section className="section">
          <h2 className="section-title">Brands</h2>
          <Slider {...settings}>
            {brands &&
              brands.map((product: any, index: number) => (
                <Link
                  to={`/brand/${product._id}`}
                  className="single-slide"
                  key={index}
                  style={{
                    width: 350,
                    // height: 200
                  }}
                >
                  <img src={product.logo} 
                  
                  style={{
                    width: '100%',
                    height:'100%',
                    objectFit: 'contain' 
                  }}
                  
                  alt="*" />
                </Link>
              ))} 
            <div className="single-slide more">
              <span>More to come!</span>
            </div>
            {windowSize?.current[0] >= 768 && (
              <>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </>
            )}
          </Slider>
        </section>
      </div>

 


      {modal && (
        <div className="modal">
          <div className="modalOVerlay" />
          <div className="modalWrap">
            {
              !user?.doc_signed
              ?
              <div className="modalbody2"
                  style={{ backgroundColor: `#FFF` }}
                >
                <p className="modaltitle2" style = {{textAlign: 'center'}}>Please complete and accept the terms & conditions.</p>
                <div className="iframe-container">
                <div className="background-image" style={{ backgroundImage: `url(${docImage})` }}>
                {signingData && <iframe src={signingData} title="Embedded Content" 
                  width="100%" height="100%" frameBorder="0" 
                
                />}
                </div>
                </div>
                <div className="button-container">
                  {!signingData &&
                  <Button
                    variant="primary"
                    onClick={() => {
                      getLink();
                    }}
                    label={`${load ? 'Starting...' : 'Start'}`}
                  />}
                </div>
          
              </div>

              :
              <div
              className="modalbody"
              style={{ backgroundImage: `url(${modalbackground})` }}
            >
              <div className="modalcontent">
                <img
                  src={crossicon}
                  alt=""
                  className="modalclose"
                  onClick={() => setModal(false)}
                />
                <p className="modaltitle">Book your welcome 1on1 session</p>
                <p className="modalsubtitle">with your beauty advisor.</p>
                <p className="modallefttitle">
                  An exclusive personalised virtual session to get you started
                </p>
                <div className="modalbottom">
                  <div className="modalleft">
                    <p className="modaldiv">We’ll dive into</p>
                    <ol className="list-disc space-y-4 ml-[30px] mt-[5px] modallst">
                      <li className="modallistitem">
                        Your favourite products and how to use them
                      </li>
                      <li className="modallistitem">
                        Resolve any product questions
                      </li>
                      <li className="modallistitem">
                        How to navigate the platform
                      </li>
                      <li className="modallistitem">
                        Understanding the commission system
                      </li>
                    </ol>
                    <Link className="modalschebtn" to={`/community`}>
                      Schedule Now
                    </Link>
                  </div>
                  <div className="modalright">
                    <div className="modalrightitem">
                      <img src={modal1} alt="" />
                      <p className="modalrightitemname">Heather McDermott</p>
                     
                    </div>
                    <div className="modalrightitem">
                      <img src={modal2} alt="" />
                      <p className="modalrightitemname">Amanda Ortiz</p>
                      
                    </div>
                  </div>
                </div>
              </div>
              </div>
            }
          </div>
        </div>
      )}
    </SidebarLayout>
  );
}
  