import React, { useEffect, useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import AnalyticsLayout from "components/layout/AnalyticsLayout";
import { PartnerAnalytics } from "components/Icons";
import search from "../../../../assets/Svg/searchicon.svg";
import dropdown from "../../../../assets/Svg/dropdownicon.svg";
import arrowpurple from "../../../../assets/Svg/arrowpurple.svg";
import arrowgray from "../../../../assets/Svg/arrowgray.svg";
import arrowred from "../../../../assets/Svg/arrowred.svg";
import arrowgreen from "../../../../assets/Svg/arrowgreen.svg";
import checkbox from "../../../../assets/Svg/checkgray.svg";
import copyicon from "../../../../assets/Svg/copyicon.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAllData } from "../_redux/analytics/Actions";
import * as product_actions from "../../products/_redux/products/Actions";
import * as actions from "../../products/_redux/products/Actions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { toast } from "react-toastify";
import "./styles.css";
import { Dropdown, notification, Space, Button as Butt } from "antd";
// import 'bootstrap/dist/css/bootstrap.css';
import type { NotificationPlacement } from 'antd/es/notification/interface';


let items: any = [];


function Analytic() {
  const dispatch = useDispatch<any>();
  const [date, set_date] = useState<any>(null);
  const [brand, set_brand] = useState<any>(null);
  const [branddrop, setBranddrop] = useState(false);
  const [brandselect, setBrandselect] = useState("Select Brand");
  const [compare, set_compare] = useState<any>(null);
  const [load, set_load] = useState<Boolean>(false);
  const [updatee, setUpdate] = useState(2)

  const contentperformancelist = [
    {
      id: 1,
      brand: "ROSE INC",
      pr: "TintedSerum",
      orders: 5,
      tsold: 170,
      returns: 10000,
      tc: 100,
      cl: 469,
      ctr: 3,
      cvr: 3,
    },
    {
      id: 4,
      brand: "JVN",
      pr: "BlackFriday/22",
      orders: 10,
      tsold: 170,
      returns: 10000,
      tc: 100,
      cl: 469,
      ctr: 3,
      cvr: 3,
    },
    {
      id: 1,
      brand: "BIOSSANCE",
      pr: "TintedSerum",
      orders: 6,
      tsold: 170,
      returns: 10000,
      tc: 100,
      cl: 469,
      ctr: 3,
      cvr: 3,
    },
  ];
  const channelperformancelist = [
    {
      id: 1,
      channel: "INSTAGRAM",
      totalsold: 135000,
      reach: 10000,
      cvr: 12,
      returns: 4,
      order: 47,
    },
    {
      id: 2,
      channel: "TIKTOK",
      totalsold: 135,
      reach: 36445,
      cvr: 12,
      returns: 4,
      order: 30,
    },

    {
      id: 3,
      channel: "YOUTUBE",
      totalsold: 121,
      reach: 6000,
      cvr: 10,
      returns: 3,
      order: 44,
    },

    {
      id: 4,
      channel: "BLOG",
      totalsold: 87,
      reach: 1500,
      cvr: 2,
      returns: 1,
      order: 23,
    },
  ];

  const {
    sum_analytics,
    content_performance,
    channel_performance,
    brands,
    loading,
  } = useSelector(
    (state: any) => ({
      sum_analytics: state.analytics.sum_analytics,
      loading: state.analytics.listLoading,
      channel_performance: state.analytics.channel_performance,
      brands: state.products.brands,
      content_performance: state.analytics.content_performance,
    }),
    shallowEqual
  );

  useEffect(() => {
    // dispatch(fetchAllData('sum_analytics', ''))
    dispatch(product_actions.fetchAllData("brands"));
    // dispatch(fetchAllData('content_performance', ''))
    // dispatch(fetchAllData('channel_performance', ''))
  }, []);

  useEffect(() => {
    var d: any = []

    var dd: any = {};
    dd['label'] = 'All';
    dd['title'] = 'All';
    dd['key'] = '';
    d.push(dd);
    brands && brands.forEach((element: any, index: number) => {
      var dd: any = {};
      dd['label'] = element.name;
      dd['title'] = element.name;
      dd['key'] = element._id;
      d.push(dd);
    });
    items = d;
    setUpdate(updatee + 1)
  }, [brands])

  useEffect(() => {
    var q = null;
    if (date && !brand) {
      q = `start=${date.startDate}&end=${date.endDate}`;
    } else if (!date && brand) {
      q = `brand_id=${brand}`;
    } else if (date && brand) {
      q = `start=${date.startDate}&end=${date.endDate}&brand_id=${brand}`;
    } else {
      q = "";
    }

    dispatch(fetchAllData("sum_analytics", `${q}`));
    dispatch(fetchAllData("content_performance", `${q}`));
    dispatch(fetchAllData("channel_performance", `${q}`));

    if (date) {
      var qu = null;
      const date1: any = new Date(date.startDate);
      const date2: any = new Date(date.endDate);
      const diffTime = Math.abs(date1 - date2);
      const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      var date3 = new Date(date.endDate); // Now
      date3.setDate(date2.getDate() - days); // Set now + 30 days as the new date
      if (brand) {
        qu = `start=${date2}&end=${date3}&brand_id=${brand}`;
      } else {
        qu = `start=${date2}&end=${date3}`;
      }
      // console.log(qu);

      dispatch(fetchAllData("sum_analytics_compare", `${qu}`)).then(
        (res: any) => {
          if (res.success) {
            // console.log("compare", res.success)
            set_compare(res.success);
          }
        }
      );
    }
  }, [date, brand]);

  function handleSubmit() {
    console.log(date);
  }

  function reqP() {
    if (load) return;
    set_load(true);
    dispatch(fetchAllData("request_payment"))
      .then((res: any) => {
        // console.log(res);
        set_load(false);
        if (res.error) {
          toast.error(res.error);
        } else {
          toast.info(res.success);
        }
      })
      .catch((err: any) => {
        set_load(false);
        toast.error("Error requesting for payment " + err);
      });
  }

  const handleMenuClick = (el: any) => {
    Object.keys(items).map((_, index: number) => {
      if (items[index]?.key == el?.key) {
        setBrandselect(items[index]?.label);
      }
    })
    set_brand(el?.key);
    setBranddrop(false);
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };



  const close = () => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
  };
  
  const [api, contextHolder] = notification.useNotification();
  const copyValue = (d: string) => {
    navigator.clipboard.writeText(d)
  };


  const openNotification = (placement: NotificationPlacement, type: string, data: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Butt type="link" size="small" onClick={() => {
          copyValue(data);
          api.destroy();
        }}>
          COPY LINK!
        </Butt>
      </Space>
    );
    api.open({
      message: 'Copy to clipboard!',
      description:
        `Click the confirm button to copy ${data} to your clipboard!`,
      placement,
      btn,
      key,
      onClose: close,
    });
  };


  return (
    <AnalyticsLayout
      headerTitle="ANALYTICS DASHBOARD"
      headerIcon={PartnerAnalytics}
    >
      {contextHolder}
      <div className="analytics-top">
        <div className="eaeupcoming-nav">
          <div className="ecnav-item2">
            <div
              className="analyselection"
              style={{ marginRight: 20, width: 250 }}
            >
              <div className="">
                <p className="hclabel">Date range</p>
                <div className="analyselection__cnt">
                  <div className="analysection_cnt">
                    <DateRangePicker
                      onApply={(a, b) => {
                        set_date({
                          startDate: b.startDate.format("YYYY-MM-DD"),
                          endDate: b.endDate.format("YYYY-MM-DD"),
                        });
                        handleSubmit();
                      }}
                      initialSettings={{
                        startDate: "1/1/2023",
                        endDate: new Date(),
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          backgroundColor: "transparent",
                          width: "100%",
                        }}
                      />
                    </DateRangePicker>
                    <img
                      className="vblsc_right"
                      style={{ paddingLeft: 20 }}
                      src={dropdown}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="analyselection">
              <div className="dorpdown">
                <p className="hclabel">Filter: Brands</p>
                <Dropdown menu={menuProps} trigger={['click']}>
                  <div className="user">
                    <span className="labele">{brandselect}</span>
                    <DownOutlined color="#370E78" />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="analytictoponn">
        <div className="analytics-topone">
          <div className="analytics-toponeitem">
            <p className="tponetitle">Earning summary</p>
            <div className="tponebuttom">
              <div className="tponebuttom-item">
                <p className="tponebuttomtitle">Lifetime earnings</p>
                <p className="tponebuttomsen">
                  {/* {loading
                  ? "-"
                  : sum_analytics && sum_analytics?.lifetime_earning.length > 0
                  ? sum_analytics?.lifetime_earning
                      .map(
                        (data: any) =>
                          `${data.currency} ${data.Total.toFixed(2)}`
                      )
                      .join(", ")
                  : "0"} */}
                  {sum_analytics
                    ? `GBP ${sum_analytics?.lifetime_earning}`
                    : "-"}
                </p>
              </div>
              <div className="tponebuttom-item">
                <p className="tponebuttomtitle">Cash out balance</p>
                <p className="tponebuttomsen">
                  {loading
                    ? "-"
                    : sum_analytics && sum_analytics?.lifetime_earning.length > 0
                      ? sum_analytics?.lifetime_earning
                        .map(
                          (data: any) =>
                            `${data.currency} ${data.Total.toFixed(2)}`
                        )
                        .join(", ")
                      : "0"}
                </p>
              </div>
            </div>

            <button onClick={() => reqP()} className="analytic_btn2">{`${load ? "Loading..." : "REQUEST CASHOUT"
              }`}</button>
          </div>
          <div className="analytics-toponeitem">
            <p className="tponetitle">Partnership growth</p>
            <div className="tponebuttom">
              <div className="tponebuttom-item">
                <p className="tponebuttomtitle">Partnership Level</p>
                <p className="attiamount">
                  {sum_analytics ? sum_analytics?.partnership : "-"}
                </p>
              </div>
              <div className="tponebuttom-item">
                <p className="tponebuttomtitle">
                  {sum_analytics && sum_analytics.advice
                    ? sum_analytics?.advice?.text
                    : "Choose brand to view insight"}{" "}
                </p>
                {/* <p className="tponebuttomsen">
                  {sum_analytics && sum_analytics.advice
                    ? sum_analytics?.advice?.figure
                    : "N/A"}{" "}
                </p> */}
                <p className="tponebuttomsen">
                  {sum_analytics && sum_analytics.advice
                    ? '-------------'
                    : "N/A"}{" "}
                </p>
                {/* <p className="tponebuttomsen">{sum_analytics ? sum_analytics?.total_orders : '-'}</p> */}
              </div>
            </div>
            <button
              className="analytic_btn2"
              style={{ backgroundColor: "white", borderColor: "white" }}
            >
              REQUEST CASHOUT
            </button>
          </div>
        </div>
        <div className="analytics-toptotals">
          <div className="analytics-toptotalsitem">
            <p className="attititle">Total sales</p>
            <p className="tponebuttomsen">
              {loading
                ? "-"
                : sum_analytics && sum_analytics?.total_sales.length > 0
                  ? sum_analytics?.total_sales
                    .map(
                      (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                    )
                    .join(", ")
                  : "0"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.total_sales[0]?.Total ||
                      0 >= compare?.total_sales[0]?.Total ||
                      0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs(
                  ((sum_analytics?.total_sales[0]?.Total ||
                    0 - compare?.total_sales[0]?.Total ||
                    0) / sum_analytics?.total_sales[0]?.Total || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )}
          </div>

          <div className="analytics-toptotalsitem">
            <p className="attititle">Return/Cancellation</p>
            <p className="tponebuttomsen">
              {loading
                ? "-"
                : sum_analytics ? sum_analytics.cancellation
                  : "GBP 0"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.cancellation ||
                      0 >= compare?.cancellation ||
                      0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs(
                  ((sum_analytics?.cancellation ||
                    0 - compare?.cancellation ||
                    0) / sum_analytics?.cancellation || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )} 
            {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">4.1%</p>
          </div> */}
          </div>

          <div className="analytics-toptotalsitem">
            <p className="attititle">Pending Commission</p>
            <p className="tponebuttomsen">
              {loading
                ? "-"
                : sum_analytics && sum_analytics?.pending_commissionable.length > 0
                  ? sum_analytics?.pending_commissionable
                    .map(
                      (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                    )
                    .join(", ")
                  : "GBP 0"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.pending_commissionable[0]?.Total ||
                      0 >= compare?.pending_commissionable[0]?.Total ||
                      0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs(
                  ((sum_analytics?.pending_commissionable[0]?.Total ||
                    0 - compare?.pending_commissionable[0]?.Total ||
                    0) / sum_analytics?.pending_commissionable[0]?.Total || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )}
            {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">4.1%</p>
          </div> */}
          </div>

          <div className="analytics-toptotalsitem">
            <p className="attititle">Earned commissions</p>
            <p className="tponebuttomsen">
              {loading
                ? "-"
                : sum_analytics && sum_analytics?.total_commissions.length > 0
                  ? sum_analytics?.total_commissions
                    .map(
                      (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                    )
                    .join(", ")
                  : "0"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.total_commissions[0]?.Total ||
                      0 >= compare?.total_commissions[0]?.Total ||
                      0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs(
                  ((sum_analytics?.total_commissions[0]?.Total ||
                    0 - compare?.total_commissions[0]?.Total ||
                    0) / sum_analytics?.total_commissions[0]?.Total || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )}
            {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">4.1%</p>
          </div> */}
          </div>

          <div className="analytics-toptotalsitem">
            <p className="attititle">Total # orders</p>
            <p className="attiamount">
              {sum_analytics ? sum_analytics?.total_orders : "-"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.total_orders ||
                      0 >= compare?.total_orders ||
                      0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs(
                  ((sum_analytics?.total_orders ||
                    0 - compare?.total_orders ||
                    0) / sum_analytics?.total_orders || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )}
          </div>

          <div className="analytics-toptotalsitem">
            <p className="attititle">Number of clicks</p>
            <p className="attiamount">
              {sum_analytics ? sum_analytics?.clicks : "-"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.clicks || 0 >= compare?.clicks || 0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs(
                  ((sum_analytics?.clicks || 0 - compare?.clicks || 0) /
                    sum_analytics?.clicks || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )}
            {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">1.6%</p>
          </div> */}
          </div>
          
          <div className="analytics-toptotalsitem">
            <p className="attititle">CVR</p>
            <p className="attiamount">
              {sum_analytics ? sum_analytics?.cvr : "-"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.cvr || 0 >= compare?.cvr || 0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs( 
                  ((sum_analytics?.cvr || 0 - compare?.cvr || 0) /
                    sum_analytics?.cvr || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )}
          </div>

          {/* <div className="analytics-toptotalsitem">
            <p className="attititle">Avg. return rate</p>
            <p className="attiamount">
              {sum_analytics ? sum_analytics?.avg_return : "-"}
            </p>
            {compare && (
              <div className="attirating">
                <img
                  src={
                    sum_analytics?.avg_return || 0 >= compare?.avg_return || 0
                      ? arrowgreen
                      : arrowred
                  }
                  alt=""
                />
                <p className="attirate atgrn">{`${Math.abs( 
                  ((sum_analytics?.avg_return || 0 - compare?.avg_return || 0) /
                    sum_analytics?.avg_return || 0) * 100
                ).toFixed(2)} %`}</p>
              </div>
            )}
          </div> */}
        </div>
      </div>

      <div className="analyticscp">
        <p className="analyticscptitle">Performance</p>
        <div className="ctpover">
          <div className="ctpeformance_header cpheader">
            <div className="ctp">
              <div className="ctpp">
                <img src={checkbox} alt="" />
                <p className="ctppname">Brand</p>
              </div>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ctp">
              <p className="ctppname">PRODUCT REFERENCE</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ctp">
              <p className="ctppname">AFFILIATE LINK/CODE</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ctp">
              <p className="ctppname">NO OF ORDERS</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ctp">
              <p className="ctppname">TOTAL SOLD($)</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            {/* <div className="ctp">
              <p className="ctppname"># RETURNS</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div> */}
            <div className="ctp">
              <p className="ctppname">TOTAL COMMISSION($)</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            {/* <div className="ctp">
              <p className="ctppname"># CLICKS</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div> */}
            {/* <div className="ctp">
              <p className="ctppname">CTR</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div> */}
            {/* <div className="ctp">
              <p className="ctppname">CVR</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div> */}
          </div>
          {content_performance &&
            content_performance.map((cplist: any, key: number) => (
              <div className="ctpeformance_header" key={key}>
                <div className="ctp">
                  <div className="ctpp">
                    <img src={checkbox} alt="" />
                    <p className="ctppname">{cplist?.brand.name}</p>
                  </div>
                </div>
                <div className="ctp">
                  <p className="ctppname">{cplist?.name
                }</p>

                </div>
                <div className="ctp">
                      <p className="ctppname aflink">{cplist?.discount?.code}</p>
                </div>
                   
                {/* <div className="ctp">
                  <p
                    onClick={() => {
                      var bod: any = {};
                      bod["brand_id"] = cplist.brand._id;
                      dispatch(product_actions.addData(bod, "discount_code")).then((da: any) => {
                        openNotification('bottomRight', 'code', da);
                      });
                      
                    }}
                    className="ctppname aflink"
                  >
                    CLICK HERE
                  </p>
                </div> */}
                <div className="ctp">
                  <p className="ctppname">{cplist.stats.total_orders}</p>
                </div>
                {/* <div className="ctp">
                  <p className="ctppname">
                    {cplist.stats && cplist.stats?.total_sales.length > 0
                      ? cplist.stats?.total_sales
                        .map(
                          (data: any) =>
                            `${data.currency} ${data.Total.toFixed(2)}`
                        )
                        .join(", ")
                      : "0"}
                  </p>
                </div> */}
                <div className="ctp">
                  <p className="ctppname">{`GBP ${cplist.stats.total_sales}`}</p>
                </div>
                <div className="ctp">
                  <p className="ctppname">{`GBP ${cplist.stats.total_commissions.toFixed(2)}`}</p>
                </div>
                {/* <div className="ctp">
                  <p className="ctppname">
                    {cplist.stats && cplist.stats?.total_commissions.length > 0
                      ? cplist.stats?.total_commissions
                        .map(
                          (data: any) =>
                            `${data.currency} ${data.Total.toFixed(2)}`
                        )
                        .join(", ")
                      : "0"}
                  </p>
                </div> */}
                {/* <div className="ctp">
                  <p className="ctppname">{cplist.stats.clicks}</p>
                </div> */}
                {/* <div className="ctp">
                <p className="ctppname">{cplist.stats.avg_return}%</p>
              </div> */}
                {/* <div className="ctp">
                  <p className="ctppname">
                    {`${cplist.stats.cvr
                      }`}
                  </p>
                </div> */}
              </div>
            ))}
        </div>

        <button className="analytic_btn">LOAD MORE</button>
      </div>
      <div className="analyticscp">
        <p className="analyticscptitle">Channel Performance</p>
        <div className="ctpover">
          <div className="analyticscpheader">
            <div className="ansphref">
              <div className="ansphbrands">
                <img src={checkbox} alt="" />
                <p className="ansphbrandsname">CHANNEL</p>
              </div>
              <div className="ansphfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ansphref">
              <p className="ansphbrandsname">TOTAL Clicks</p>
              <div className="ansphfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ansphref">
              <p className="ansphbrandsname">ORDERS</p>
              <div className="ansphfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ansphref">
              <p className="ansphbrandsname">REACH</p>
              <div className="ansphfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ansphref">
              <p className="ansphbrandsname">CVR</p>
              <div className="ansphfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
            <div className="ansphref">
              <p className="ansphbrandsname">#RETURNs</p>
              <div className="ansphfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div>
          </div>
          {channel_performance &&
            channel_performance.map((cplist: any, key: number) => (
              <div
                className={
                  key === channel_performance.length - 0
                    ? "analyticitemchange"
                    : "analyticitem"
                }
                key={key}
              >
                <div className="ansphref">
                  <div className="ansphbrands">
                    <img src={checkbox} alt="" className="naasa" />
                    <p className="ansphbrandsname">{cplist._id || "WEB"}</p>
                  </div>
                </div>
                <div className="analyticbrands">
                  <div className="cptotalprog">
                    <div
                      className="cptotalprogna"
                      style={{ width: cplist.percentage * 2 }}
                    >
                      <label>{cplist.total}</label>
                    </div>
                  </div>
                </div>
                <div className="analyticproduct">
                  <div className="">
                    <p className="productretitle" key={key}>
                      {cplist.order}
                    </p>
                  </div>
                </div>
                <div className="analyticproduct">
                  <div className="">
                    <p className="productretitle" key={key}>
                      {cplist.reach}
                    </p>
                  </div>
                </div>
                <div className="analyticproduct">
                  <div className="">
                    <p className="productretitle" key={key}>
                      {cplist.cvr}%
                    </p>
                  </div>
                </div>
                <div className="analyticproduct">
                  <div className="">
                    <p className="productretitle" key={key}>
                      {cplist.returns}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <button className="analytic_btn">LOAD MORE</button>
      </div>
    </AnalyticsLayout>
  );
}

export default Analytic;
