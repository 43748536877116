import React, {useEffect, useState}  from 'react';
import { Menu, Layout } from 'antd';
import Logo from 'components/Logo';
import { useNavigate } from 'react-router-dom';
import { menuItems } from './data';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

const { Sider } = Layout;

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const items = () => (
    menuItems.map((menu, index) => ({
      key: String(index),
      icon: React.createElement(menu.icon),
      label: menu.label,
      onClick: () => navigate(menu.to)
    }))
  )

  const routes = ['/products', '/analytics', '/content', '/community', '/payouts'];
  const [index, set_index] = useState<any>(0);

  useEffect(() => {
    if (routes.includes(location.pathname)) {
      console.log(routes.indexOf(location.pathname));
      set_index(routes.indexOf(location.pathname));
    }
  }, [location.pathname]);

  return (
    <Sider
      className="sidebar-container"
      breakpoint="lg"
      collapsedWidth="80"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <Link to = '/products' className="logo">
        <Logo />
      </Link>
      <Menu
        mode="inline"
        selectedKeys={[index.toString()]}
        items={items()}
      />
    </Sider>
  )
}
