import { CreatorVerification, Google } from "components/Icons";
import ProfileLayout from "components/layout/ProfileLayout";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dropdown from "../../../../assets/Svg/dropdownicon.svg";
import "./styles.scss";
import AccountSetting from "./account_settings";
import PersonalInformation from "./personal_info";
import PasswordManagement from "./password_management";
import Payment from "./payment_information";
import PayoutHistory from "./payout_history";

function Profile() {
  const [pageview, setPageview] = useState("PERSONAL INFORMATION");
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabdrop, settabdrop] = useState(false);
  //

  const buttonList = [
    {
      id: "button-1",
      title: "PERSONAL INFORMATION",
    },
    {
      id: "button-2",
      title: "ACCOUNT SETTINGS",
    },
    {
      id: "button-3",
      title: "PAYMENT INFORMATION",
    },
    {
      id: "button-4",
      title: "PASSWORD MANAGEMENT",
    },
    {
      id: "button-5",
      title: "PAYOUT HISTORY",
    },
  ];

  useEffect(() => {
    if (searchParams.get("code")) {
      setPageview(`button-2`);
    }
  }, []);

  return (
    <ProfileLayout headerTitle="PROFILE" headerIcon={CreatorVerification}>
      <div className="comhubcnt">
        <div className="profilepagebuttons">
          {buttonList.map((btnlist, key) => (
            <button
              className={
                pageview === `${btnlist.title}`
                  ? "active_button"
                  : "notactive_button"
              }
              key={key}
              onClick={() => {
                setPageview(`${btnlist.title}`);
              }}
            >
              {btnlist.title}
            </button>
          ))}
        </div>

        <div className="communityhubbuttonsmobile">
          <div className="cbbmtop" onClick={() => settabdrop(true)}>
            <label>{pageview}</label>
            <img src={dropdown} alt="" />
          </div>
          {tabdrop && (
            <div className="">
              <div
                className="vblscdropoverlay"
                onClick={() => settabdrop(false)}
              />
              <div className="vblsdrop">
                {buttonList.map((btnlist: any, key: number) => (
                  <div
                    className={`dboxlabel ${
                      key === buttonList.length - 1 ? "" : "vblsdroplabel"
                    }`}
                  >
                    <label
                      key={key}
                      onClick={() => {
                        setPageview(`${btnlist.title}`);
                        settabdrop(false);
                      }}
                    >
                      {btnlist.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="profilepagebody">
          {pageview === "PERSONAL INFORMATION" && <PersonalInformation />}
          {pageview === "ACCOUNT SETTINGS" && <AccountSetting />}
          {pageview === "PAYMENT INFORMATION" && <Payment />}
          {pageview === "PASSWORD MANAGEMENT" && <PasswordManagement />}
          {pageview === "PAYOUT HISTORY" && <PayoutHistory />}
        </div>
      </div>
    </ProfileLayout>
  );
}

export default Profile;
