import { Checkbox } from 'antd';
import { ForwardArrow } from 'components/Icons';

interface SocialInputProps {
  onChange?: any;
  label: string;
  icon?: any;
  value?: string;
}


export default function SocialInput({ onChange, value, label, icon }: SocialInputProps) {

  return (
    <div>
      <div className="check-button">
        <div className="flex frame">
          <div className="flex items-center icon">
            {icon && icon}
            {/* <span className='channelcaps'>{label || ''}</span> */}
            <input
            defaultValue = {value}
            // onChange={(e: any) => onChange(e.target.value.trim())}
            onChange={onChange}
            placeholder = {`Enter ${label} profile link`}
            style = {{backgroundColor: 'transparent', width: 300}}/>
          </div>
          {/* <div className="pointt">
            <ForwardArrow />
          </div> */}
        </div>
      </div>
    </div>
  )
}
