import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'brands') {
    return axios.get(`${URL}api/brand/all`);
  } else if (type === 'categories') {
    return axios.get(`${URL}api/category/all${queryParams || '?'}`);
  } else if (type === 'products') {
    return axios.get(`${URL}api/product/all${queryParams}`);
  } else if (type === 'tracking') {
    return axios.get(`${URL}api/product/tracking_code${queryParams}`);
  } else if (type === 'product') {
    return axios.get(`${URL}api/product${queryParams}`);
  } else if (type === 'commission') {
    return axios.get(`${URL}api/commission_scheme/all${queryParams}`);
  } else if (type === 'signing_link') {
    return axios.get(`${URL}auth/user/initiate-signing`);
  } else if (type === 'ip') {
    return axios.get(`https://ipapi.co/json/`);
  } else if (type === 'gen_code') {
    return axios.get(`${URL}api/product/generate_code`);
  }
}

export function getTierData(brandId) {
  return axios.get(`${URL}api/brand/tier?brand_id=${brandId}`);
}

export function fetchBrandDetails(id) {
  return axios.get(`${URL}api/brand?brand_id=${id}`);
}

export function fetchProductDetails(id) {
  return axios.get(`${URL}api/product?product_id=${id}`);
}


export function addBrand(payload) {
  return axios.post(`${URL}api/brand`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function addFile(payload) {
  return axios.post(`${URL}api/file`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function addClick(payload) {
  return axios.post(`${URL}api/analytics/click`, payload);
}

export function addData(payload, type) {
  if (type === 'event') {
    return axios.post(`${URL}auth/user/doc-signing`, payload);
  }
  return axios.post(`${URL}api/product/discount_code`, payload);
}

export function editDetails(payload, query) {
  return axios.put(`${URL}api/product${query}`, payload);
}

export function editBrand(payload, brandId) {
  return axios.put(`${URL}api/brand?brand_id=${brandId}`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getProductFilesAll(queries) {
  return axios.get(`${URL}api/file/all${queries}`);
}

export function deleteSingleBrand(id) {
  return axios.delete(`${URL}api/brand?brand_id=${id}`);
}
