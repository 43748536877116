import { Radio } from 'antd';

interface RadioButtonProps {
  value: string;
  label: string;
}

export default function RadioButton({ value, label }: RadioButtonProps) {
  return (
    <Radio value={value} className="radio-button">
      <div className="labele">
        <span>{label || ''}</span>
      </div>
    </Radio>
  )
}
