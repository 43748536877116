import { Steps } from "../utils";
import "./styles.scss";
import config from "app/global/config";
import FooterAuth from "./FooterAuth";
import Logo from "components/Logo";

export default function AuthContainer(props: any) {
  const { children, intro, title, subTitle, titleB, steps } = props;
  const defaultSteps = [
    { title: "Basic info" },
    { title: "Connect channels" },
    { title: "About you" },
    { title: "Welcome pack" },
  ];
  return (
    <>
      <div className="auth-container">
        <div className="inner">
          <a href={config.landing} className="logo">
            <Logo />
            {/* <img src={require("assets/images/logo.png")} /> */}
          </a>
          {/* <div className="logo"><Logo /> </div> */}
          <div className="children">
            <p className="intro">
              Empower you the creator, to <span>monetise</span> your content,
              while effectively inspiring consumers to{" "}
              <span>make good, without compromise.</span>{" "}
            </p>
            <h2 className="title">{title || ""}</h2>
            <Steps items={steps && defaultSteps} />
            <h3 className="title-b">{titleB || ""}</h3>
            <p className="sub-title">{subTitle || ""}</p>
            {children}
          </div>
        </div>
      </div>
      <FooterAuth />
    </>
  );
}
