import { useState, useEffect, useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Checkbox, Alert, Space } from 'antd';
import AuthContainer from 'components/layout/AuthContainer'
import { Field, Button } from 'components/forms';
import { Google, Apple, Facebook } from 'components/Icons';
import * as auth from '../../redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { login, get_social, getUserByToken } from '../../redux/AuthCRUD'
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import config from 'app/global/config';
import * as functions from 'app/global/functions';
import { toast } from 'react-toastify';

import {
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";


export default function Login() {
  const [facebookSelected, setFacebookSelected] = useState(false);

  const REDIRECT_URI =  `${process.env.CREATOR_APP_URL || 'https://creator.cleancreatorcommunity.com/'}login`

  const onLoginStart = useCallback(() => {
    // alert('login start');
    // console.log(process.env.REACT_APP_TWITTER_V2_APP_KEY);
  }, []);


  const prop = {
    intro: 'Empower you the creator,  to monetise your content,  while effectively inspiring consumers to  make good, without compromise.',
    title: 'Login',
    subTitle: 'To access your account, please enter your email address and password used in your application.'
  }
  const dispatch = useDispatch()

  let { code } = useParams();

  useEffect(() => {
    dispatch(auth.actions.logout());
  }, [])

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [email, set_email] = useState('')
  const [password, set_password] = useState('')
  const [show_apple, setShowAppleId] = useState(false)
  const navigate = useNavigate();
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    // alert('logout success');
  }, []);

  function send_c(code: any) {
    setStatus('');
    var b: any = {};
    b['access_token'] = code;
    get_social(b, 'apple')
      .then((response) => {
        setLoading(false);
        var data = response.data;
        console.log(data);
        if (data.success) {
          var resu = data.success;

          var name = resu?.name;
          if (name) {
            name = name.split(' ');
            dispatch(auth.actions.set_first_name(name[0]));
            dispatch(auth.actions.set_last_name(name[name.length - 1]));
          }
          dispatch(auth.actions.set_email(resu?.email));
          navigate("/register");
        } else {
          setStatus('Something went wrong! Try again');
        }
      })
      .catch((error) => {
        var message = error.response.data ? error.response.data.message : 'Network error occured!';
        setLoading(false);
        setStatus(message ? message : 'Network error occured!');
      });
  }

  async function subm() {

    if (email === '' || password === '') {
      toast.error('Enter all inputs!');
      return;
    }

    // console.log(password, email); return;

    if (!functions.validateEmail(email)) {
      toast.error('Enter a valid email address!');
      return;
    }

    setLoading(true);
    setStatus('');
    login({ email: email, password: password, type: 'creator' })
      .then(async (response) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
          setStatus(data.error);
        } else {
          dispatch(auth.actions.fulfillToken(data.user_token));
          const { data: user } = await getUserByToken()
          dispatch(auth.actions.fulfillUser(user));

          if (user.user.verification_status && user.user.verification_status !== 'approved') {
            navigate("/channels");
          }

        }
      })
      .catch((error) => {
        var message = error.response.data ? error.response.data.message : 'Network error occured!';

        setLoading(false);
        setStatus(message ? message : 'Network error occured!');
      });
  }



  const googleLogin = useGoogleLogin({
    flow: 'implicit', //auth-code
    onSuccess: async (codeResponse) => {
      const access_token = codeResponse.access_token;
      setLoading(true);
      setStatus('');
      var b: any = {};
      b['access_token'] = access_token;
      get_social(b, 'google')
        .then((response) => {
          setLoading(false);
          var data = response.data;
          if (data.success) {
            var resu = data.success;
            dispatch(auth.actions.set_first_name(resu.given_name));
            dispatch(auth.actions.set_last_name(resu.family_name));
            dispatch(auth.actions.set_email(resu.email));
            navigate("/register");
          } else {
            setStatus('Something went wrong! Try again');
          }
        })
        .catch((error) => {
          var message = error.response.data ? error.response.data.message : 'Network error occured!';
          setLoading(false);
          setStatus(message ? message : 'Network error occured!');
        });

    },
    onError: errorResponse => console.log(errorResponse),
  });

  return (
    <AuthContainer {...prop}>

      <form onSubmit={(e: any) => {
        e.PreventDefault();
        subm();
      }} className="auth-form">
        {status &&
          <Alert
            description={`Error! ${status}`}
            type="error"
            closable
            showIcon
            onClose={() => setStatus('')}
          />}<br></br>
        <Field type="email" required={true}
          onChange={(e: any) => set_email(e.target.value.trim())}
          label="Email address" placeholder="e.g. johndoe@email.com" />
        <Field type="password"
          onChange={(e: any) => set_password(e.target.value.trim())}
          required={true} label="Password" placeholder="" />
        <div className="flex items-center justify-between meta">
          <Checkbox>Remember me</Checkbox>
          <div className="forgot">
            <Link to="/forgot-password">Forgot password</Link>
          </div>
        </div>
        <div className="actions">
          <Button
            loading={loading}
            onClick={() => subm()} variant="primary" label="Login" />
          <span className="or">OR</span>
          <Space direction="vertical" size="middle" className="w-100">
            {/* <Button variant="secondary" onClick={() => googleLogin()} Icon={Google} label="Sign In with Google" /> */}

            <LoginSocialFacebook
              appId={process.env.REACT_APP_FB_APP_ID || ""}
              fieldsProfile={
                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
              }
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              redirect_uri={REDIRECT_URI}
              onResolve={({ provider, data }: IResolveParams) => {
                // var b: any = {};
                // b["type"] = provider; 
                // b["data"] = data;
                // console.log(provider, data      )
                dispatch(auth.actions.set_first_name(data?.first_name));
                dispatch(auth.actions.set_last_name(data?.last_name));
                dispatch(auth.actions.set_email(data?.email));
                navigate("/register");

              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <Button variant="secondary" Icon={Facebook} label="Sign In with Facebook" />
            </LoginSocialFacebook>
            {/* <Button variant="secondary" Icon={Apple} label="Sign In with Apple" /> */}
            {/* <AppleLogin
              clientId={config.client_id}
              redirectURI={config.redirect_uri}
              // usePopup={true}
              callback={(ddd) => {
                // console.log(ddd)
                send_c(ddd.code);
              }}
              scope={config.scope}
              responseMode="query"
              render={renderProps => (  //Custom Apple Sign in Button
                <Button onClick={renderProps.onClick} variant="secondary" Icon={Apple} label="Sign In with Apple" />
              )}
            /> */}
            <Button variant="plain" label="Don't have an account? Apply Now" onClick={() => navigate("/register")} />
          </Space>
        </div>
      </form>
    </AuthContainer>
  )
}
