import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'staffs') {
    return axios.get(`${URL}api/booking/staffs`);
  } else if (type === 'resources') {
    return axios.get(`${URL}api/booking/resources`);
  } else if (type === 'services') {
    return axios.get(`${URL}api/booking/services`);
  } else if (type === 'workspaces') {
    return axios.get(`${URL}api/booking/workspaces`);
  } else if (type === 'availability') {
    return axios.get(`${URL}api/booking/availability${queryParams}`);
  } else if (type === 'bookings') {
    return axios.get(`${URL}api/booking/all`);
  }
}

export function fetchEventAll(type, q) {
  if (type === 'event') {
    return axios.get(`${URL}api/event/all`);
  } else {
    return axios.get(`${URL}api/event/search${q}`);
  }
}

export function deleteSingleEvent(id) {
  return axios.delete(`${URL}api/event/${id}`);
}


export function createData(payload, type) {
  if (type === 'email') {
    return axios.post(`${URL}api/booking/email`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    // return axios.post(`${URL}api/booking/email`, payload);
  } else if (type === 'attend_event') {
    return axios.post(`${URL}api/event/book/${payload}`, {});
    // return axios.post(`${URL}api/book`, `?event_id=${payload}`);
  } else if (type === 'cancel_event') {
    return axios.put(`${URL}api/event/cancelBooking/${payload}`, {});
    // return axios.post(`${URL}api/book`, `?event_id=${payload}`);
  }
  return axios.post(`${URL}api/booking`, payload);
  // return axios({
  //   method: "POST",
  //   url: URL+'api/booking',
  //   data: data,
  //   headers: { "Content-Type": "multipart/form-data" },
  // })
}

export function bookAnEvent(id) {
  return axios.post(`${URL}api/event/book/${id}`);
}

export function cancelAnEvent(id){
  return axios.put(`${URL}api/event/cancelBooking/${id}`);
}

export function getProductFilesAll(queries) {
  return axios.get(`${URL}api/file/all${queries}`);
}

export function deleteSingleBrand(id) {
  return axios.delete(`${URL}api/brand?brand_id=${id}`);
}
