import React, {useEffect, useState} from "react";
import { Steps as Stepper } from 'antd';
import "./styles.scss"
import { useLocation } from 'react-router-dom'

export default function Steps({items}: any) {
  const location = useLocation();
  const routes = ['/register', '/channels', '/about-you', '/welcome-pack'];
  const [index, set_index] = useState<any>(0);

  useEffect(() => {
    if (routes.includes(location.pathname)) {
      set_index(routes.indexOf(location.pathname))    
    }
  }, [location.pathname]);

  return (
    <div className="stepper-container">
      {items && items.length > 0
        ? <Stepper
          current={index}
          labelPlacement="vertical"
          items={items}
        />
        : null}
    </div>
  )
}
