import { Input } from 'antd';
import { EyeOn, EyeOff } from 'components/Icons';

import './styles.scss';

interface FieldProps {
  type?: 'text' | 'email' | 'password' | 'number' ;
  label?: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  initialValue?: string;
  status?: string;
  WithTopRight?: any;
  prefixSelector?: any;
  onChange?: (e?: any) => void;
  showCount?: boolean;
  maxLength?: number;
  className?: string;
  onPressEnter?: any;
  defaultValue?: any;
}

export default function Field({ type, disabled, value, required, label, placeholder, WithTopRight, prefixSelector,defaultValue, maxLength, showCount, className, onChange, onPressEnter }: FieldProps) {
  return (
    <div className={`form-item ${className}`}>
      {WithTopRight &&
        <div className="top-right">
          <WithTopRight />
        </div>
      }
      {label && <label htmlFor="">
        {label || ''}
        {required && <span className='required'>*</span>}
      </label>}

      {type === 'password'
        ?
        <Input.Password
          placeholder={placeholder || ''}
          onChange={onChange}
          value={value}
          disabled={disabled}
          required={required}
          iconRender={(visible) => (visible ? <EyeOn /> : <EyeOff />)}
        />
        : <Input placeholder={placeholder || ''} addonBefore={prefixSelector} showCount={showCount} maxLength={showCount ? maxLength || 20 : 200}
          disabled={disabled} value={value} defaultValue = {defaultValue} required={required} className="input-textfield" onChange={onChange} onPressEnter={onPressEnter} />
      }
    </div>
  )
}
