import React, { useState, useEffect } from "react";
import "./styles.scss";
import markIcon from "../../../../assets/Svg/markicon.svg";
import editIcon from "../../../../assets/Svg/editicon.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPayments, addBank, editB } from "../../auth/redux/AuthCRUD";
import { toast } from "react-toastify";
import * as auth from "../../auth/redux/AuthRedux";
import { Selectable } from "components/forms";
import { countryOptions } from './data'

function Payment() {
  const dispatch = useDispatch();
  const { user, bank } = useSelector(
    (state: any) => ({
      bank: state.auth.bank,
      user: state.auth?.user?.user,
    }),
    shallowEqual
  );


  const [searchText, setSearchText] = useState("");

  const [editPayment, setEditPayment] = useState(false);
  const [editD, set_ed] = useState<any>(null);
  const [add, setAdd] = useState(false);
  const [first_name, set_first_name] = useState(user?.first_name);
  const [last_name, set_last_name] = useState(user?.last_name);
  const [phone, set_phone] = useState(user?.phone);
  const [email, set_email] = useState(user?.email);
  const [iban, set_iban] = useState("");
  const [account_number, set_account_number] = useState("");

  const [city, set_city] = useState("");
  const [state, set_state_] = useState("");
  const [street, set_street] = useState("");
  const [number, set_number] = useState("");
  const [building, set_building] = useState("");
  const [floor, set_floor] = useState("");
  const [postal_code, set_postal_code] = useState("");
  const [country, set_country] = useState("");


  function filterByText(list: any, searchText: string) {
    return list.filter(
      (item: any) =>
        item.value.toLowerCase().search(searchText.toLowerCase()) === 0
    );
  }
  const onSearch = (text: string) => {
    setSearchText(text.length > 0 ? text.toLowerCase() : "");
  };

  const filteredList = searchText
    ? filterByText(countryOptions, searchText)
    : countryOptions;

  useEffect(() => {
    getPay();
  }, []);

  useEffect(() => {
    if (!editPayment) {
      set_first_name(user?.first_name);
      set_last_name(user?.last_name);
      set_phone(user?.phone);
      set_email(user?.email);
    } else {
      set_first_name(editD?.first_name);
      set_last_name(editD?.last_name);
      set_phone(editD?.phone_number);
      set_email(editD?.email_address);
      set_iban(editD?.iban);
      set_account_number(editD?.account_number);
      set_city(editD?.city);
      set_street(editD?.street);
      set_state_(editD?.state);
      set_building(editD?.building);
      set_number(editD?.number);
      set_floor(editD?.floor);
      set_postal_code(editD?.postal_code);
      set_country(editD?.country);
    }
  }, [editPayment]);

  function getPay() {
    getPayments()
      .then((response: any) => {
        var data = response.data;
        // console.log(data);
        dispatch(auth.actions.set_bank(data.success));
      })
      .catch((error: any) => {
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  function add_Pay() {
    if (
      first_name === "" ||
      last_name === "" ||
      phone === "" ||
      email === "" ||
      iban === "" ||
      account_number === "" ||
      city === "" ||
      state === "" ||
      street === "" ||
      // floor === "" ||
      postal_code === "" ||
      country === ""
    ) {
      toast.error("Enter all inputs!");
      return;
    }

    var b: any = {};
    b["first_name"] = first_name;
    b["last_name"] = last_name;
    b["phone_number"] = phone;
    b["email_address"] = email;
    b["iban"] = iban;
    b["account_number"] = account_number;
    b["city"] = city;
    b["state"] = state;
    b["street"] = street;
    b["number"] = number;
    b["floor"] = floor;
    b["country"] = country;
    b["postal_code"] = postal_code;

    addBank(b)
      .then((response: any) => {
        var data = response.data;
        setAdd(false);
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Successfully added a payment option");
          getPay();
        }
      })
      .catch((error: any) => {
        setAdd(false);
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  function editP() {
    if (
      first_name === "" ||
      last_name === "" ||
      phone === "" ||
      email === "" ||
      iban === "" ||
      account_number === "" ||
      city === "" ||
      state === "" ||
      street === "" ||
      // floor === "" ||
      postal_code === "" ||
      country === ""
    ) {
      toast.error("Enter all inputs!");
      return;
    }

    var b: any = {};
    b["first_name"] = first_name;
    b["last_name"] = last_name;
    b["phone_number"] = phone;
    b["email_address"] = email;
    b["iban"] = iban;
    b["account_number"] = account_number;
    b["city"] = city;
    b["state"] = state;
    b["street"] = street;
    b["building"] = building;
    b["number"] = number;
    b["floor"] = floor;
    b["country"] = country;
    b["postal_code"] = postal_code;

    // console.log(b); return;

    editB(b, `?bank_id=${editD?._id}`)
      .then((response: any) => {
        var data = response.data;
        setEditPayment(false);
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Successfully added a payment option");
          getPay();
        }
      })
      .catch((error: any) => {
        setEditPayment(false);
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }

  return (
    <div className="pay_body">
      {add && (
        <div className="pybody-info">
          <div className="pybody-infotop">
            <div className="pybitleft">
              <label>Add new payment option</label>
            </div>
            <div className="pybitright" onClick={() => setAdd(false)}>
              <label>Cancel</label>
            </div>
          </div>

          <div className="pybody-pi">
            <p>Personal information</p>
            <div className="pybody-pibody">
              <div className="pip_body-bottomitem">
                <label className="normal-case">First name <span className="mkred">*</span></label>
                <input
                  type="text"
                  placeholder="JOHN"
                  value={first_name}
                  onChange={(e: any) => set_first_name(e.target.value)}
                  className="pip_body-bottomitemedit"
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Last name <span className="mkred">*</span></label>
                <input
                  type="text"
                  placeholder="Doe"
                  value={last_name}
                  onChange={(e: any) => set_last_name(e.target.value)}
                  className="pip_body-bottomitemedit"
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Phone number <span className="mkred">*</span></label>
                <input
                  type="tel"
                  placeholder="+44 1234 5678 90"
                  value={phone}
                  onChange={(e: any) => set_phone(e.target.value)}
                  className="pip_body-bottomitemedit"
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Email address <span className="mkred">*</span></label>
                <input
                  type="email"
                  placeholder="johndoe@email.com"
                  className="pip_body-bottomitemedit"
                  value={email}
                  onChange={(e: any) => set_email(e.target.value)}
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Sort code/IBAN <span className="mkred">*</span></label>
                <input
                  type="number"
                  placeholder="123456"
                  value={iban}
                  onChange={(e: any) => set_iban(e.target.value)}
                  className="pip_body-bottomitemedit"
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Account number <span className="mkred">*</span></label>
                <input
                  type="text"
                  placeholder="1234567890"
                  value={account_number}
                  onChange={(e: any) =>
                    set_account_number(e.target.value)
                  }
                  className="pip_body-bottomitemedit"
                />
              </div>
            </div>
          </div>
          <div className="pybody-pi pybody-pib">
            <p>Bank address information</p>
            <div className="pybody-pibody">
              <div className="pip_body-bottomitem">
                <label>Street name <span className="mkred">*</span></label>
                <input
                  type="text"
                  placeholder="Street"
                  className="pip_body-bottomitemedit"
                  value={street}
                  onChange={(e: any) => set_street(e.target.value)}
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>House number <span className="mkred">*</span></label>
                <input
                  type="number"
                  placeholder="2"
                  className="pip_body-bottomitemedit"
                  value={number}
                  onChange={(e: any) => set_number(e.target.value)}
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Floor unit (optional)</label>
                <input
                  type="number"
                  placeholder="1"
                  className="pip_body-bottomitemedit"
                  value={floor}
                  onChange={(e: any) => set_floor(e.target.value)}
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Building Name (optional)</label>

                <input
                  type="text"
                  placeholder="Building Name"
                  className="pip_body-bottomitemedit"
                  value={building}
                  onChange={(e: any) => set_building(e.target.value)}
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>Postal code <span className="mkred">*</span></label>
                <input
                  placeholder="123456"
                  value={postal_code}
                  onChange={(e: any) => set_postal_code(e.target.value)}
                  className="pip_body-bottomitemedit"
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>City <span className="mkred">*</span></label>
                <input
                  type="text"
                  placeholder="ENGLISH"
                  value={city}
                  onChange={(e: any) => set_city(e.target.value)}
                  className="pip_body-bottomitemedit"
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>State / Province / Region <span className="mkred">*</span></label>
                <input
                  type="text"
                  placeholder="LONDON"
                  value={state}
                  onChange={(e: any) => set_state_(e.target.value)}
                  className="pip_body-bottomitemedit"
                />
              </div>
              <div className="pip_body-bottomitem">
                <label>
                  Country<span className="mkred">*</span>
                </label>
                <div className="payform">
                  <Selectable
                    defaultValue={country}
                    onChange={(e: any) => set_country(e)}
                    options={countryOptions}
                  />
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => add_Pay()} className="pybody-infobtn">
            SAVE CHANGES
          </button>
        </div>
      )}

      {bank &&
        bank.map((ele: any, i: number) => {
          return (
            <div key={i} className="pybody-info">
              <div className="pybody-infotop">
                <div className="pybitleft">
                  <div className="pybitleftcheck">
                    <img src={markIcon} alt="" />
                  </div>
                  <label>Payment option {i + 1}</label>
                </div>
                <div
                  className="pybitright"
                  onClick={() => {
                    setEditPayment(true);
                    set_ed(ele);
                  }}
                >
                  <div className="pybitrightedit">
                    <img src={editIcon} alt="" />
                  </div>
                  <label>EDIT PAYMENT INFORMATION</label>
                </div>
              </div>
              <div className="pybody-pi">
                <p>Personal information</p>
                <div className="pybody-pibody">
                  <div className="pip_body-bottomitem">
                    <label>
                      First name{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.first_name}</label>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="JOHN"
                        value={first_name}
                        onChange={(e: any) =>
                          set_first_name(e.target.value)
                        }
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Last name{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.last_name}</label>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="Doe"
                        value={last_name}
                        onChange={(e: any) =>
                          set_last_name(e.target.value)
                        }
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Phone number{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.phone_number}</label>
                      </div>
                    ) : (
                      <input
                        type="tel"
                        placeholder="+44 1234 5678 90"
                        value={phone}
                        onChange={(e: any) => set_phone(e.target.value)}
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Email address{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.email_address}</label>
                      </div>
                    ) : (
                      <input
                        type="email"
                        placeholder="johndoe@email.com"
                        className="pip_body-bottomitemedit"
                        value={email}
                        onChange={(e: any) => set_email(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Sort code/IBAN{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.iban}</label>
                      </div>
                    ) : (
                      <input
                        type="number"
                        placeholder="123456"
                        value={iban}
                        onChange={(e: any) => set_iban(e.target.value)}
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Account number{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.account_number}</label>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="1234567890"
                        value={account_number}
                        onChange={(e: any) =>
                          set_account_number(e.target.value)
                        }
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="pybody-pi pybody-pib">
                <p>Bank address information</p>
                <div className="pybody-pibody">
                  <div className="pip_body-bottomitem">
                    <label>
                      Street name{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.street}</label>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="Street"
                        className="pip_body-bottomitemedit"
                        value={street}
                        onChange={(e: any) => set_street(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      House number{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.number}</label>
                      </div>
                    ) : (
                      <input
                        type="number"
                        placeholder="2"
                        className="pip_body-bottomitemedit"
                        value={number}
                        onChange={(e: any) => set_number(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Building Name (optional)
                      {/* {editPayment === true && <span className="mkred">*</span>} */}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.building}</label>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="Building Name"
                        className="pip_body-bottomitemedit"
                        value={building}
                        onChange={(e: any) =>
                          set_building(e.target.value)
                        }
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Floor unit (optional)
                      {/* {editPayment === true && <span className="mkred">*</span>} */}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.floor}</label>
                      </div>
                    ) : (
                      <input
                        type="number"
                        placeholder="1"
                        className="pip_body-bottomitemedit"
                        value={floor}
                        onChange={(e: any) => set_floor(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Postal code{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.postal_code}</label>
                      </div>
                    ) : (
                      <input
                        placeholder="123456"
                        value={postal_code}
                        onChange={(e: any) =>
                          set_postal_code(e.target.value)
                        }
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      City{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.city}</label>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="ENGLISH"
                        value={city}
                        onChange={(e: any) => set_city(e.target.value)}
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      State / Province / Region{" "}
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.state}</label>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="LONDON"
                        value={state}
                        onChange={(e: any) => set_state_(e.target.value)}
                        className="pip_body-bottomitemedit"
                      />
                    )}
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Country
                      {editPayment === true && <span className="mkred">*</span>}
                    </label>
                    {!editPayment === true ? (
                      <div className="pip_body-bottomitemndit">
                        <label>{ele.country}</label>
                      </div>
                    ) : (
                      // <input
                      //   type="text"
                      //   placeholder="UNITED KINGDOM"
                      //   className="pip_body-bottomitemedit"
                      // />

                      <div className="payform">
                        <Selectable
                          defaultValue={country}
                          onChange={(e: any) => set_country(e)}
                          options={countryOptions}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {editPayment && (
                <button onClick={() => editP()} className="pybody-infobtn">
                  SAVE CHANGES
                </button>
              )}
            </div>
          );
        })}

      {bank && bank.length === 0 && (
        <button className="pay_bodybutton" onClick={() => setAdd(true)}>
          ADD NEW PAYMENT OPTIONS
        </button>
      )}
      {/* {!editPayment === true ? (
      <button className="pay_bodybutton" onClick={() => setAdd(true)}>ADD NEW PAYMENT OPTIONS</button>
    ) : (
      null
      // <button className="pay_bodybuttonn">ADD NEW PAYMENT OPTIONS</button>
    )} */}
    </div>
  );
}

export default Payment;
