import React, { useState } from 'react';
import { Layout, Modal } from 'antd';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './styles.scss';
import TopHeader from './TopHeader';
import Crumbs from './Crumbs';
import { CreatorVerification } from 'components/Icons';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { editUserData } from '../../app/modules/auth/redux/AuthCRUD';
import { toast } from 'react-toastify';
import * as auth from '../../app/modules/auth/redux/AuthRedux';

const { Content } = Layout;
const SidebarLayout = (props: any) => {
  const dispatch = useDispatch();
  const { user } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user
    }),
    shallowEqual
  );

  // console.log(user)

  function chan() {
    var body: any = {};
    body['booked_session'] = true;
    
    editUserData(body)
    .then((response: any) => {
    var data = response.data;
    if (data.error) {
        toast.error(data.error);
    } else {
      dispatch(auth.actions.fulfillToken(data.user_token));
      dispatch(auth.actions.fulfillUser(data));
    }
    })
    .catch((error: any) => {
    var message = error.response.data ? error.response.data.message : 'Network error occured!';
    toast.error(message ? message : 'Network error occured!');
    });
  }

  const [modalState, toggleModalState] = useState(user?.booked_session);
  const { headerIcon, headerTitle, children, crumbs } = props;
  const Icc = headerIcon;
  return (
    <Layout>
      <Sidebar />
      <Layout className="layout-container">
        <TopHeader Icon={headerIcon} title={headerTitle} />
        <Content className="content-container">
          <Crumbs crumbs={crumbs} icon={<Icc />} />
          {children}
        </Content>
        <Footer />
      </Layout>
      {/* <Modal
        centered
        open={!modalState}
        onOk={() => {
          toggleModalState(false);
          chan();
        }}
        onCancel={() => {
          toggleModalState(false);
          chan();
        }}
        footer={null}
        title={null}
      >
        <Link to = '/community'>
          <img src={require('assets/images/session.png')} alt="@session" />
        </Link>
      </Modal> */}
    </Layout >
  );
};

export default SidebarLayout;