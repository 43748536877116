import {
  PartnerAnalytics,
  ProductCatalogue,
  ContentHub,
  CommunityHub,
} from "components/Icons";

export const menuItems = [
  { icon: ProductCatalogue, label: "Product Catalogue", to: "/products" },
  { icon: PartnerAnalytics, label: "Analytics Dashboard", to: "/analytics" },
  { icon: ContentHub, label: "Content Hub", to: "/content" },
  { icon: CommunityHub, label: "Community Hub", to: "/community" },
  // { icon: CommunityHub, label: "Payout Requests", to: "/payouts" },
];

export const mobileMenu = [
  { icon: ProductCatalogue, label: "Product Catalogue", to: "/products" },
  { icon: PartnerAnalytics, label: "Analytics Dashboard", to: "/analytics" },
  { icon: ContentHub, label: "Content Hub", to: "/content" },
  { icon: CommunityHub, label: "Community Hub", to: "/community" },
  { icon: CommunityHub, label: "Logout", to: "/logout" },
];
