import { useState, useEffect, useRef } from "react";
import { Steps, Dropdown, message, Popover, notification, Space, Button as Butt } from "antd";
import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SidebarLayout } from "components/layout";
import { ProductCatalogue } from "components/Icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import { Button } from "components/forms";
import {
  getSingleBrand,
  fetchAllData,
  reset,
  addData,
  fetchTierData,
} from "../_redux/products/Actions";
import bannerBg from "assets/images/banner-placeholder.png";
import type { NotificationPlacement } from 'antd/es/notification/interface';

var items: any = [];

const SALES_TEXT = "Average of sales is calculated across a 3 months period";
const settings = {
  customPaging: (i: any) => {
    return (
      <a className="paging">
        <img src={require(`assets/images/paging${i}.png`)} />
      </a>
    );
  },
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={require("assets/images/next-icon.png")} alt="" />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={require("assets/images/prev-icon.png")} alt="" />
    </div>
  );
};


export default function BrandDetails() {
  const dispatch = useDispatch<any>();
  const params = useParams();

  const brandId: any = params?.id;
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const buttonRef = useRef<any>(null);
  const [selectedCategory, setCategory] = useState("All");
  const navigate = useNavigate();
  const [parsedDesc, setParsedDesc] = useState<any>("");
  const [cced, setCCed] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  // const [messageApi, contextHolder] = message.useMessage();
  const [vg_bg, setbannerVg] = useState<any>(null);

  const [bannerBgImage, setbannerBgImage] = useState<any>(bannerBg);
  const [commissionSteps, set_commissionSteps] = useState<any>([
    {
      title: "Start off base commission ",
    },
    {
      title: "After first 30 units sold",
    },
    {
      title: "After $100k in sales/month",
    },
    {
      title: "After $200k in sales/month",
    },
  ]);

  const [t1_data, set_t1_data] = useState<any>(null);
  const [t2_data, set_t2_data] = useState<any>(null);
  const [t3_data, set_t3_data] = useState<any>(null);
  const [t4_data, set_t4_data] = useState<any>(null);
  const [gen_code, set_gen_code] = useState<any>('');

  const [available_categories, set_available] = useState<any>([]);
  const [sliderKey, setSliderKey] = useState(Date.now());
  const [ modal, setModal] = useState(false)
  const [code, setCode] = useState<string>('')
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { categories, products, brandDetails, commission, brandCommissionTierData } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      categories: state.products.categories,
      products: state.products.products,
      listLoading: state.products.listLoading,
      commission: state.products.commission,
      brandDetails: state.products.brandDetails,
      brandCommissionTierData: state.products.brandCommissionTierData,
    }),
    shallowEqual
  );

  const productSettings = {
    dots: false,
    infinite: false,
    key: sliderKey,
    count: products?.length,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const crumbs = [
    { label: "/ brands", to: "/products" },
    { label: `brands: ${brandDetails?.name}`, to: `/brand/${brandId}` },
  ];

  useEffect(() => {
    if (brandId) {
      dispatch(getSingleBrand(brandId));
      dispatch(fetchAllData("products", `?brand_id=${brandId}&all=true`));
      dispatch(fetchAllData("commission", `?brand_id=${brandId}&all=true`));
      dispatch(fetchAllData("categories", `?brand_id=${brandId}&all=true`));
      dispatch(fetchTierData(brandId));
    } else {
      dispatch(reset());
    }
  }, []);

  useEffect(() => {
    var d: any = [];

    var dd: any = {};
    dd["label"] = "All";
    dd["title"] = "All";
    dd["key"] = "";
    d.push(dd);

    categories &&
      categories.forEach((element: any, index: number) => {
        var dd: any = {};
        dd["label"] = element.name;
        dd["title"] = element.name;
        dd["key"] = element._id;
        d.push(dd);
      });
    items = d;
  }, [categories]);

  useEffect(() => {
    if (commission && commission.length > 0) {
      var t1 = commission.find((x: any) => x.name === "t1");
      var t2 = commission.find((x: any) => x.name === "t2");
      var t3 = commission.find((x: any) => x.name === "t3");
      var t4 = commission.find((x: any) => x.name === "t4");

      var dd = [
        {
          title: t1?.description || 'Start off base commission'
        },
        {
          title: getDesc(t2),//t2?.description,
        },
        {
          title: getDesc(t3)
        },
        {
          title: getDesc(t4)
        },
      ];
      set_commissionSteps(dd);

      set_t1_data(t1);
      set_t2_data(t2);
      set_t3_data(t3);
      set_t4_data(t4);
    } else {
      set_t1_data(null);
      set_t2_data(null);
      set_t3_data(null);
      set_t4_data(null);
    }
  }, [commission]);

  useEffect(() => {
    if (products && products.length > 0) {
      var a: any = [];
      products.forEach((element: any) => {
        a.push(...element.categories);
      });
      let uniqueItems = [...new Set(a)];
      set_available(uniqueItems);
    } else {
      set_available([]);
    }
  }, [products]);

  useEffect(() => {
    if (brandDetails) {
      if (brandDetails.video) {
        setbannerVg(`${brandDetails.video}`)
        setbannerBgImage(null)
      } else if (brandDetails.banner || brandDetails.logo) {
        setbannerBgImage(`${brandDetails.banner || brandDetails.logo}`)
        setbannerVg(null)
      } 

      if (brandDetails?.description) {
        const parser = JSON.parse(brandDetails.description);
        setParsedDesc(parser);
      }
    }
  }, [brandDetails]);

  function getDesc(data: any) {
    var dur = data?.duration === 'per_month' ? 'per month' : data?.duration === 'per_day' ? 'per day' : data?.duration;
    if (data?.mode === 'unit_sold') {
      return (
        <span>
        {`Avg. of ${data?.average} units sold/${dur}`}
          <Popover
          content={<div className="pop-sub" dangerouslySetInnerHTML={{ __html: SALES_TEXT }} />}
          title={''} trigger="click">  {' '}<InfoCircleOutlined />
        </Popover>
        </span>
      )
      
    }
    // return `Avg. of £${data?.average} in ${data?.mode}/${dur}`;
    return (
      <span>
      {`Avg. of £${data?.average} in ${data?.mode}/${dur}`}
        <Popover
        content={<div className="pop-sub" dangerouslySetInnerHTML={{ __html: SALES_TEXT }} />}
        title={''} trigger="click">  {' '}<InfoCircleOutlined />
      </Popover>
      </span>
    )
  }

  const handleMenuClick = (el: any) => {
    setCategory(el?.item?.props?.title);
    if (el.key) {
      dispatch(fetchAllData("products", `?brand_id=${brandId}&category=${el.key}&all=true`));
    } else {
      dispatch(fetchAllData("products", `?brand_id=${brandId}&all=true`));
    }
    setSliderKey(Date.now());
  };

  const limitStr = (str: string, length: number) => {
    const spl = str.split("");
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join("");
    }
    return newStr;
  };


  const copyValue = (d: string) => {
    navigator.clipboard.writeText(d)
  };


  const [api, contextHolder] = notification.useNotification();

  function tracking(id: any, type = 'link') {
    if (type === 'link') {
      dispatch(fetchAllData("tracking", `?product_id=${id}`))
      .then((da: any) => {
        setCCed(da)
        openNotification('bottomRight', 'tracking', da);
      });
    } else {
      var bod: any = {};
      bod['brand_id'] = brandId;
      dispatch(addData(bod, "discount_code")).then((da: any) => {
        setCCed(da)
        openNotification('bottomRight', 'code', da.success);
      });
    }
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const calculateEarn = (price: number) => {
    let earn = 0;
    let dis = 20;
    if (brandDetails?.discount) {
      dis = brandDetails?.discount;
    }
    if (price && typeof price == 'number') {

      var real_p = price - (dis/100 * price);

      earn = brandCommissionTierData?.commission / 100 * real_p;
    }
    return earn.toFixed(2);
  }


  const close = () => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
  };
  
 

  const openNotification = (placement: NotificationPlacement, type: string, data: string, msg = null) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Butt type="link" size="small" onClick={() => {
          copyValue(data);
          api.destroy();
        }}>
          COPY LINK!
        </Butt>
      </Space>
    );
    api.open({
      message: 'Copy to clipboard!',
      description:
      msg || `Click the confirm button to copy ${data} to your clipboard!`,
      placement,
      btn,
      key,
      onClose: close,
    });
  };
  const handleGenerate = () => {
    
    if (inputRef.current) {
      // setCode('ccc' + inputRef.current.value);
      // console.log(code)
      var bod: any = {};
      bod['brand_id'] = brandId;
      bod['code'] = inputRef.current.value
      dispatch(addData(bod, "discount_code")).then((da: any) => {
        openNotification('bottomRight', 'code', da.success, da?.msg || null );
      });
    }
  }
  
  const toggleModal = () => {
    console.log('open')
    setModal(!modal)
  }
  
  const renderModal = () => {
    return (
      <>
      <div className="modal-container">
        <button className="close-btn" onClick={toggleModal} style={{float: 'right'}}>
        <img src={require("assets/images/close.png")} alt=""  style={{width: '15px'}}/>
        </button>
        <p className="modal-header">Customise Discount Code</p>
        <p className="input-label">Generate Coupon Code</p>
        <div className="input-group">
          <span className="input-prefix">CCC</span>
          <input className="modal-input" defaultValue = {gen_code} placeholder='' ref={inputRef}/>
        </div>
        <button className='modal-button' onClick={handleGenerate}>GENERATE CODE</button>
      </div>
      </>
    )
  }
  const renderSlider = () => {
    return (
      <>
        {
          available_categories.map((catt: any, i: string) => {
            return (
              <div>
             
                <h2 className="section-sub-title">{categories && categories.length > 0 ? categories.find((x: any) => x._id === catt)?.name || '' : ''}</h2>
                  
                <Slider {...productSettings} className="products-slider">
                  {products &&
                    products.map(
                      (ele: any, index: number) =>
                        ele.categories.includes(catt) && (
                          <div className="product-item" key={index}>
                              <img src={ele.thumbnail} alt="*" />
                              <div className="info">
                                <Link
                                  to={`/product/${ele._id}`}
                                  className="name"
                                >
                                  {`${limitStr(ele.name, 22)}...`}
                                  <Popover
                                    content={<div className="pop-sub" dangerouslySetInnerHTML={{ __html: ele.description }} />}
                                    title={ele?.name} trigger="click">  {' '}<InfoCircleOutlined />
                                  </Popover>
                                </Link>
                                <div className="description" dangerouslySetInnerHTML={{ __html: limitStr(ele.description, 37)+'...' }} />
                                <span className="price">
                                  Price to Consumer:{" "}
                                  <span>{`£${ele.price}`}</span>
                                </span>
                                <span className="earn">
                                  Earn: £{calculateEarn(ele.price)}
                                </span>
                              </div>
                              <div className="actions">
                                <Button
                                  variant="primary"
                                  // onClick={toggleModal}
                                  onClick = {() => {
                                    dispatch(fetchAllData("gen_code"))
                                    .then((da: any) => {
                                      set_gen_code(da.success.substring(3))
                                      toggleModal();
                                    });
                                  }}
                                  label="GENERATE CODE"
                                /> 
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    tracking(ele._id);
                                  }}
                                  label="get tracking link"
                                />
                                <a target="_blank" href={ele?.orderUrl || 'javascript:;'}>
                                  <Button variant="primary"
                                    label="order" />
                                </a>
                                <Button
                                  variant="primary"
                                  label="explore products and tutorials"
                                  onClick={() =>
                                    navigate(`/product/${ele._id}`)
                                  }
                                />


                                {/* <a
                                  ref={buttonRef}
                                  className = "UFIInputContainer"
                                  onClick={() =>
                                    copyValue()
                                  }
                                >sdcsdcsdcs</a> */}
                              </div>
                            </div>
                        )
                    )}
                </Slider>
              </div>
            );
          })
        }
      </>
    )
  }

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {contextHolder}
      {modal ? <div className="modal">{renderModal()}</div> : ''}
          
      <div className="brand-details-container">
        <div
          className="banner"
          // style={{ backgroundImage: `url(${bannerBgImage})` }}
        >
          
          {bannerBgImage && !vg_bg && (
            <div
              className="banner-image"
              style={{ backgroundImage: `url(${bannerBgImage})`,
              // backgroundColor: 'red'
            
            }}
            ></div>
          )}
          {vg_bg && (
            <video className="banner-video" autoPlay loop muted>
              <source src={vg_bg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}


        </div>

        {brandDetails && (
          <section className="brand-info">
            <h2 className="section-title">
              {brandDetails?.header_description || brandDetails?.name}
            </h2>
            {/* <p className="description">{brandDetails.description}</p> */}
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: parsedDesc }}
            />
          </section>
        )}
        {windowSize?.current[0] >= 768
          ? <section className="commission">
            <h2 className="section-title">
              Commission scheme across all portfolio
            </h2>
            <div className="process">
              <div className="steppers">
                <div className="each">
                  <span className="rate">
                    {t1_data ? `${t1_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                </div>
                <div className="each">
                  <span className="rate">
                    {t2_data ? `${t2_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                </div>
                <div className="each">
                  <span className="rate">
                    {t3_data ? `${t3_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                </div>
                <div className="each">
                  <span className="rate">
                    {t4_data ? `${t4_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                </div>
                <div className="each">
                  <span className="rate">
                    {`${brandDetails?.discount || 0}%`}
                  </span>
                  <span>Promo Discount</span>
                  <span className="extra-info">*Promo Discount applies to all tiers</span>
                </div>
              </div>
              <Steps
                labelPlacement="vertical"
                items={commissionSteps}
                current={brandCommissionTierData?.tier - 1}
                status={brandCommissionTierData?.tier - 1 === 0 ? "process" : brandCommissionTierData?.tier - 1 > 0 ? "wait" : "process"}
              />
              <p className="note" style = {{marginTop: 50}}>*The Commissions scheme is subject to change during the UK pilot period</p>
              <p className="note" style = {{marginTop: 10}}>*Commission Scheme Across all Portfolio</p>
            </div>
          </section>
          : null}
        {windowSize?.current[0] < 768
          ? <section className="commission mobile-tier">
            <div className="process">
              <div className="steppers">
                <div className="each">
                  <span className="rate">
                    {t1_data ? `${t1_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                  <div className="coms">
                    <span className={brandCommissionTierData?.tier === 1 ? "index active" : "index"}>1</span>
                    <div className="text">{commissionSteps && commissionSteps.length > 0 ? commissionSteps[0]?.title : 'N/A'}</div>
                  </div>
                </div>
                <div className="each">
                  <span className="rate">
                    {t2_data ? `${t2_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                  <div className="coms">
                    <span className={brandCommissionTierData?.tier === 2 ? "index active" : "index"}>2</span>
                    <div className="text">{commissionSteps && commissionSteps.length > 0 ? commissionSteps[1]?.title : 'N/A'}</div>
                  </div>
                </div>
                <div className="each">
                  <span className="rate">
                    {t3_data ? `${t3_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                  <div className="coms">
                    <span className={brandCommissionTierData?.tier === 3 ? "index active" : "index"}>3</span>
                    <div className="text">{commissionSteps && commissionSteps.length > 0 ? commissionSteps[2]?.title : 'N/A'}</div>
                  </div>
                </div>
                <div className="each">
                  <span className="rate">
                    {t4_data ? `${t4_data?.commission}%` : "0%"}
                  </span>
                  <span>Commission per sale</span>
                  <div className="coms">
                    <span className={brandCommissionTierData?.tier === 4 ? "index active" : "index"}>4</span>
                    <div className="text">{commissionSteps && commissionSteps.length > 0 ? commissionSteps[3]?.title : 'N/A'}
                    </div>
                  </div>
                </div>
                <div className="each">
                  <span className="rate">
                    {`${brandDetails?.discount || 0}%`} 
                  </span>
                  <span>Promo Discount</span>
                  <span className="extra-info">*Promo Discount applies to all tiers</span>
                </div>
              </div>
              {/* <Steps
              labelPlacement="vertical"
              items={commissionSteps}
              current={brandCommissionTierData?.tier - 1}
              status={brandCommissionTierData?.tier - 1 === 0 ? "process" : brandCommissionTierData?.tier - 1 > 0 ? "wait" : "process"}
            /> */}
                <p className="note" style = {{marginTop: 50}}>*The Commissions scheme is subject to change during the UK pilot period</p>
                <p className="note">*Commission Scheme Across all Portfolio</p>
            </div>
          </section>
          : null}
        <section className="brand-listings">
          <div className="title-section">
            <h2 className="ttle">Products</h2>
            <div className="dorpdown">
              <span>FILTER: PRODUCTS</span>
              <Dropdown menu={menuProps} trigger={["click"]}>
                <div className="user">
                  <span className="labele">{selectedCategory}</span>
                  <DownOutlined color="#370E78" />
                </div>
              </Dropdown>
            </div>
          </div>

          <div className="product-listing">{renderSlider()}</div>
        </section>
      </div>
    </SidebarLayout>
  );
}
