import { Checkbox } from 'antd';
import AuthContainer from 'components/layout/AuthContainer'
import { Button, Field } from 'components/forms';
import { useNavigate, Link } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import config from 'app/global/config';
interface dataProps {
  edit?: string;
}

export default function Email() {
  const navigate = useNavigate();


  const prop = {
    title: 'Password instructions sent to your email-address',
    subTitle: `Kindly log into your email and follow the instructions provided to successfully change your password`,
  }

  return (
    <AuthContainer {...prop}>
      <div className="welcome-pack auth-form">
        <div className="actions mb-4">
          <a href={config.landing}>
            <Button
              variant="secondary" label="BACK TO HOMEPAGE" />
          </a>
        </div>
        <Button onClick={() => navigate("/login")} variant="plain" label="Back to login" />
      </div>
    </AuthContainer>
  )
}
