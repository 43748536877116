import * as requestFromServer from "./Crud";
import { ProductsSlice, callTypes } from "./Slice";
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';


const { actions } = ProductsSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'brands') {
        dispatch(actions.brandsList(res.success));
        return;
      } else if (type === 'categories') {
        dispatch(actions.catList(res.success));
        return;
      } else if (type === 'products') {
        dispatch(actions.productList(res.success));
      } else if (type === 'commission') {
        dispatch(actions.comList(res.success));
      } else if (type === 'tracking') {
        if (res.success) {
          var cp = `${res.success}`;
          return cp;
        } else {
          toast.error(res.error);
        }
      }
      return res;
    })
    .catch(error => {
      // console.log(error);
      error.clientMessage = error?.response?.data?.error || "Can't find Datas";
      toast.error(error.clientMessage);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTierData = (brandId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTierData(brandId)
    .then(response => {
      let res = response.data;
      dispatch(actions.brandCommissionTierData(response.data));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch files";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProductMediaAll = (queries) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductFilesAll(queries)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch files";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const addClick = (payload, type = 'book') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addClick(payload, type)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Cannot create a product at the moment";
      toast.error(error?.response?.data?.error?.response?.errormessage || error?.response?.data?.error?.response?.returnvalue?.message || 'Error creating discount!');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = (payload, type = 'book') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addData(payload, type)
    .then(response => {
      let res = response.data;
      if (res.success) {
        var cp = `${res.success}`;
        if (type === 'event') {
          toast.info(cp);
        }
        if (type === 'discount_code') {
          return res;
        }
        return cp;
      } else {
        toast.error(res.error);
      }

      // return res;
    })
    .catch(error => {
      error.clientMessage = "cannot generate code at this time";
      toast.error(error?.response?.data?.error?.response?.errormessage || error?.response?.data?.error?.response?.returnvalue?.message || 'Error copying to clipboard!');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleBrand = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchBrandDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.brandDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleProduct = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchProductDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.productDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reset = () => dispatch => {
  dispatch(actions.reset());
};

