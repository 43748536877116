import { CreatorVerification } from "components/Icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentHubLayout from "components/layout/ContentHubLayout";
import search from "../../../../assets/Svg/searchicon.svg";
import { Button, Field } from "components/forms";
import btnplay from "../../../../assets/Svg/btnplay.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAllData,
  getContentsAll,
} from "../_redux/content/Actions";

import "./styles.scss";
import { ProgressiveImage } from "components/shared";
import ContentDetails from "./ContentDetails";
// import Modal from 'react-modal';
import { Modal, message, Radio } from 'antd';
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player/vimeo'
import ReactMarkdown from 'react-markdown';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function ContentHub() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [video, set_video_url] = useState(null);
  const [q, setQ] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [isVideo, set_Video] = useState(true);
  const [tab, setTab] = useState('sessions');

  const limitStr = (str: string, length: number) => {
    const spl = str.split("");
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join("") + "...";
    }
    return newStr;
  };

  const { loading, contentList } = useSelector(
    (state: any) => ({
      loading: state.analytics.listLoading,
      contentList: state.content.contentList,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getContentsAll());
    // dispatch(fetchAllData('contents', ''))
  }, [])




  const initSearch = () => {
    if (q !== '') {
      dispatch(getContentsAll('search', `?q=${q}`));
    } else {
      dispatch(getContentsAll());
    }
  }




  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // console.log("submited!!!!")
    e.preventDefault()
    initSearch();
  }


  function removeMarkdownAndHTML(text: string) {
    // Remove HTML tags
    const withoutHTML = text.replace(/<[^>]+>/g, '');

    // Remove Markdown headings
    const withoutHeadings = withoutHTML.replace(/^#+\s+(.*)$/gm, '$1');

    // Remove Markdown formatting
    const withoutMarkdown = withoutHeadings.replace(/([*_~`]|~~)(.*?)\1/g, '$2');

    return withoutMarkdown;
  }


  return (
    <ContentHubLayout
      headerTitle="CONTENT HUB"
      headerIcon={CreatorVerification}
    >{contextHolder}

      <div className="contenthub_body">
        <div className="contenthub-top">
          <label>Getting you started</label>
        </div>
        <div className="">
          {/* <p className="eclabel">Search</p> */}
          {/* <Field placeholder="How to discover products " type="text" label="Search" onChange={(e) => setQ(e.target.value)} showCount onPressEnter={initSearch} /> */}

          <form className="ecinput_cnt" action="#" onSubmit={handleSubmit}>
            <img src={search} alt="" />
            <input type="text" placeholder="e.g How to discover products" onChange={(e) => setQ(e.target.value)} />
            <label>{q.length}/20</label>
          </form>
        </div>
        <div className="contenthublist">

          {contentList && contentList.length > 0
            ? contentList.map((el: any, index: number) => (
              // console.log(el)
              <div className="contenthublistitem" key={index}>
                {/* <div
                  style={{ backgroundImage: `url(${el.banner})` }}
                  className="cthlitop"
                >
                  <img src={btnplay} alt="" className="cthlitopimg2" />
                </div> */}
                <div className="cthlitop">
                  <ProgressiveImage
                    imgSrc={el.thumbnail}
                    previewSrc={""}
                    width="100%"
                    height="75%"
                    style={{ objectFit: 'cover', objectPosition: 'center', height: '230px' }}
                  />
                  <div className="overlay-feature"></div>
                  {el.video_url &&
                    <img src={btnplay} onClick={() => {
                      set_Video(true)
                      set_video_url(el.video_url)
                      setIsOpen(true)
                    }} alt="" className="cthlitopimg2" />}
                </div>
                <h3 className="cthlititle">{el.title}</h3>
                <div className="cthlisubtitle" dangerouslySetInnerHTML={{ __html: removeMarkdownAndHTML(limitStr(el.body, 80)) }}/>
                {/* <ReactMarkdown className="cthlisubtitle">{limitStr(el.body, 80)}</ReactMarkdown> */}
                <button className="cthlibtn" onClick={() => navigate(`/content/post/${el._id}`)}>
                  WATCH OR READ
                </button>
              </div>
            ))
            : ''
          }
        </div>
      </div>




      <Modal
        centered
        open={modalIsOpen}
        onOk={() => {
          set_Video(false);
          setTimeout(() => {
            setIsOpen(false)
          }, 100);
        }}
        onCancel={() => {
          set_Video(false);
          setTimeout(() => {
            setIsOpen(false)
          }, 100);
        }}
        footer={null}
        title={null}
      >
        {video &&
          <div className="video" style={{ width: 'auto', height: 'auto', minHeight: '340px', position: 'relative' }}>
            <ReactPlayer
              url={video}
              style={{ position: 'absolute', top: 0, left: 0 }}
              playing={isVideo}
              width="100%"
              height="100%"
              controls={true}
              autoplay={true}
            />
          </div>}
      </Modal>

    </ContentHubLayout>
  );
}

export default ContentHub;