import React from "react";
import { Navigate, Route, Routes, Link } from 'react-router-dom'
import Login from "./auth/Login";
import { Verification } from "./verification";
import Register from "./auth/Register";


export function AuthPage() {


  return (
    <Routes>
        <Route path="/" element={<Login />} /> 
        <Route path="/register" element={<Register />} /> 
        {/* <Route path="*" element={<p>Path not resolved</p>} /> */}
        {/* <Route path="/login" element={<Login />} />  */}
        {/* <Route path='/auth/login' element={<Login/>} /> */}
        {/* <Navigate from='/auth' exact={true} to='/auth/login' /> */}
        {/* <Navigate to='/auth/login' /> */}
        <Route path="/" element={<Navigate to="/auth/login" />}/>
   
    </Routes>
  )
}


