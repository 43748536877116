export const footerLinks = [
  {
    linkaddress: "about",
    name: "ABOUT US",
  },
  {
    linkaddress: "faq",
    name: "FAQ",
  },
  {
    linkaddress: "terms-of-use",
    name: "TERMS OF USE",
  },
  {
    linkaddress: "privacy-notice",
    name: "PRIVACY POLICY",
  },
  {
    linkaddress: "info@cleancreatorcommunity.com",
    name: "CONTACT US",
  },
  {
    
  }
];