import { Layout } from "antd";
import { Footer } from "antd/es/layout/layout";
import { CreatorVerification } from "components/Icons";
import Crumbs from "./Crumbs";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
const { Content } = Layout;
const crumbs = [{ label: "/ GETTING YOU STARTED", to: "/" }];
const ContentHubLayout = (props: any) => {
  const { headerIcon, headerTitle, children } = props;
  return (
    <Layout>
      <Sidebar />
      <Layout className="layout-container">
        <TopHeader Icon={headerIcon} title={headerTitle} />
        <Content className="content-container">
          <Crumbs crumbs={crumbs} icon={<CreatorVerification />} />
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default ContentHubLayout;
