import { useEffect, useState } from 'react';


interface ProgressiveImgProps {
  imgSrc: string | any;
  previewSrc: string;
  width: string;
  height?: string;
  style: any;
  spotlight?: boolean;
}
export default function PregressiveImage({ imgSrc, previewSrc, width, height, style, spotlight }: ProgressiveImgProps) {
  if (!imgSrc) {
    imgSrc = require('assets/images/holder.png')
  }
  const [usedSrc, setUsedSrc] = useState(previewSrc);
  const [usedEffectStyle, setUsedEffectStyle] = useState<any>({ filter: 'blur(10px)', clipPath: 'inset(0)' });

  useEffect(() => {
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      setUsedSrc(img.src);
      setUsedEffectStyle({});
    }
  }, []);

  return <img
    className={`banner-in ${spotlight && "spotty"}`}
    src={usedSrc}
    width={width}
    height={height}
    style={{ transition: 'filter 0.6s linear', ...usedEffectStyle, ...style }} />;

}