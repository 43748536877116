import { Checkbox } from 'antd';
import AuthContainer from 'components/layout/AuthContainer'
import { Button, Field } from 'components/forms';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import config from 'app/global/config';

export default function Pending() {
  const navigate = useNavigate();

  const { email } = useSelector(
    (state: any) => ({
        email: state.auth.email,
    }),
    shallowEqual
);

  const prop = {
    title: 'Your application is still under review',
    subTitle: `Our team will foward a mail to ${email} within 24-48 hours after reviewing your submission.`,
  }

  return (
    <AuthContainer {...prop}>
      <div className="welcome-pack auth-form">
        <div className="actions mb-4">
        <a href={config.landing}>
            <Button 
            variant="secondary" label="BACK TO HOMEPAGE" />
        </a>
        </div>
        {/* <Button onClick={() => navigate("/register")} variant="plain" label="Not your email?" /> */}
      </div>
    </AuthContainer>
  )
}