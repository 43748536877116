import * as requestFromServer from "./Crud";
import { CommunitySlice, callTypes } from "./Slice";
import { toast } from 'react-toastify';

const { actions } = CommunitySlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'bookings') {
        dispatch(actions.booksList(res.success));
        return;
      } else if (type === 'workspaces') {
        dispatch(actions.workspaceList(res.success));
        return;
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const create = (payload, type = 'book') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createData(payload, type)
    .then(response => {
      let res = response.data;
      if (type === 'attend_event') {
        toast.success(`You've successfully indicated to attend this event!`);
        dispatch(actions.DatasStatusUpdated({ id: res.success._id, attendees: res.success.attendees, capacity_remaining: res.success.capacity_remaining }));
      } else if (type === 'cancel_event') {
        toast.info(`You will no longer be attending this event`);
        dispatch(actions.DatasStatusUpdated({ id: res.success._id, attendees: res.success.attendees, capacity_remaining: res.success.capacity_remaining }));
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Cannot create a product at the moment";
      toast.error(error?.response?.data?.error?.response?.errormessage || error?.response?.data?.error?.response?.returnvalue?.message || 'Error creating booking!');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const bookEvent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .bookAnEvent(id)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Cannot create an event at the moment";
      toast.error('An error occured. Please retry later!');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const cancelEvent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .cancelAnEvent(id)
    .then(response => {
      console.log('REEESESESESE: ', response);
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Cannot cancel an event at the moment";
      toast.error('An error occured. Please retry later!');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const getEventsAll = (type = 'event', q = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchEventAll(type, q)
    .then(response => {
      let res = response.data;
      const sucres = [...res.success];
      if (sucres.length > 0) {
        dispatch(actions.eventsAll(sucres.reverse()));
      } else {
        dispatch(actions.eventsAll(sucres));
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const clearEventDetails = () => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.clearEventData());
}
export const deleteEvent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSingleEvent(id)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to delete brand";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reset = () => dispatch => {
  dispatch(actions.reset());
};

export const setEvent = (data) => dispatch => {
  dispatch(actions.eventsAll(data));
}