import { useEffect, useState } from "react";
import { Radio, RadioChangeEvent, Popover, Form, Select } from "antd";
import { useNavigate } from "react-router-dom";
import AuthContainer from "components/layout/AuthContainer";
import {
  Field,
  Button,
  RadioButton,
  Selectable,
  MultiSelectField,
} from "components/forms";
import * as auth_actions from "../../redux/AuthRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { editUserData, getUserByToken } from "../../redux/AuthCRUD";
import { toast } from "react-toastify";
import MoreIcon from "assets/Svg/more-info.svg";
import { Checkbox } from "antd";
import "./styles.scss";
import "./zfixin.scss";

const { Option } = Select;

function isJson(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}


export default function About() {
  const { user, more } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user,
      more: state.auth.more
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(user?.affiliate || "yes");
  const [programs, set_programs] = useState(user?.programs || []);
  const [products, set_products] = useState(isJson(user?.content_type) ? JSON.parse(user?.products_to_promote || '[]') : []);
  const [commission, set_com] = useState(user?.average_commission || "");
  const [content_type, set_content_type] = useState(isJson(user?.content_type) ? JSON.parse(user?.content_type || '[]') : []);
  const [loading, setLoading] = useState(false)

  const [consent, setConsent] = useState(false);
  const [currency, set_currency] = useState(user?.currency || 'usd');

  const [pumpFake, setPumpfake] = useState(1);
  const [fielders, setFielders] = useState(user?.programs.length > 0 ? user?.programs : [1]);
  const [fieldersPromote, setFieldersPromote] = useState(
    user?.products_to_promote || [1]
  );

  // console.log('content_type: ', content_type);

  useEffect(() => {
    if (!user) {
      navigate("/register");
    }
  }, []);

  const prop = {
    title: "Let's get started",
    titleB: "About you",
    subTitle: "You're 1 step away from earning $1000's in commissions.",
    steps: true,
  };

  const contentOptions = [
    {
      value: "Beauty",
      label: "Beauty",
    },
    {
      value: "Comedy",
      label: "Comedy",
    },
    {
      value: "Fashion",
      label: "Fashion",
    },
    {
      value: "Fitness",
      label: "Fitness",
    },
    {
      value: "Food",
      label: "Food",
    },
    {
      value: "Haircare",
      label: "Haircare",
    },
    {
      value: "Lifestyle",
      label: "Lifestyle",
    },
    {
      value: "Travel",
      label: "Travel",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const productOptions = [
    {
      value: "Beauty",
      label: "Beauty",
    },
    {
      value: "Fashion",
      label: "Fashion",
    },
    {
      value: "Haircare",
      label: "Haircare",
    },
    {
      value: "Lifestyle",
      label: "Lifestyle",
    },
    {
      value: "Skincare",
      label: "Skincare",
    },

    {
      value: "Fitness",
      label: "Fitness",
    },
    {
      value: "Technology",
      label: "Technology",
    },
    {
      value: "Entertainment",
      label: "Entertainment",
    },
    {
      value: "Vegan Products",
      label: "Vegan Products",
    },
    {
      value: "Makeup",
      label: "Makeup",
    },
    {
      value: "Health",
      label: "Health",
    },
    {
      value: "Wellness",
      label: "Wellness",
    },
    {
      value: "Sustainable",
      label: "Sustainable",
    },
  ];

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    renderFields();
  }, [fielders]);

  const addNewField = () => {
    const cFields = fielders;
    cFields.push(fielders.length + 1);
    setPumpfake(pumpFake + 1);
    setFielders(cFields);
  };

  const removeField = (index: any) => {
    if (fielders && fielders.length > 1) {
      const cFields = fielders;
      cFields.splice(index, 1);
      setPumpfake(pumpFake + 1);
      setFielders(cFields);
    }
  };

  const renderFields = () => {
    return fielders.map((_: any, index: number) => (
      <div key={index} className="removable-fd">
        <Field
          type="text"
          required
          showCount
          maxLength={20}
          className="plusCount"
          placeholder={"e.g. LTK"}
          defaultValue={programs[index]}
          onChange={(e: any) => set_pr(e.target.value.trim(), index)}
        />
        {/* {index === fielders.length - 1 && */}
        <button
          className="close-btn"
          onClick={() => {
            removeField(index);
            rm_pr(index);
          }}
        >
          <img src={require("assets/images/close.png")} alt="" />
        </button>
        {/* } */}
      </div>
    ));
  };

  const set_pr = (value: string, i: number) => {
    var p: any = programs;
    p[i] = value;
    set_programs(p);
  }

  const rm_pr = (i: number) => {
    var array: any = programs;
    array.splice(i, 1);
    // const index = array.indexOf(i);
    // if (index > -1) {
    // }
    set_programs(array);
  }

  function set_prod(value: string, i: number) {
    if (!value || value === "") {
      return;
    }
    // console.log(value);
    var p: any = products;
    p[i] = value;
    // set_products(p);
  }

  function rm_prod(i: number) {
    var array: any = products;
    const index = array.indexOf(i);
    if (index > -1) {
      array.splice(index, 1);
    }
    // set_products(array);
  }

  const addNewFieldPromote = () => {
    const cFields = fieldersPromote;
    cFields.push(fieldersPromote.length + 1);
    setPumpfake(pumpFake + 1);
    setFieldersPromote(cFields);
  };

  const removeFieldPromote = (index: any) => {
    if (fieldersPromote && fieldersPromote.length > 1) {
      const cFields = fieldersPromote;
      cFields.splice(index, 1);
      setPumpfake(pumpFake + 1);
      setFieldersPromote(cFields);
    }
  };

  const renderFieldsPromote = () => {
    return fieldersPromote.map((_: any, index: number) => (
      <div key={index} className="removable-fd">
        <Field
          type="text"
          placeholder={products[index]}
          onChange={(e: any) => set_prod(e.target.value.trim(), index)}
        />
        {index === fieldersPromote.length - 1 && (
          <button
            className="close-btn"
            onClick={() => {
              removeFieldPromote(index);
              rm_prod(index);
            }}
          >
            <img src={require("assets/images/close.png")} alt="" />
          </button>
        )}
      </div>
    ));
  };

  async function sub() {
    var filtered = programs.filter(function (el: any) {
      return el != null && el !== "";
    });

    // var filtered2 = products.filter(function (el: any) {
    //   return el != null && el !== '';
    // });

    if (value === "yes") {
      if (filtered.length === 0) {
        toast.error("Kindly include the programmes you're partnering with");
        return;
      }

      // if (commission === "") {
      //   toast.error("Kindly input the average commission you earned");
      //   return;
      // }
    }

    if (content_type === "" || content_type.length === 0) {
      toast.error("Kindly input the content type");
      return;
    }

    if (products === "" || products.length === 0) {
      // console.log(products)
      toast.error("Kindly type products you will like to promote");
      return;
    }

    if (!consent) {
      toast.error("Kindly agree to the terms");
      return;
    }

    var body: any = {};
    body["affiliate"] = value;
    body["programs"] = programs;
    body["average_commission"] = commission;
    body["content_type"] = JSON.stringify(content_type);
    body["products_to_promote"] = JSON.stringify(products);
    body["currency"] = currency === "$" ? 'usd' : "gbp" //currency;
    
    // console.log(body);

    // dispatch(auth_actions.actions.set_more(body));

    editUserData(body)
      .then(async (response: any) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
          toast.error(data.error);
        } else {
          const { data: user } = await getUserByToken()
          dispatch(auth_actions.actions.fulfillUser(user));
          dispatch(auth_actions.actions.fulfillToken(data.user_token));
          navigate("/welcome-pack");
        }
      })
      .catch((error: any) => {
        var message = error.response.data
          ? error.response.data.message
          : "Network error occured!";

        setLoading(false);
        toast.error(message ? message : "Network error occured!");
      });
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        onChange={(e: any) => set_currency(e)}
        defaultValue={currency === "usd" ? "$" : "£"}
        style={{ width: 70 }}
      >
        <Option value="$">$</Option>
        <Option value="£">£</Option>
        {/* <Option value="naira">₦</Option> */}
      </Select>
    </Form.Item>
  );

  const commissionInfo = (
    <div>
      <p>
        To calculate the payable commission, multiply your sales by the sales
        commission rate. A 10 percent commission rate on a $10,000 product deal
        would pay $1,000 in commission.
      </p>
    </div>
  );

  const onToggleConsent = () => setConsent(!consent);

  const MoreInfo = () => {
    return (
      <Popover content={commissionInfo} trigger="click">
        <div className="more">
          <img src={MoreIcon} alt="*" />
        </div>
      </Popover>
    );
  };

  return (
    <AuthContainer {...prop}>
      <div className="auth-form">
        <div className="form-item">
          <label htmlFor="">
            Have you been a part of any affiliate programs before?
            <span className="required">*</span>
          </label>
          <Radio.Group onChange={onChange} value={value}>
            <RadioButton label="Yes" value="yes" />
            <RadioButton label="No" value="no" />
          </Radio.Group>
        </div>

        {value === "yes" && (
          <div>
            <div className="mb-6">
              <div className="form-item">
                <label htmlFor="">
                  Which Programmes are you partnering with?
                  <span className="required">*</span>
                </label>
                {renderFields()}
              </div>
              {/* <Button
                label={
                  <img
                    src={require("assets/images/plus.png")}
                    className="add-input-img"
                  />
                }
                onClick={addNewField}
                variant="secondary"
              /> */}
              <button onClick={addNewField} className="abtbtn">
                <img
                  src={require("assets/images/plus.png")}
                  className="add-input-img"
                  alt=""
                />{" "}
                <label>add other</label>
              </button>
            </div>

            <Field
              type="text"
              WithTopRight={MoreInfo}
              prefixSelector={prefixSelector}
              onChange={(e: any) => set_com(e.target.value.trim())}
              value={value === "no" ? "0" : commission}
              label="What is the average commission you earned per month? (optional)"
              placeholder="e.g. $100"
            />
          </div>
        )}

        <MultiSelectField
          required
          value={content_type}
          onChange={(e: any) => set_content_type(e)}
          options={contentOptions}
          label="What is your content type?"
        />

        <MultiSelectField
          value={products}
          required
          onChange={(e: any) => {
            set_products(e);
          }}
          options={productOptions}
          label="What type of products would you like to promote?"
        />
        {/* <div className="mb-6">
          <div className="form-item">
            <label htmlFor="">what type of products would you like to promote?*</label>
            {renderFieldsPromote()}
          </div>
          <Button
            label={<img src={require('assets/images/plus.png')} className="add-input-img" />}
            onClick={addNewFieldPromote}
            variant="secondary"
          />
        </div> */}

        <div className="consent">
          <Checkbox onChange={onToggleConsent}>
            I'm over the age of 18 years old and I agree to Clean Creator
            Community
            <span className="primary"><a target="_blank" href={`${process.env.REACT_APP_LANDING_APP_URL || 'https://cleancreatorcommunity.com/'}terms-of-use`}> Terms of Use </a></span> and{" "}
            <span className="primary"><a target="_blank" href={`${process.env.REACT_APP_LANDING_APP_URL || 'https://cleancreatorcommunity.com/'}privacy-notice`}>Privacy Policy</a></span>
          </Checkbox>
        </div>

        <div className="actions mb-4">
          <div className="flex gap w-100">
            <Button
              loading={loading}
              variant="secondary"
              label="Previous"
              onClick={() => navigate("/channels")}
            />
            <Button variant="primary" label="Next" onClick={() => sub()} />
          </div>
        </div>
        <Button
          variant="plain"
          label="Already have an account? Login"
          onClick={() => navigate("/login")}
        />
      </div>
    </AuthContainer>
  );
}
