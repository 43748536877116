import React, { useEffect } from "react";
import Logo from 'assets/images/Logo_white.png';
import arrowup from "../../assets/images/arrowupicon.svg";
import socialicon from "../../assets/images/instagramicon.svg";
import { footerLinks } from "./constants";

const Footer = () => {
  useEffect(() => {
    console.log(process.env);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <section className="footer bg-secondary px-[10px]">
      <div className=" flex w-full justify-center items-center pt-[61px] pb-[71px]">
        <div className="footer-orientation flex w-[992px] justify-between">
          <img src={Logo} alt="" className=" w-[296.44px] h-[66.69px]" />
          <ul className="">
            {footerLinks.map((footer, index) => (
              <li
                key={footer.name}
                className={`cursor-pointer text-[14px] text-dimwhite ${index === footerLinks.length - 1 ? "mb-0" : "mb-[10px]"
                  }`}
              >
                  <a
                    href={
                      footer.name === "CONTACT US"
                        ? "mailto:info@cleancreatorcommunity.com"
                        : `${process.env.REACT_APP_LANDING_APP_URL || 'https://cleancreatorcommunity.com/'}${footer.linkaddress}`
                    }
                  >
                    {footer.name}
                  </a>
              </li>
            ))}
          </ul>

          <div className="">
            {/* <div className="">
              <p className="text-[16px] font-[325] text-white">Follow Us</p>

              <div className="socialicon cursor-pointer hover:bg-dimwhite mt-[10px] bg-white w-[40px] h-[40px] rounded-[40px] flex items-center justify-center">
                <img src={socialicon} alt="" className="w-[24px] h-[24px]" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="footerbottom flex w-full justify-between items-center pt-[21px] pb-[22px] px-[27px]">
        <p className="text-white text-[14px] font-[325]">
          Ⓒ2023 The Clean Creator Community by Amyris. All Rights Reserved
        </p>

        <div className="footerbottomicon cursor-pointer flex items-center">
          <img src={arrowup} alt="" className="w-[24px] h-[24px]" />
          <p
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            className=" cursor-pointer text-[14px] text-white pl-[10px] hover:underline"
          >
            BACK TO TOP
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
