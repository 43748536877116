import { useState, useEffect, useRef } from 'react'
import { message, Rate, Skeleton, notification, Space, Button as Butt } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Slider from "react-slick";
import { ProductCatalogue } from 'components/Icons'
import { SidebarLayout } from 'components/layout'
import { getSingleBrand, getSingleProduct, fetchAllData, addData, reset, fetchProductMediaAll } from '../_redux/products/Actions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import { Button } from 'components/forms';
import Vimeo from '@u-wave/react-vimeo';
import type { NotificationPlacement } from 'antd/es/notification/interface';


const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/next-icon.png')} alt="" />
    </div>
  );
}

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/prev-icon.png')} alt="" />
    </div>
  );
}
const vidSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function ProductDetails() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const [crumbs, setCrumbs] = useState<any>([{ label: '/brands', to: '/products' }])
  const productId: any = params?.id;
  // const [messageApi, contextHolder] = message.useMessage();
  const [previewImage, setPreviewImage] = useState<number>(0);
  const [pumpFake, setPumpfake] = useState(1);
  const [productImages, setProductImages] = useState<any>([]);
  const [productVideos, setProductVideos] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [ modal, setModal] = useState(false)
  const [code, setCode] = useState<string>('')
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [gen_code, set_gen_code] = useState<any>('');


  const { listLoading, actionsLoading, brandDetails, productDetails, brandCommissionTierData } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      listLoading: state.products.listLoading,
      productDetails: state.products.productDetails,
      brandDetails: state.products.brandDetails,
      brandCommissionTierData: state.products.brandCommissionTierData,
    }),
    shallowEqual
  );

  useEffect(() => {

    if (productId) {
      dispatch(getSingleProduct(productId));
    } else {
      dispatch(reset());
    }
  }, []);

  useEffect(() => {
    if (productDetails && !brandDetails) {
      dispatch(getSingleBrand(productDetails.brand_id));
    }
  }, [productDetails, brandDetails])

  useEffect(() => {
    if (productDetails && brandDetails) {
      // dispatch(getSingleBrand(productDetails.brand_id));

      setCrumbs([
        { label: '/ brands', to: '/products' },
        { label: brandDetails?.name, to: `/brand/${brandDetails._id}` },
        { label: productDetails.name, to: `/product/${productDetails._id}` },
      ])
    }
  }, [productDetails, brandDetails])

  useEffect(() => {
    setPumpfake(pumpFake + 2);
  }, [brandCommissionTierData]);



  const copyValue = (d: string) => {
    navigator.clipboard.writeText(d)
  };


  const [api, contextHolder] = notification.useNotification();

  function tracking(id: any, type = 'link') {
    if (type === 'link') {
      dispatch(fetchAllData("tracking", `?product_id=${id}`))
      .then((da: any) => {
        // setCCed(da)
        openNotification('bottomRight', 'tracking', da);
      });
    } else {
      var bod: any = {};
      bod['brand_id'] = productDetails.brand_id;
      dispatch(addData(bod, "discount_code")).then((da: any) => {
        // setCCed(da)
        openNotification('bottomRight', 'code', da.success);
      });
    }
  }



  useEffect(() => {
    if (productDetails && productDetails._id) {
      dispatch(fetchProductMediaAll(`?type_id=${productDetails._id}&file_type=image`)).then((res: any) => {
        let pIm: any = productImages;
        pIm = res.success;
        console.log(pIm)
        setPumpfake(pumpFake + 1);
        setProductImages(pIm);
      })

      dispatch(fetchProductMediaAll(`?type_id=${productDetails._id}&file_type=video`)).then((res: any) => {
        let pIm: any = productVideos;
        pIm = res.success;
        setPumpfake(pumpFake + 1);
        setProductVideos(pIm);
      })
    }
  }, [productDetails]);

  const selectThumbIndex = (index: number) => setPreviewImage(index);

  const calculateEarn = (price: number) => {
    let earn = 0;
    let dis = 20;
    if (price && typeof price == 'number' && brandCommissionTierData?.commission) {
      var real_p = price - (dis/100 * price);
      earn = brandCommissionTierData?.commission / 100 * real_p;
    }
    return earn.toFixed(2);
  }


  const close = () => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
  };
  
  const handleGenerate = () => {
    
    if (inputRef.current) {
      // setCode('ccc' + inputRef.current.value);
      var bod: any = {};
      bod['brand_id'] = productDetails.brand_id;
      bod['code'] = inputRef.current.value
      dispatch(addData(bod, "discount_code")).then((da: any) => {
        openNotification('bottomRight', 'code', da.success, da?.msg || null );
      });
    }
  }
  const toggleModal = () => {
    console.log('open')
    setModal(!modal)
  }
  const renderModal = () => {
    return (
      <>
      <div className="modal-container">
        <button className="close-btn" onClick={toggleModal} style={{float: 'right'}}>
        <img src={require("assets/images/close.png")} alt=""  style={{width: '15px'}}/>
        </button>
        <p className="modal-header">Customise Discount Code</p>
        <p className="input-label">Generate Coupon Code</p>
        <div className="input-group">
          <span className="input-prefix">CCC</span>
          <input className="modal-input" placeholder='' defaultValue = {gen_code} ref={inputRef}/>
        </div>
        <button className='modal-button' onClick={handleGenerate}>GENERATE CODE</button>
      </div>
      </>
    )
  }

  const openNotification = (placement: NotificationPlacement, type: string, data: string, msg = null) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Butt type="link" size="small" onClick={() => {
          copyValue(data);
          api.destroy();
        }}>
          COPY LINK!
        </Butt>
      </Space>
    );
    api.open({
      message: 'Copy to clipboard!',
      description:
      msg || `Click the confirm button to copy ${data} to your clipboard!`,
      placement,
      btn,
      key,
      onClose: close,
    });
  };


  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {contextHolder}
      {productDetails && productDetails._id
        ? <>
          <div className="products-details-container" style={{position: 'relative'}}>
            {modal ? <div className="modal">{renderModal()}</div> : ''}
            {productImages
              ? <div className="preview">
                <div className="sliderr">
                  {productImages &&
                    <>
                      {productImages.map((image: any, index: number) => (
                        <div className={`product-image ${previewImage == index ? 'anymate' : 'hide'}`} key={index}>
                          <img src={productImages[index].url} alt="" style={{ width: '100%', height: '100%' }} />
                        </div>
                      ))}
                    </>
                  }
                  <div className="control">
                    {productImages.map((image: any, index: number) => (
                      <div className={`tnail ${previewImage == index && 'active'}`} key={index} onClick={() => selectThumbIndex(index)}>
                        <img src={image.url} alt="*" />
                      </div>
                    ))}
                  </div>
                </div>
                <a target="_blank" href={productDetails?.orderUrl || 'javascript:;'}>
                  <Button variant="primary"
                    label="explore product" />
                </a>
              </div>
              : <span>No bueno!</span>
            }
            <div className="details">
              <div className="brand-logo">
                {/* {
                  <img src={productDetails?.thumbnail} alt="*" />
                } */}
                {brandDetails &&
                  <h2 className="product-head">{brandDetails.name.toUpperCase()}</h2>
                }
              </div>
              <h2 className="product-title">{productDetails.name}</h2>
              <div className="desc" dangerouslySetInnerHTML={{ __html: productDetails.description }} />
              <span className="price">Price to Consumer: <span>£{productDetails.price}</span></span>
              <span className="earn">Earn: £{calculateEarn(productDetails.price)}</span>
              <div className="ratings">
                <Rate
                  disabled
                  allowHalf
                  defaultValue={5}
                />
                {
                  productDetails.exploreUrl && <a href={`${productDetails.exploreUrl}#ratings-reviews`} target="_blank" className="reviews">see reviews</a>
                }
                {/* <span className="reviews">see {productDetails.reviews || 0} reviews</span> */}
              </div>
              <div className="actions">
                <Button variant="primary"
                //  onClick={toggleModal}
                onClick = {() => {
                  dispatch(fetchAllData("gen_code"))
                  .then((da: any) => {
                    set_gen_code(da.success.substring(3))
                    toggleModal();
                  });
                }}
                  label="GENERATE CODE" />
                <Button variant="primary"
                  onClick={() => {
                    tracking(productDetails?._id);
                  }}
                  label="get tracking link" />
                <a href={productDetails?.orderUrl || 'javascript:;'} target='_blank'>
                  <Button variant="primary"
                    label="order" />
                </a>
              </div>
            </div>
          </div>
          <div className="video-container">
            <h2 className="section-title">Video usage & tutorials</h2>
            <Slider {...vidSettings} className="video-slider">
              {productDetails && JSON.parse(productDetails?.product_videos || '[]').map((d: any, index: number) => (
                <div className="video" key={index} style={{
                  height: 'auto',
                  width: '100%',
                  maxHeight: 450,
                }}>
                  <Vimeo
                    responsive
                    video={d}
                    width={800}
                    height={480}
                    autoplay={false}
                  />
                  {/* <img src={require('assets/images/video-placeholder.png')} alt="*" />
                  <span>How it works</span> */}
                </div>
              ))}
            </Slider>
          </div>
        </>
        : <Skeleton />
      }
    </SidebarLayout >
  )
}
