import { useEffect, useRef, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Layout, Dropdown, Drawer } from 'antd';
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { HamburgerMenu } from 'components/Icons';
// import Logo from 'components/Logo';
import { menuItems, mobileMenu } from './data';


interface HeaderProps {
  title?: string;
  Icon?: any;
}

const { Header } = Layout;


export default function TopHeader(props: HeaderProps) {
  const navigate = useNavigate();
  const { title, Icon } = props;
  const location = useLocation();
  const [drawerState, toggleDrawerState] = useState<boolean>(false);
  const [profileActive, setProfileActive] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);


  useEffect(() => {
    if (location.pathname.includes('profile')) {
      return setProfileActive(true)
    }
    setProfileActive(false);

  }, [location.pathname]);

  const items: MenuProps['items'] = [
    {
      label: <span>User Profile</span>,
      key: '0',
      onClick: () => navigate('/profile'),
    },
    {
      type: 'divider',
    },
    {
      label: <span> LOGOUT</span>,
      key: '3',
      onClick: () => navigate('/logout'),
      danger: true,
    },
  ];

  const navigateTo = (item: any) => {
    toggleDrawerState(false);
    navigate(item.to);
  }

  const { user } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user
    }),
    shallowEqual
  );


  return (
    <>
      {windowSize?.current[0] < 992 &&
        <>
          <div className="mobile-top-bar">
            <div className="logo">
              {/* <Logo /> */}
              <img src={require('assets/images/logo.png')} alt="" width="72%" />
            </div>
            <button className="mmenu" onClick={() => toggleDrawerState(true)}>
              <HamburgerMenu />
            </button>
          </div>
          <Drawer
            placement="right"
            onClose={() => toggleDrawerState(false)} open={drawerState}
            title={<img src={require('assets/images/logo-white.png')} className="drawer-logo" />}
            closeIcon={<img src={require('assets/images/close-white.png')} alt="X" className="close-icon" />}
            className="drawer-container"
          >
            {mobileMenu.map((item: any, index: number) => (
              <span className="mobile-link-item" key={index} onClick={() => navigateTo(item)}>{item.label}</span>
            ))}
          </Drawer>
        </>
      }
      <Header className="header-container">
        <div className="title-icon">
          <div className="icon">
            {Icon && <Icon />}
          </div>
          <span className='title'>{title || ''}</span>
        </div>
        <div className="menu-section">
          <button className="notification">
            <img src={require('assets/images/notification.png')} width={22} height={24} alt="#" />
          </button>
          <Dropdown menu={{ items }} trigger={['click']}>
            <div className={`user ${profileActive && 'active'}`}>
              <img src={user.picture ? user.picture : require('assets/images/user.png')} width={36} height={36} alt="#" style={{
                objectFit: 'cover'
              }} />
              <div className="content">
                <h3>{`${user.first_name} ${user.last_name}`}</h3>
                <span>{user.type}</span>
              </div>
            </div>
          </Dropdown>
        </div>
      </Header>
    </>
  )
}
